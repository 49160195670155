import React from 'react';
import { Skeleton } from 'primereact/skeleton';

export default function ConversationSkeleton(props) {
    const { count } = props;
    const skeletons = [];

    for (let i = 0; i < count; i++) {
        skeletons.push(
            <div key={i} className="card">
                <div className="flex flex-wrap">
                    <div className="flex align-items-center">
                        <Skeleton shape="circle" size="5rem"></Skeleton>
                        <div className="w-full md:w-6 p-3">
                            <Skeleton width="5rem" className="mb-2" borderRadius="16px"></Skeleton>
                            <Skeleton width="15rem" className="mb-2" borderRadius="16px"></Skeleton>
                            <Skeleton width="10rem" borderRadius="16px" className="mb-2"></Skeleton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {skeletons}
        </>
    );
}