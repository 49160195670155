import React, {useContext, useEffect} from 'react';
import Resources from '../Resources/Resources';
import {useHistory} from "react-router-dom";
import {CompanyContext} from "../../context/companyContext";


const ProviderWebhooks = () => {

    const history = useHistory();
    const companyContext = useContext(CompanyContext);

    useEffect(() => {
        if (!companyContext.webhookEnabled) {
            history.push('/');
        }
    }, [companyContext.webhookEnabled]);

    const resource = {
        title: 'Lista de webhooks',
        url: '/provider_webhooks',
        colsHeader: [
            { field: 'method', header: 'Método' },
            { field: 'url', header: 'URL'},
            { field: 'body', header: 'JSON', truncate: false},
            { field: 'active', header: 'Activo', checkbox: true},
            { field: 'provider_webhook_action.description', header: 'Acción'},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/provider_webhooks/',
            edit: '/edit',
            delete: true
        },
        urlTable: [
            '/provider_webhooks',
            '/provider_webhooks/new',
            '^\\/provider_webhooks\\/\\d+$',
            '^\\/provider_webhooks\\/\\d+\\/edit$',
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader} action={resource.action}
                       customTableHeaderButtons={resource.customTableHeaderButtons} customActions={resource.customActions}
                       urlTable={resource.urlTable} />);
}

export default ProviderWebhooks;