import React, {useEffect, useState, useContext} from 'react';
import ApiLoan from "../../../service/ApiLoanService";
import classNames from 'classnames';
import { CompanyContext } from '../../../context/companyContext';

const WhatsappNotification = (props) => {
    const [notifications, setNotifications] = useState({
        total: 0,
        max: 0
    });
    const company_context = useContext(CompanyContext);
    const socket = company_context.socket;

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: '/message/whatsapp/analytics'
        }).then((response) => {
            setNotifications(response.data)
        })
    },[]);

    const onTopbarItemClick = (event, item) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }
    useEffect(() => {
        socket.on('update-whatsapp-notifications', updateWhatsappNotifications)
        return () => {
            socket.off('update-whatsapp-notifications', updateWhatsappNotifications)
        }
    }, []);

    const updateWhatsappNotifications = (data) => {
        setNotifications(data);
    }

    const notifications_available = notifications.max - notifications.total;
    return (
        <li className={classNames({ 'active-topmenuitem': props.activeTopbarItem === 'notifications' })}>
            <button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'notifications')}>
                <i className="topbar-icon pi pi-bell"></i>
                <span className="topbar-badge animated rubberBand">{notifications_available}</span>
                <span className="topbar-item-name">Notifications</span>
            </button>
            <ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
                <li role="menuitem">
                    <button type="button" className="p-link">
                        <span>Notificaciones de whatsapp disponibles {notifications_available}/{notifications.max}</span>
                    </button>
                </li>
            </ul>
        </li>
    );
}

export default WhatsappNotification