import React from 'react';
import Resources from '../Resources/Resources';

const logs = (params) => {
    let resource = {
        title: params.title,
        url: '/logs',
        colsHeader: [
            { field: 'created', header: 'Fecha', date: true},
            { field: 'file.content_hash', header: 'MD5 Archivo'},
            { field: 'user.username', header: 'Subido por'},
            { field: 'action', header: 'Acciones' }
        ],
        params: {
            filters: [
                {
                    name: params.name,
                    op: params.op,
                    val: params.val
                }
            ],
            order_by: {
                field: 'created',
                direction: 'desc'
            }
        },
        action: {},
        customActions: [
            { field: 'get_csv', uri: '/files/'}
        ]
    };

    if(params.revertUrl){
        resource.customActions.push({ field: 'revert', uri: params.revertUrl});
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       params={resource.params} action={resource.action}
                       customActions={resource.customActions} />);
};

export default logs;