import React, {useState, useEffect, useRef, Fragment} from 'react';
import "../../../assets/css/SheetForm.css";
import { Button } from 'primereact/button';
import {Toast} from "primereact/toast";
import {InputTextarea} from "primereact/inputtextarea";
import ApiLoan from "../../../service/ApiLoanService";
import { validateLetters, valiteLength } from '../../../utils/utils';
import {Checkbox} from 'primereact/checkbox';

const SheetForm = (props) => {
    const [formValues, setFormValues] = useState({
        index: props.index,
        url: '',
        columns: [
            {
                search: '', extract: '', qr: false, error: {}
            }
        ],
        sheet: '',
        message_error: '',
        error: {},
    });
    const refToast = useRef(null);
    const refInputQuestions = useRef(null);
    const refInputSuccessMessage = useRef([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if(props.formData){
            setFormValues(props.formData)
        }
    }, [props.formData]);

    const addFormFields = () => {
        let newFormValues = {...formValues};

        newFormValues.columns = [...newFormValues.columns, {
            column_search: null, row_extract: null
        }]

        setFormValues(newFormValues);
    }

    const removeFormFields = (index) => {
        let newFormValues = {...formValues};
        newFormValues.columns = newFormValues.columns.filter((x,i)=> i !== index);
        setFormValues(newFormValues);
    }

    const handleChange = (i, e) => {
        let newFormValues = {...formValues}

        if(e?.type === 'checkbox') {
            newFormValues.columns[i][e.name] = e.checked;
        } else {
            if(valiteLength(e.value,1)) if(validateLetters(e.value)) newFormValues.columns[i][e.name] = e.value;
            if(valiteLength(e.value,0)) newFormValues.columns[i][e.name] = e.value;
        }

        if (Object.keys(newFormValues.columns[i]['error']).length !== 0){
            newFormValues.columns[i]['error'] = {};
        }
        setFormValues(newFormValues);
    }

    const updateSheet = (e) => {
        let newFormValues = {...formValues}
        newFormValues[e.name] = e.value;
        
        if (Object.keys(newFormValues.error).length > 0){
            newFormValues.error = {};
        }

        setFormValues(newFormValues);
    }

    const errorSheet = (data) => {
        let newFormValues = {...formValues}
        newFormValues['error'] = data.errors;
        setFormValues(newFormValues);
    }

    const getFieldError = (errors, field) => {
        if (errors === undefined){
            return
        }
        let form = null;
        
        if (Object.keys(errors).filter(x=> x === field).length > 0){
            form = (
                <small style={{color:'#f44336'}} className="p-error">
                    {errors[field][0]}
                </small>
            );
        }

        return form;
    }

    const getFieldArrayError = (errors, fields, index, field) => {
        if (errors === undefined){
            return
        }
        let form = null;

        if (Object.keys(errors).filter(x=> x === fields).length > 0){
            form = (
                <small style={{color:'#f44336'}} className="p-error">
                    {errors[fields][index][field][0]}
                </small>
            );
        }

        return form;
    }

    const handleDelete = () => {
        props.setFormData(null);
        props.hideSheet();
    }

    const handleSubmit = () => {
        const apiService = new ApiLoan();
        setLoading(true);
        let url = '/sheets'

        const formData = {
            'url': formValues.url,
            'sheet': formValues.sheet,
            'columns': formValues.columns,
            'message_error': formValues.message_error,
            'error': formValues.error,
        }

        apiService.postResource({
            'url': url,
            'data': formData
        }).then(response => {
            setLoading(false);
            props.setFormData(formData);
            props.refToast.current.show({severity: 'success', summary: 'Configuración Sheet',
                detail: 'El sheet se ha creado satisfactoriamente.'});
            props.hideSheet();
        }).catch(error => {
            setLoading(false);
            errorSheet(error?.response?.data);
            props.refToast.current.show({severity: 'error', summary: 'Configuración Sheet',
                detail: 'Verifique los datos del formulario.'});
        });

    }

    let form = (
        <div className="grid formgrid" style={{position: 'relative', top: '20px', paddingBottom: '20px'}}>
            <div className="col-12 md-12">
                <Toast ref={refToast}/>
                <div className="grid formgrid">
                    <div className="col-12">
                        <div className="col-12">
                            <div className="grid formgrid">
                                <div className="field col">
                                    <div className='p-inputgroup'>
                                        <span className="p-float-label space-right">
                                            <InputTextarea name={'url'} value={formValues.url}
                                                       onChange={(e) => updateSheet(e.target)}
                                                       ref={el => refInputQuestions.current = el}
                                                       className={'sheet-question'} rows={1} />
                                            <label htmlFor="inputtext">{'Ingresa Url'}</label>
                                         </span>
                                    </div>
                                    {getFieldError(formValues.error, 'url')}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="grid formgrid">
                                <div className="field col">
                                    <div className='p-inputgroup'>
                                        <span className="p-float-label space-right">
                                            <InputTextarea name={'sheet'} value={formValues.sheet}
                                                       onChange={(e) => updateSheet(e.target)}
                                                       ref={el => refInputQuestions.current = el}
                                                       className={'sheet-question'} rows={1} />
                                            <label htmlFor="inputtext">{'Ingresa Hoja'}</label>
                                         </span>
                                    </div>
                                    {getFieldError(formValues.error, 'sheet')}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="grid formgrid">
                                <div className="field col">
                                    <div className='p-inputgroup'>
                                        <span className="p-float-label space-right">
                                            <InputTextarea name={'message_error'} value={formValues.message_error}
                                                       onChange={(e) => updateSheet(e.target)}
                                                       ref={el => refInputQuestions.current = el}
                                                       className={'sheet-question'} rows={1} />
                                            <label htmlFor="inputtext">{'Mensaje de Error'}</label>
                                         </span>
                                    </div>
                                    {getFieldError(formValues.error, 'message_error')}
                                </div>
                            </div>
                        </div>

                        {formValues.columns.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <div className="grid formgrid" key={index}>
                                        <div className="field col inline-flex">
                                            <div className="field col">
                                                <div className='p-inputgroup'>
                                                    <span className="p-float-label">
                                                        <InputTextarea className={'sheet-textarea'} name={'search'} value={item.search}
                                                               onChange={(e) => handleChange(index, e.target)}
                                                               ref={el => refInputSuccessMessage.current[index] = el}
                                                               rows={1} />

                                                        <label htmlFor="inputtext">Indique la columna para buscar</label>
                                                    </span>
                                                </div>
                                                {getFieldArrayError(formValues.error, 'columns', index, 'search' )}
                                            </div>
                                            <div className="field col">
                                                <div className='p-inputgroup'>
                                                    <span className="p-float-label">
                                                        <InputTextarea className={'sheet-textarea'} name={'extract'} value={item.extract}
                                                               onChange={(e) => handleChange(index, e.target)}
                                                               ref={el => refInputSuccessMessage.current[index] = el}
                                                               rows={1} />

                                                        <label htmlFor="inputtext">Indique la columna para extraer</label>
                                                    </span>
                                                </div>
                                                {getFieldArrayError(formValues.error, 'columns', index, 'extract' )}
                                            </div>
                                            <div className="field col-1">
                                                <div className="field-checkbox">
                                                        <Checkbox className='sheet-qr' name={'qr'} checked={item.qr}
                                                               onChange={(e) => handleChange(index, e.target)}
                                                               ref={el => refInputSuccessMessage.current[index] = el}
                                                               rows={1} />

                                                        <label className='sheet-qr' htmlFor="inputtext">QR</label>
                                                </div>
                                                {getFieldArrayError(formValues.error, 'columns', index, 'qr' )}
                                            </div>
                                            {
                                            /*<div className="p-button-container field">
                                                <Button icon="pi pi-plus" className={"p-button-pip"}
                                                         onClick={() => addFormFields()}>
                                                </Button>
                                                <Button icon="pi pi-minus" className={formValues.sheet.length === 1 ? "p-button-pip-min-disabled" : "p-button-pip-min"}
                                                         onClick={() => removeFormFields(index)}
                                                         disabled={formValues.sheet.length === 1}>
                                                </Button>
                                            </div>*/
                                            }
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })}
                        <div  className='p-inputgroup'>
                            <Button label={props.formData !== null ? 'Guardar' : 'Crear'}
                            onClick={handleSubmit} disabled={loading} className="p-button-raised ml-auto" style={{width: props.formData !== null ? '50%' : '100%'}}
                            icon={loading ? 'pi pi-spin pi-spinner': props.formData !== null ? 'pi pi-pencil' : 'pi pi-plus'}
                            />
                            <span>&nbsp;</span>
                            {props.formData !== null &&
                                <Button className="p-button-raised btn-danger ml-auto" disabled={loading} label={'Eliminar'} style={{width: '50%'}}
                                onClick={handleDelete}
                            icon={'pi pi-times'}
                            />
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    return form

}

export default SheetForm;