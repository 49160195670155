export const MONDAY = 0;
export const TUESDAY = 1;
export const WEDNESDAY = 2;
export const THURSDAY = 3;
export const FRIDAY = 4;
export const SATURDAY = 5;
export const SUNDAY = 6;

export const switchDayOfWeek = (day) => {
    let name;
    switch(day){
        case MONDAY:
            name = 'Lunes';
            break;
        case TUESDAY:
            name = 'Martes';
            break;
        case WEDNESDAY:
            name = 'Miercoles';
            break;
        case THURSDAY:
            name = 'Jueves';
            break;
        case FRIDAY:
            name = 'Viernes';
            break;
        case SATURDAY:
            name = 'Sabado';
            break;
        case SUNDAY:
            name = 'Domingo';
            break;
        default:
            name = null;
            break
    }
    return name;
}
