import * as actionTypes from './actionTypes';

export const showMessage = (message) => ({
    type: actionTypes.SHOW_MESSAGE,
    message: message
});

export function showToast(message) {
    return showMessage(message);
}

export const cleanMessage = () => {
    return {
        type: actionTypes.CLEAN_MESSAGE,
        messages: null
    }
};

export const clearMessages = () => {
    return dispatch => {
        dispatch(cleanMessage());
    }
};