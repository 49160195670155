import React, { Component } from 'react';
import InputForm from '../../../UI/InputForm';
import { Button } from 'primereact/button';
import ApiLoan from '../../../service/ApiLoanService';
import {withRouter} from "react-router-dom";

import {showToast} from "../../../store/actions";
import {showSuccessMessage, showErrorMessage} from "../../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";
import {messageErrorObject} from "../../../shared/utility";


class Channel extends Component {

    _isMounted = false;

    state = {
        apiService: new ApiLoan(),
        url: null,
        uri: '/channels',
        title: 'Canal',
        channelForm: {
            name: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Nombre'
            },
            description: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Descripción'
            }
        },
        loading: true,
        channelFormEdit: null
    };


    componentDidMount(){
        this._isMounted = true;

        if (this.props.action === 'edit'){
            this.state.apiService.getResource({
                url: this.props.url,
                resource_id: this.props.resource_id
            }).then(response => {
                if (this._isMounted) {
                    let fetched_resource = {};

                    this.props.fields.map(channelField => {
                        for(let field in response.data){
                            if (channelField.hasOwnProperty(field)){
                                if (response.data[field] === null){
                                    response.data[field] = '';
                                }
                                channelField[field].value = response.data[field];
                                fetched_resource[field] = channelField[field];
                            }
                        }
                        return fetched_resource;
                    });
                    this.setState({
                        channelFormEdit: fetched_resource,
                        title: this.props.title,
                        loading: false
                    });
                }
            }).catch(error => {
                showErrorMessage(this, "Canal", 'Error en la carga del canal');
                this.props.history.push( '/channels' );
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    channelHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formData = {};

        for (let formElementIdentifier in this.state.channelForm) {
            formData[formElementIdentifier] = this.state.channelForm[formElementIdentifier].value;
        }

        this.state.apiService.postResource({
            url: this.state.uri,
            data: formData
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Canal", "Se creó satisfactoriamente");
            this.props.history.push( '/channels' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let channelForm = {
                ...this.state.channelForm
            };

            let errorMessages = messageErrorObject(channelForm, messageError);

            if (errorMessages !== null){
                this.setState({
                    channelForm: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Canal", messageError);
            }
        });
    };

    channelEditHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formDataEdit = {};

        for (let formElementIdentifier in this.state.channelFormEdit) {
            formDataEdit[formElementIdentifier] = this.state.channelFormEdit[formElementIdentifier].value;
        }

        this.state.apiService.patchResource({
            url: this.state.uri,
            resource_id: this.props.resource_id,
            data: formDataEdit
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Canal", "Se editó satisfactoriamente");
            this.props.history.push( '/channels' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let channelFormEdit = {
                ...this.state.channelFormEdit
            };

            let errorMessages = messageErrorObject(channelFormEdit, messageError);

            if (errorMessages !== null){
                this.setState({
                    channelFormEdit: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Canal", messageError);
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        let inputFormChanged = this.props.action !== 'edit' ? this.state.channelForm : this.state.channelFormEdit;

        const updatedChannelForm = {
            ...inputFormChanged
        };

        const updatedFormElement = {
            ...updatedChannelForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedChannelForm[inputIdentifier] = updatedFormElement;

        if (updatedFormElement.elementConfig.error !== undefined){
            updatedFormElement.elementConfig.error = null;
        }

        if (this.props.action !== 'edit'){
            this.setState({channelForm: updatedChannelForm});
        } else {
            this.setState({channelFormEdit: updatedChannelForm});
        }
    }

    render(){
        const formElementsArray = [];
        let channelForm = this.props.action !== 'edit' ? this.state.channelForm : this.state.channelFormEdit;
        let submitForm = this.props.action !== 'edit' ? this.channelHandler : this.channelEditHandler;
        let submitFormButton = this.props.action !== 'edit' ? 'Guardar' : 'Actualizar';

        for (let key in channelForm) {
            formElementsArray.push({
                id: key,
                config: channelForm[key]
            });
        }

        let form = (
            <form onSubmit={submitForm}>
                {formElementsArray.map(formElement => (
                    <InputForm
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        typeElement={formElement.id}
                        traduction={formElement.config.traduction}
                        title={formElement.config.title}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}
                    />

                ))}

                <div className="col-12 md-12">
                    <Button label={submitFormButton} style={{marginBottom:'10px'}} />
                </div>

            </form>
        );

        return (
            <div className="grid p-fluid">
                <div className="col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1><strong>{this.state.title}</strong></h1>
                        <div className="col-12 form-group">
                            {form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(Channel));