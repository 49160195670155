import React, { useEffect, useState, Fragment } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { markCurrentUser } from '../../../utils/users';

const UserGroupSelector = ({ client, users, groups, showSelectUser, showSelectGroup, onUserGroupChangeHandler, hideUserGroupList, cancelButton, styleDropdown, styleCancelButton }) => {

    const [selectedUsersOption, setSelectedUsersOption] = useState(null);
    const [selectedGroupsOption, setSelectedGroupsOption] = useState(null);
    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    useEffect(() => {
        if (client?.conversation?.user) {
            setSelectedUsersOption(markCurrentUser([client?.conversation?.user])[0]);
        } else {
            setSelectedUsersOption(null);
        }
        if (client?.conversation?.group && groups) {
            let newGroups = [...groups]
            let newSelectGroupsOption = newGroups.filter(x => x.id === client?.conversation?.group?.id)[0]
            setSelectedGroupsOption(newSelectGroupsOption);
        } else {
            setSelectedGroupsOption(null);
        }
    }, [client, users, groups]);

    let userGroupCancelButton = (showSelectUser || showSelectGroup) && (
        <Fragment>
            <i style={styleCancelButton} className='user-group-cancel-btn font-bold' onClick={() => hideUserGroupList()}>
                <FontAwesomeIcon icon={"fa-solid fa-xmark"} className={"btnEmoji user-group-cancel-btn-icon"} />
            </i>
        </Fragment>
    );

    return (
        <>
            {(cancelButton || cancelButton === undefined) && userGroupCancelButton}
            {showSelectUser && (
                <Dropdown
                    value={selectedUsersOption}
                    optionLabel='username'
                    options={users}
                    onChange={(e) => onUserGroupChangeHandler(e.value)}
                    placeholder='Usuarios'
                    resetFilterOnHide={true}
                    filter={!isMobile()}
                    className='user-group-dropdown'
                    style={styleDropdown}
                />
            )}
            {showSelectGroup && (
                <Dropdown
                    value={selectedGroupsOption}
                    optionLabel='name'
                    options={groups}
                    onChange={(e) => onUserGroupChangeHandler(e.value)}
                    placeholder='Grupos'
                    resetFilterOnHide={true}
                    filter={!isMobile()}
                    className='user-group-dropdown'
                    style={styleDropdown}
                />
            )}
        </>
    );
};

export default UserGroupSelector;