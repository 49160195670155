import React, {useEffect, useContext, useState, Fragment, useRef} from 'react';
import ApiLoan from "../../service/ApiLoanService";
import {Link, useHistory} from "react-router-dom";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {CompanyContext} from "../../context/companyContext";
import {switchDayOfWeek} from "../../shared/daysWeek";
import './Chatbots.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/toolbar";
import {Toast} from "primereact/toast";
import ChatBotTestForm from "./ChatBotTestForm";

const ChatBots = () => {

    const history = useHistory();
    const {chatbotEnabled} = useContext(CompanyContext)
    const [resources, setResources] = useState([])
    const [expandedRows, setExpandedRows] = useState([]);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [selectedDeleteItem, updateSelectedDeleteItem] = useState(null);

    const [methodTest, setMethodTest] = useState({
        show: false,
        value: {},
    });

    const refToast = useRef(null);

    useEffect(() => {
        if (!chatbotEnabled){
            history.push('/');
        }
    }, [chatbotEnabled]);

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: '/chat_bots'
        }).then(response => {
            setResources(response.data.objects)
        });
    }, [])

    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    const headerTemplate = (data) => {
        return (
            <Fragment>
                <span>{switchDayOfWeek(data.day)}</span>
                <div className={'p-rowgroup-header flex justify-content-end p-row-group-header-chatbot'}>
                    {actionBodyTemplate(data)}
                </div>
            </Fragment>
        )
    }

    const booleanField = (rowData, column) => {
        let label = 'SI';
        let status = 'actived';
        if (!rowData[column.field]){
            label = 'NO';
            status = 'disabled'
        }
        return <span className={`boolean-field-badge status-${status}`}>{label}</span>;
    }

    const booleanRepeatField = (rowData, column) => {
        let label = 'SIEMPRE';
        let status = 'actived'
        if (!rowData[column.field]){
            label = 'NUNCA';
            status = 'disabled';
        }
        return <span className={`boolean-field-badge status-${status}`}>{label}</span>;
    }

    const confirmDeleteResource = (event, resource) => {
        setDeleteItemDialog(true)
        updateSelectedDeleteItem(resource.day);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <Fragment>
                <Link to={'chat_bots/'+rowData.day+'/edit'}>
                    <FontAwesomeIcon icon={"fa-regular fa-pen"} size={"lg"} className={"btn-actions btn-actions-default btn-chatbot-actions"} />
                </Link>
                <button className="p-link" onClick={(event) => confirmDeleteResource(event, rowData)}>
                    <FontAwesomeIcon icon={"fa-regular fa-trash-can"} color={'red'} size={"lg"} className={"btn-actions btn-chatbot-actions"}/>
                </button>
            </Fragment>
        );
    }

    const footerTemplate = (data) => {
        return null;
    }

    const hideDeleteResourceDialog = () => {
        updateSelectedDeleteItem(null);
        setDeleteItemDialog(false);
    }

    const deleteItemDataTable = (day) => {
        let itemsList = [...resources];
        itemsList = itemsList.filter((c) => c.day !== day);
        setResources(itemsList);
    };

    const deleteResource = (event) => {
        event.preventDefault();
        const apiService = new ApiLoan();

        apiService.deleteResource({
            url: '/chat_bots',
            resource_id: selectedDeleteItem.toString()
        }).then(response => {
            deleteItemDataTable(selectedDeleteItem);
            updateSelectedDeleteItem(null);
            setDeleteItemDialog(false);
            refToast.current.show({severity: 'success', summary: 'Frecuencia Bot', detail: 'Se elimino satisfactoriamente'});
        }).catch(error => {
            let messageDetail = error.response?.data?.message !== undefined ?
                error.response.data.message : 'Error al querer eliminar el recurso';
            refToast.current.show({severity: 'error', summary: 'Frecuencia Bot', detail: messageDetail});
        });
    };

    const deleteResourceDialogFooter = (
        <Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteResourceDialog} />
            <Button label="Eliminar" icon="pi pi-check" className="p-button-text" onClick={deleteResource} />
        </Fragment>
    );

    const methodTestModal = () => {
        setMethodTest((prevState) => {
            return {
                ...prevState,
                show: true
            }
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <Fragment>
                <div className={'ml-2'}>
                    <Button
                        className="ml-auto method-test-button"
                        label="Método de prueba"
                        icon='pi pi-plus'
                        data-tip="Método de prueba"
                        onClick={() => { methodTestModal()}}

                    />
                </div>
                <div className={'ml-2'}>
                    <Link to={'/chat_bots/new'}>
                        <Button
                            className="ml-auto"
                            label="Nuevo"
                            icon='pi pi-plus'
                        />
                    </Link>
                </div>
            </Fragment>
        )
    }

    const hideModalChatBotTest = () => {
        setMethodTest((prevState) => {
            return {
                ...prevState,
                show: false,
                value: null
            }
        })
    }

    const getModalChatbotTest = () => {
        return (
            <Fragment>
                <Dialog header="Método de Prueba" visible={methodTest.show} style={{ minHeight: '50%', width: '50%', height: 'auto' }}
                        onHide={() => hideModalChatBotTest()}>
                        <ChatBotTestForm
                            formData={methodTest.value}
                            hideModalChatBotTest={hideModalChatBotTest}
                            refToast={refToast}
                        />
                </Dialog>
            </Fragment>
        )
    }


    return (
        <div className="grid">
            <div className="col-12">
                <div className='card'>
                <h1><strong>Frecuencias Bot</strong></h1>
                <Toast ref={refToast}/>
                <div className="datatable-filter-wa-mobile">
                <div className="card">
                    <Toolbar className="mb-2 mr-2" right={() => rightToolbarTemplate()}></Toolbar>
                    <DataTable emptyMessage="No se encontró ningún resultado" className="p-datatable-customers" value={resources} rowGroupMode="subheader" groupRowsBy="day" sortMode="single"
                               sortField="day" sortOrder={1} expandableRowGroups expandedRows={expandedRows}
                               onRowToggle={(e) => setExpandedRows(e.data)}
                               rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate}>
                        <Column></Column>
                        <Column field="start_time" header="Hora Inicio"></Column>
                        <Column field="end_time" header="Hora Fin"></Column>
                        <Column field="active" header="Habilitado" body={booleanField}></Column>
                        <Column field="trace" header="Rastro" body={booleanField}></Column>
                        <Column field="active_bot_response" header="Respuesta del bot" body={booleanField}></Column>
                        <Column field="repeat" header="Repetir" body={booleanRepeatField}></Column>
                    </DataTable>
                    <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirmar" modal
                            footer={deleteResourceDialogFooter} onHide={hideDeleteResourceDialog}>
                        <div className="confirmation-content mt-3">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                            <span>¿Está seguro?</span>
                        </div>
                    </Dialog>
                        {getModalChatbotTest()}
                </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ChatBots;
