import React, { useEffect , useState} from 'react';

const ModalComponent = ({ children }) => {

    const [height, setHeight] = useState(window.innerWidth);

    useEffect(() => {
        const updateWindowDimensions = () => {
        const newHeight = window.innerWidth;
        setHeight(newHeight);
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions);

    }, []);

    return (
        <div id="customModal" className="custom-modal">
            <div className="custom-modal-content">
                {children}
            </div>
        </div>
    );
}

export default ModalComponent;