import ApiLoan from '../service/ApiLoanService';

export const markAsSeen = (status=true, client_id, providerUrlVersion) => {
    const apiService = new ApiLoan();
    apiService
    .postResourceGeneric({
        url: '/conversations/' + client_id + '/seen',
        data: {
          unread_messages: !status
        }
    }, providerUrlVersion)
}

export const markAsPinup = (status=true, client_id, providerUrlVersion) => {
    const apiService = new ApiLoan();
    apiService
    .postResourceGeneric({
        url: '/conversations/' + client_id + '/pinup',
        data: {
          pinup_messages: status
        }
    }, providerUrlVersion)
}


export const markAsArchived = (status=true, client_id, providerUrlVersion) => {
    const apiService = new ApiLoan();
    apiService
    .postResourceGeneric({
        url: '/conversations/' + client_id + '/archived',
        data: {
          archived: status
        }
    }, providerUrlVersion)
}

export const markAsStar = (message_id, providerUrlVersion) => {
    const apiService = new ApiLoan();
    apiService
    .postResourceGeneric({
        url: '/message/' + message_id + '/star',
    }, providerUrlVersion)
}

export const internalMarkAsStar = (message_id) => {
    const apiService = new ApiLoan();
    apiService
    .postResource({
        url: '/internal_message/' + message_id + '/star',
    })
}

export const markAsArchivedAndClean = (status= true, conversation= false, custom_tags= false,
                                       client_id, providerUrlVersion) => {
    const apiService = new ApiLoan();
    apiService
    .postResourceGeneric({
        url: '/conversations/' + client_id + '/clean',
        data: {
          archived: status,
          clean_conversation: conversation,
          clean_custom_tags: custom_tags,
        }
    }, providerUrlVersion)
}

export const toggleAssign = (client_id, user_id, toggle_assign, providerUrlVersion ) => {
    const apiService = new ApiLoan();
    return apiService
        .postResourceGeneric({
            url: '/conversations/' + client_id + '/assign',
            data: {
                user_id: user_id,
                toggle_assign: toggle_assign
            }
        }, providerUrlVersion)
}
