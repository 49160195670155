export const colors = [
    '#d67575',
    '#c83131',
    '#861919',
    '#379c0c',
    '#ff0000',
    '#bf7bab',
    '#c15ca3',
    '#ca1294',
    '#66094b',
    '#ff00b4',
    '#8f789e',
    '#9154b8',
    '#812bb8',
    '#511b74',
    '#9c00ff',
    '#9393e3',
    '#5f5fe0',
    '#2f2fd6',
    '#202098',
    '#0000ff',
    '#83a6c1',
    '#468abf',
    '#1770b4',
    '#12446b',
    '#0091ff',
    '#8daaa9',
    '#54adaa',
    '#199994',
    '#0e5250',
    '#01201f',
    '#8dbc89',
    '#4dad45',
    '#24901c',
    '#12490e',
    '#0fb103',
    '#a1a17c',
    '#a6a64e',
    '#85851b',
    '#4e4e10',
    '#caca0e',
    '#b19e73',
    '#8d641c',
    '#573b09',
    '#e59100',
    '#dca54f',
    '#c47d30',
    '#8c5418',
    '#ef7c00',
];

export const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
}
