import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { throttle, debounce } from "throttle-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import notificationSound from '../../../assets/sound/notification_sound.wav';
import ApiLoan from "../../../service/ApiLoanService";
import { listToObject, getLessNumberIndexThan } from '../../../utils/utils';
import { formatInternalConversation, updateShowedClients, getUniqueConversationId, isConversationActive } from "./ConversationListHelpers.ts"
import { MessageContext } from "../../../context/messageContext";
import { CompanyContext } from '../../../context/companyContext';
import { switchRole } from "../../../shared/roles";
import DialogGeneric from "../../../UI/DialogGeneric";
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import './ConversationList.scss';
import MessageNoteReminder from "../MessageNote/MessageNoteReminder";
import { MessageNoteContext } from "../../../context/messageNoteContext";
import { Toast } from "primereact/toast";
import moment from "moment/moment";
import CustomSpinner from "../../../UI/CustomSpinner";
import MessagesNoteModal from "../MessageNote/MessagesNoteModal";
import { nameMimetype } from '../../../utils/utils';
import InfiniteScroll from 'react-infinite-scroller';
import { useAudioPlayer } from '../../../context/AudioPlayerContext';
import Avatar from 'react-avatar';
import ResultNotFount from '../../../UI/ResultNotFount';
import ConversationSkeleton from '../ConversationSkeleton';
import ChannelSelector from '../../ChatChannelContainer/ChannelSelector';
import CustomTabHeadersInternalChat from '../../../UI/CustomTabHeadersInternalChat';
import { internalChatTabOptions } from "./ConversationListHelpers.ts"

const ConversationList = React.memo(function ConversationList(props) {
  const [conversationState, setConversationState] = useState({
    conversations: [],
    searchLoading: false,
    page: 1,
  });

  const [clientsState, setClientsState] = useState({
    showedClients: [],
    internalGroups: [],
    searchLoading: false,
    page: 1,
    internalGroupsPage: 1,
  });
  const [paginating, setPaginating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadinglist, setLoadinglist] = useState(false);
  const [loadingsearch, setLoadingsearch] = useState(false);
  const [lastMessageReached, setLastMessageReached] = useState(false);
  const [lastClientReached, setLastClientReached] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showUnreads, setShowUnreads] = useState(false);
  const [filterUnreads, setFilterUnreads] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dialogReassignMessages, setDialogReassignMessages] = useState(false);
  const [updateModalInfo, setUpdateModalInfo] = useState(false);
  const [valueDialog, setValueDialog] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [dialogNoteReminder, setDialogNoteReminder] = useState(false);
  const apiService = new ApiLoan();
  const CancelTokenConversationRequest = axios.CancelToken;
  const conversationRequest = CancelTokenConversationRequest.source();
  const message_context = useContext(MessageContext);
  const company_context = useContext(CompanyContext);
  const [supervisor, setSupervisor] = useState(false);
  const [showConversationListUsers, setShowConversationListUsers] = useState(false);
  const [conversationActive, setConversationActive] = useState(null);
  const [itemMenuFilter, setItemMenuFilter] = useState([]);
  const [itemMenuMore, setItemMenuMore] = useState(false);
  const [profile, setProfile] = useState(null);
  const socket = company_context.socket;
  const [showConversationListGroups, setShowConversationListGroups] = useState(null);
  const num_results = 40;
  const { setIsTopBarVisible } = company_context
  const [height, setHeight] = useState(window.innerWidth);
  const player = document.getElementById('globalAudioPlayer');
  const { audioEnded, setAudioEnded, isAudioVisible, setIsAudioVisible } = useAudioPlayer();

  const toast = useRef(null);
  const refMenu = useRef(null);
  const showedClientsRef = useRef(clientsState.showedClients);
  const searchValueRef = useRef("");
  const isInitialMount = useRef(true);


  const { selectedClient, selectedMessageNote, globalClients, expirationNotes,
    messageNotes, errorNotes, loadingConversation, selectedContact,
    activeMessageNoteModal, clientFullName, updateMessageNotes, updateErrorNotes, updateExpirationNotes,
    updateLoadingConversation, setActiveMessageNoteModal } = useContext(MessageNoteContext);

  useEffect(() => {
    setIsTopBarVisible(true)
    const apiService = new ApiLoan();
    apiService.getResources({
      url: '/profile'
    }).then(response => {
      setProfile(response.data);
    })
  }, []);

  useEffect(() => {
    showedClientsRef.current = clientsState.showedClients;
  }, [clientsState.showedClients]);

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    if (selectedTab === 0 && !isInitialMount.current) {
      getInternalConversations();
    }
    isInitialMount.current = false;
  }, [selectedTab]);

  function sendNotificationSound() {
    const audio = new Audio(notificationSound);
    audio.addEventListener('canplaythrough', () => {
      audio.play();
    });
  }

  function showNotification(title, options, message) {
    if ('serviceWorker' in navigator && isMobile() && localStorage.getItem('token')) {
      if (Notification.permission === 'granted') {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification(title, options);
        });
      }
    }
    if ('Notification' in window && !isMobile() && localStorage.getItem('token')) {
      if (Notification.permission === 'granted') {
        var notification = new Notification(title, options);
        notification.onclick = function (event) {
          event.preventDefault();
          message.client.name = message.name;
          props.onSelectClientHandler(message.client);
          setConversationActive(message.client.id);
          window.focus();
        };
      };
    }
  }

  useEffect(() => {
    if (selectedTab == 3) getInternalGroups();
    if (selectedTab == 2) getInternalClients();
    if (selectedTab == 1) getInternalConversations(undefined, true);
    else getInternalConversations();
  }, [selectedTab, showUnreads]);

  const isMobile = () => {
    return window.innerWidth <= 750;
  };


  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerWidth;
      setHeight(newHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);

  }, []);


  useEffect(() => {
    setLastClientReached(false);
    setLastMessageReached(false);
  }, [searchValue, showUnreads]);

  useEffect(() => {
    socket.on('internal_conversation-' + localStorage.getItem('provider_id'), addOrUpdateConversation);
    socket.on('delete_internal_message_reaction-' + localStorage.getItem('provider_id'), removeMessageReaction);
    socket.on('update_internal_message_reaction-' + localStorage.getItem('provider_id'), updateMessage);
    socket.on('internal_messenger-events-' + localStorage.getItem('provider_id'), updateMessage);
    socket.on('internal_new_note-' + localStorage.getItem('provider_id'), addNewNote);
    socket.on('delete_internal_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
    socket.on('seen_internal_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
    socket.on('update_status_internal_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
    return () => {
      socket.off('internal_conversation-' + localStorage.getItem('provider_id'), addOrUpdateConversation);
      socket.off('delete_internal_message_reaction-' + localStorage.getItem('provider_id'), removeMessageReaction);
      socket.off('update_internal_message_reaction-' + localStorage.getItem('provider_id'), updateMessage);
      socket.off('internal_messenger-events-' + localStorage.getItem('provider_id'), updateMessage);
      socket.off('internal_new_note-' + localStorage.getItem('provider_id'), addNewNote);
      socket.off('delete_internal_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
      socket.off('seen_internal_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
      socket.off('update_status_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
    }
  }, [conversationState.conversations, globalClients]);


  useEffect(() => {
    if (updateModalInfo && valueDialog !== null && dialogReassignMessages) {
      setModalInfo(<DialogGeneric visible={dialogReassignMessages}
        closable={false}
        setVisible={setDialogReassignMessages}
        closeModal={true} {...valueDialog} />);
    }
  }, [updateModalInfo, valueDialog, dialogReassignMessages]);

  useEffect(() => {
    if (!dialogReassignMessages) {
      props.setButtonsActivated(false);
      if (modalInfo !== null) {
        setModalInfo(null);
      }
      if (updateModalInfo) {
        setUpdateModalInfo(false);
      }
      if (valueDialog !== null) {
        setValueDialog(null);
      }
    }

  }, [modalInfo, dialogReassignMessages]);

  useEffect(() => {
    let role = switchRole();
    if (role === 'supervisor') {
      setSupervisor(true);
    }
  }, []);

  useEffect(() => {
    if (message_context.closeModal) {
      message_context.updateCloseModal(false);
    }
  }, [message_context.closeModal]);

  useEffect(() => {
    if (!showUnreads) {
      setFilterUnreads(false);
    }
  }, [showUnreads]);

  useEffect(() => {
    if (selectedClient !== null && globalClients) {
      setActiveMessageNoteModal(true);
      updateLoadingConversation(false);
    }
  }, [selectedClient, globalClients, lastMessageReached, selectedTab, conversationState.conversations]);

  useEffect(() => {
    if (errorNotes !== null && dialogNoteReminder) {
      toast.current.show({
        severity: 'error', summary: 'Nota/Recordatorio', detail: errorNotes,
        sticky: true
      });
      updateErrorNotes(null);
    }
  }, [errorNotes, dialogNoteReminder]);


  const getInternalConversations = (searchQuery = null, showUnreads = false) => {
    setLoadingsearch(true);
    setLoading(true);
    fetchInternalConversations(1, searchQuery, showUnreads).then(response => {
      const internalConversations = response.data.objects.map((conversation) => formatInternalConversation(conversation));
      setConversationState({
        ...conversationState,
        conversations: internalConversations,
      })
      setLoading(false);
    }).catch(error => {
      console.error(error)
      setLoading(false);
    });
  };

  const fetchInternalConversations = (page, searchQuery = null, showUnreads = false) => {
    let params = {
      page: page,
      results_per_page: num_results,
      search_query: searchQuery,
      cancelToken: conversationRequest.token,
      unread_messages: showUnreads,
    }

    return apiService.getResources({
      url: '/internal_conversations',
      ...params
    });
  };

  const getMoreMessagesByContact = (page, fromId, showUnreads) => {
    setLoadingsearch(true);
    setLoading(true);

    let params = {
      page: page,
      results_per_page: num_results,
      from_id: fromId,
      cancelToken: conversationRequest.token
    }

    if (showUnreads !== null) {
      params['unread_messages'] = showUnreads;
    }

    return apiService.getResources({
      url: '/internal_conversations',
      ...params
    }).then(response => {
      const prevConversationsIds = conversationState.conversations.map(
        (conversation) => {
          return conversation.conversation_id;
        }
      );
      const newConversations = response.data.objects.filter(
        (conversation) => {
          return !prevConversationsIds.includes(conversation.conversation_id);
        }
      );

      const newConversationsFormatted = newConversations.map((conversation) => formatInternalConversation(conversation));

      let { page } = conversationState
      if (newConversationsFormatted) {
        page = page + 1;
      }

      setConversationState(prevState => ({
        ...prevState,
        conversations: [
          ...prevState.conversations,
          ...newConversationsFormatted
        ],
        page: page
      }));

      setLoadingsearch(false);
      setLoading(false);

      if (showUnreads) {
        setFilterUnreads(true);
      }

      return response;
    }).catch(error => {
      setLoadingsearch(false);
      setLoading(false);
    });
  };

  const getMessagesFiltered = (page, showUnreads, searchQuery, conversationFilterRequest) => {
    setLoading(true);
    setLoadingsearch(true);

    let params = {
      page: page,
      results_per_page: num_results,
      search_query: searchQuery,
      cancelToken: conversationFilterRequest.token
    }

    if (showUnreads !== null) {
      params['unread_messages'] = showUnreads;
    }

    apiService.getResources({
      url: '/internal_conversations',
      ...params
    }).then(response => {
      const filteredMessagesByContacts = response.data.objects.map((message) => formatInternalConversation(message));

      setConversationState({
        ...conversationState,
        conversations: [...filteredMessagesByContacts],
      })

      setLoading(false);
      setLoadingsearch(false);

      if (showUnreads) {
        setFilterUnreads(true);
      }

    }).catch((err) => {
      console.error(err)
      setLoadinglist(false);
      setLoading(false);
    });
  };

  const getMoreMessagesFiltered = (page, fromId, showUnreads, searchQuery) => {
    setLoading(true);
    setLoadinglist(true);
    let params = {
      page: page,
      results_per_page: num_results,
      from_id: fromId,
      search_query: searchQuery,
    }

    if (showUnreads !== null) {
      params['unread_messages'] = showUnreads;
    }

    return apiService.getResources({
      url: '/internal_conversations',
      ...params
    }).then(response => {
      const prevClientIds = conversationState.conversations.map(
        (message) => {
          return message.client_id;
        }
      );

      const newMessages = response.data.objects.filter(
        (message) => {
          return !prevClientIds.includes(message.client_id);
        }
      );

      const filteredMessagesByContacts = newMessages.map((message) => formatInternalConversation(message));

      let { page } = conversationState;

      if (filteredMessagesByContacts) {
        page = page + 1;
      }

      setConversationState({
        ...conversationState,
        conversations: [
          ...conversationState.conversations,
          ...filteredMessagesByContacts
        ],
        page: page,
      })
      setLoading(false);
      setLoadinglist(false);
      if (showUnreads) {
        setFilterUnreads(true);
      }

      return response;
    }).catch(error => {
      console.error(error)
      setLoadinglist(false);
      setLoading(false);
    });
  };

  const getInternalClients = () => {
    let url = '/internal_conversation/internal_clients?username=' + localStorage.getItem('username')
    apiService.getResources({
      url: url,
      results_per_page: num_results,
    }).then(response => {
      const internalClients = response.data.objects.map((conversation) => {
        return formatInternalConversation(conversation)
      })
      setClientsState(prevState => ({
        ...prevState,
        showedClients: internalClients,
      }));
    });
  };

  const getMoreInternalClients = (page, searchQuery = null) => {
    setLoading(true);
    let url = '/internal_conversation/internal_clients?username=' + localStorage.getItem('username')

    let params = {
      url: url,
      page: page,
      results_per_page: num_results,
      search_query: searchQuery,
      from_id: null,
    }

    return apiService.getResources({
      ...params
    }).then(response => {
      const internalClients = response.data.objects.map((conversation) => {
        return formatInternalConversation(conversation)
      })
      let { page } = clientsState;

      if (internalClients) {
        page = page + 1;
      }
      setClientsState(prevState => ({
        ...prevState,
        showedClients: [
          ...prevState.showedClients,
          ...internalClients
        ],
        page: page
      }));
      setLoading(false);
      return response;
    });
  };

  const getInternalGroups = () => {
    let url = '/internal_conversation/internal_groups?username=' + localStorage.getItem('username')
    apiService.getResources({
      url: url,
      results_per_page: num_results,
    }).then(response => {
      const internalGroups = response.data.objects.map((conversation) => {
        return formatInternalConversation(conversation)
      })
      setClientsState(prevState => ({
        ...prevState,
        internalGroups: internalGroups,
      }));
    });
  };

  const getMoreInternalGroups = (page, searchQuery = null) => {
    setLoading(true);
    let url = '/internal_conversation/internal_groups?username=' + localStorage.getItem('username')

    let params = {
      url: url,
      results_per_page: num_results,
      search_query: searchQuery,
      page: page,
    }

    return apiService.getResources({
      ...params
    }).then(response => {
      const internalGroups = response.data.objects.map((client) => {
        let newMessage = formatInternalConversation(client)
        return newMessage
      })
      if (internalGroups) {
        page = page + 1;
      }
      setClientsState({
        ...clientsState,
        internalGroups: [
          ...clientsState.internalGroups,
          ...internalGroups
        ],
        internalGroupsPage: page
      })
      setLoading(false);
      return response;
    });
  };

  const getClientsFiltered = (searchQuery, searchCustomTags, recipientFilterRequest, currentState) => {
    let url = '/internal_conversation/internal_clients'
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/internal_conversation/internal_clients?username=' + localStorage.getItem('username')
    }

    let params = {
      url: url,
      results_per_page: num_results,
      search_query: searchQuery,
      cancelToken: recipientFilterRequest.token
    }

    if (searchCustomTags !== null) {
      params['filters'] = { custom_tags: searchCustomTags };
    }

    apiService.getResources({
      url: url,
      ...params
    }).then(response => {
      const filteredClients = response.data.objects.map((client) => {
        let newMessage = formatInternalConversation(client)
        return newMessage
      })

      setClientsState(prevState => ({
        ...prevState,
        showedClients: filteredClients,
      }))
    }).catch((err) => {
      console.error(err)
      return;
    });
  };

  const getGroupsFiltered = (searchQuery, searchCustomTags, recipientFilterRequest, currentState) => {
    let url = '/internal_conversation/internal_groups'
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/internal_conversation/internal_groups?username=' + localStorage.getItem('username')
    }

    let params = {
      url: url,
      results_per_page: num_results,
      search_query: searchQuery,
      cancelToken: recipientFilterRequest.token
    }

    if (searchCustomTags !== null) {
      params['filters'] = { custom_tags: searchCustomTags };
    }

    apiService.getResources({
      url: url,
      ...params
    }).then(response => {
      const filteredClients = response.data.objects.map((client) => {
        let newMessage = formatInternalConversation(client)
        return newMessage
      })

      setClientsState(prevState => ({
        ...prevState,
        internalGroups: filteredClients,
      }))
    }).catch((err) => {
      console.error(err)
      return;
    });
  };

  const getMessagesFilteredDebounced = useRef(debounce(500, getMessagesFiltered));
  const getMessagesFilteredThrottled = useRef(throttle(500, getMessagesFiltered));
  const getClientsFilteredThrottled = useRef(throttle(600, getClientsFiltered));
  const getClientsFilteredDebounced = useRef(debounce(500, getClientsFiltered));
  const getGroupsFilteredThrottled = useRef(throttle(600, getGroupsFiltered));
  const getGroupsFilteredDebounced = useRef(debounce(500, getGroupsFiltered));

  useEffect(() => {
    const CancelTokenFilterRequest = axios.CancelToken;
    const conversationFilterRequest = CancelTokenFilterRequest.source();
    if (showUnreads || searchValue) {
      if (searchValue.length < 5) {
        if (selectedTab == 3) getGroupsFilteredThrottled.current(searchValue, conversationFilterRequest, { ...clientsState, page: 1 });
        if (selectedTab == 2) getClientsFilteredThrottled.current(searchValue, conversationFilterRequest, { ...clientsState, page: 1 });
        else getMessagesFilteredThrottled.current(1, showUnreads, searchValue, conversationFilterRequest, { ...conversationState, page: 1 });
      } else {
        if (selectedTab == 3) getGroupsFilteredDebounced.current(searchValue, conversationFilterRequest, { ...clientsState, page: 1 });
        if (selectedTab == 2) getClientsFilteredDebounced.current(searchValue, conversationFilterRequest, { ...clientsState, page: 1 });
        else getMessagesFilteredDebounced.current(1, showUnreads, searchValue, conversationFilterRequest, { ...conversationState, page: 1 })
      }
    }
    else {
      if (selectedTab == 3) getGroupsFilteredDebounced.current(null, conversationFilterRequest, { ...clientsState, page: 1 });
      if (selectedTab == 2) getClientsFilteredDebounced.current(null, conversationFilterRequest, { ...clientsState, page: 1 });
      else getMessagesFilteredDebounced.current(1, showUnreads, null, conversationFilterRequest, { ...conversationState, page: 1 })
      setConversationState({
        ...conversationState,
        conversations: [...conversationState.conversations],
        page: 1
      })
      setClientsState(prevState => ({
        ...prevState,
        showedClients: [...prevState.showedClients],
        internalGroups: [...prevState.internalGroups],
        page: prevState.page,
        internalGroupspage: prevState.internalGroupspage,
      }));
    }
    return () => {
      conversationFilterRequest.cancel()
      setTimeout(function () {
        setLoadingsearch(false);
      }, 2000);
    }
  }, [showUnreads, selectedTab, searchValue]);


  const addOrUpdateConversation = (message) => {
    let isUser = localStorage.getItem('username') === message?.last_message?.recipient_internal_client?.user?.username
    let newConversations = [...conversationState.conversations];

    const newMessage = formatInternalConversation(message);
    if (message && isUser && newMessage.unread_count !== 0) {
      const body = newMessage?.last_message?.mime_type !== null ? nameMimetype(newMessage?.last_message?.mime_type) : newMessage.last_message.body;
      if (profile?.notification_available) {
        showNotification(
          newMessage.name,
          { body: "Nuevo Mensaje Interno\n\n" + body, icon: `${process.env.REACT_APP_PAGE}/favicon.ico` },
          message
        );
      }
      if (profile?.sound_available && localStorage.getItem('token')) {
        sendNotificationSound();
      }
    }

    // Find and update or add the new conversation in conversations
    const conversationIndex = newConversations.findIndex(conv => conv.conversation_id === newMessage.conversation_id);
    if (conversationIndex > -1) {
      newConversations[conversationIndex] = newMessage;
    } else {
      newConversations.unshift(newMessage);
    }

    // Update the state with new messages and conversations
    setConversationState({
      ...conversationState,
      conversations: newConversations,
    });
  }


  const removeMessageReaction = (message) => {
    const updatedConversations = conversationState.conversations.map(messageToUpdate => {
      return messageToUpdate.id === message.id
        ? { ...messageToUpdate, body: message.parent.body, text: message.parent.body }
        : messageToUpdate;
    });

    setConversationState({
      ...conversationState,
      conversations: updatedConversations
    });
  };

  const updateMessage = (message) => {

    const { conversations } = conversationState;

    let updatedConversations = [...conversations];

    let conversationsIndexedByClientId = listToObject(conversations, 'client_id');

    updatedConversations.map((element) => element.client_id === message.client_id ? message.created = element.created : message.created = message.created);
    const formatedMessage = formatInternalConversation(message);


    if (conversationsIndexedByClientId[formatedMessage.client_id]) {
      updatedConversations = conversations.map(c => c.client_id === formatedMessage.client_id ? formatedMessage : c);
    }
    if (!searchValue) {
      if (!conversationsIndexedByClientId[formatedMessage.client_id]) {
        const position = getLessNumberIndexThan(formatedMessage.id, conversations.map(m => m.id));
        updatedConversations.splice(position, 0, formatedMessage);
      }
    }
    updatedConversations = conversations;
    if (selectedTab === 2) {
      const currentShowedClients = showedClientsRef.current
      let updatedShowedClients = updateShowedClients(formatedMessage.client, currentShowedClients, searchValueRef.current)
      setClientsState({
        ...clientsState,
        showedClients: updatedShowedClients
      })
    }

    setConversationState({
      ...conversationState,
      conversations: updatedConversations,
    })
  }


  const addNewNote = (message) => {
    let allowed_message_types = ['note_reminder', 'scheduled_message'];

    if (!allowed_message_types.includes(message.message_type.name)) {
      return null;
    }
    let notifyMessage = true;
    let privateInbox = false;

    if (company_context.privateInbox) {
      if (!['admin', 'supervisor'].includes(switchRole())) {
        privateInbox = true;
      }
    }
    if (privateInbox) {
      if (message?.client?.conversation?.group !== null) {
        if (company_context.privateGroup) {
          if (message?.client?.conversation?.user.username !== localStorage.getItem('username')) {
            notifyMessage = false;
          }
        } else {
          if (message?.client?.conversation !== null) {
            notifyMessage = false;
          } else {
            notifyMessage = false;
          }
        }
      } else {
        if (message?.client?.conversation?.user?.username !== localStorage.getItem('username')) {
          notifyMessage = false;
        }
      }
    }

    if (notifyMessage) {
      let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';

      let message_notes_temp = [...messageNotes[messageNoteKey]]

      message_notes_temp.unshift(message);

      updateMessageNotes(prevState => {
        return {
          ...prevState,
          [messageNoteKey]: message_notes_temp
        }
      })
    }

  }

  const deleteNoteReminder = (message) => {
    if (!globalClients) {
      return null;
    }

    let allowed_message_types = ['note_reminder', 'scheduled_message'];
    if (!allowed_message_types.includes(message.message_type.name)) {
      return null;
    }

    let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
    let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id);

    updateMessageNotes(prevState => {
      return {
        ...prevState,
        [messageNoteKey]: updated_messages
      }
    })

    setDialogNoteReminder(false);

    toast.current.show({
      severity: 'success', summary: 'Recordatorio', detail: 'Se elimino el recordatorio',
      sticky: true
    });
  }

  const seenNoteReminder = (message) => {
    if (!globalClients) {
      return null;
    }

    let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
    let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id);

    updateMessageNotes(prevState => {
      return {
        ...prevState,
        [messageNoteKey]: updated_messages
      }
    })

    setDialogNoteReminder(false);
    updateExpiredNoteReminders(updated_messages);
    toast.current.show({
      severity: 'success', summary: 'Recordatorio', detail: 'Se marcó como visto el recordatorio',
      sticky: true
    });
  }

  const updateStatusMessage = ({ message, status }) => {
    const client_id = message.client_id;

    let conversations = [...conversationState.conversations];
    let conversation_filtered = conversations.filter(x => x.client_id === client_id)[0];

    if (conversation_filtered !== undefined) {
      conversation_filtered[status] = true;
    }
    let conversation_filtered_index = conversations.findIndex(x => x.client_id === client_id);

    if (conversation_filtered_index !== -1) {
      conversations[conversation_filtered_index] = conversation_filtered;
    }

    setConversationState({
      ...conversationState,
      conversations: [...conversations]
    });
  }

  const updateExpiredNoteReminders = (notes) => {
    const date_now = moment(new Date()).format('DD/MM/YYYY')
    let reminders_expired = notes.filter(x =>
      moment(x.note_reminder.reminder_date).format('DD/MM/YYYY') === date_now)
    updateExpirationNotes(reminders_expired.length);
  }

  const filterConversationList = (value) => {
    setSearchValue(value)
  }


  const onChangeFilterHandler = (event) => {
    filterConversationList(event.target.value);
  }

  const onClearValue = () => {
    filterConversationList('');
  }

  const handleScroll = () => {
    if (paginating || loading) return;

    const hasConversations = conversationState.conversations.length > 0;
    const hasShowedClients = clientsState.showedClients.length > 0;
    const hasInternalGroups = clientsState.internalGroups.length > 0;

    if (!hasConversations && !hasShowedClients && !hasInternalGroups) return;

    if (selectedTab === 2 && !lastClientReached && hasShowedClients) {
      setPaginating(true);
      getMoreInternalClients(clientsState.page + 1, searchValue)
        .then(result => {
          if (result.data.objects.length === 0 || result.data.num_results < num_results) {
            setLastClientReached(true);
          }
          setPaginating(false);
        });
    }

    if (selectedTab === 3 && !lastClientReached && hasInternalGroups) {
      setPaginating(true);
      getMoreInternalGroups(clientsState.internalGroupsPage + 1, searchValue)
        .then(result => {
          if (result.data.objects.length === 0 || result.data.num_results < num_results) {
            setLastClientReached(true);
          }
          setPaginating(false);
        });
    }

    if (selectedTab !== 2 && !lastMessageReached && hasConversations) {
      setPaginating(true);
      getMoreMessagesByContact(conversationState.page + 1, null, showUnreads)
        .then(result => {
          if (result.data.objects.length === 0 || result.data.num_results < num_results) {
            setLastMessageReached(true);
          }
          setPaginating(false);
        });
    }
  }

  let itemsToShow;

  if (selectedTab === 3) {
    itemsToShow = clientsState.internalGroups;
  } else if (selectedTab === 2) {
    itemsToShow = clientsState.showedClients;
  } else {
    itemsToShow = [...conversationState.conversations].sort((a, b) => {
      const dateA = new Date(a.last_message.created);
      const dateB = new Date(b.last_message.created);
      return dateB - dateA;
    });
  }

  const conversationItems = (
    <>
      {
        itemsToShow.map((conversation) => {
          if (conversation !== undefined) {
            const uniqueId = getUniqueConversationId(conversation);
            const active = isConversationActive(conversation, conversationActive);

            return (<ConversationListItem key={uniqueId}
              supervisor={supervisor}
              data={conversation}
              selectedTab={selectedTab}
              onClick={() => conversationListItemHandler(conversation)}
              conversationActive={active}
            />)
          }
          else {
            return null
          }
        })}
    </>
  );

  const conversationListItemHandler = (conversation) => {
    props.onSelectClientHandler(conversation);
    setConversationActive(conversation);
    const currentActiveId = getUniqueConversationId(conversationActive);
    const newActiveId = getUniqueConversationId(conversation);
    if (newActiveId !== currentActiveId) {
      if (player.ref !== null && player.ref !== undefined && !audioEnded && !isMobile()) {
        player.hidden = false;
        setIsAudioVisible(true)
        player.play();
      }
    }
  }

  const handleAudioHide = () => {
    setAudioEnded(true)
    player.hidden = true;
    setIsAudioVisible(false)
    player.pause();
  }


  useEffect(() => {
    switch (selectedTab) {
      case 0:
        setShowUnreads(null);
        break
      case 1:
        setShowUnreads(true);
        break
      case 3:
        setShowUnreads(null);
        break
      case 4:
        setShowUnreads(null);
        break
      case 5:
        setShowUnreads(null);
        break
      default:
        break;
    }
  }, [(selectedTab)]);

  let render_modal = dialogReassignMessages ? modalInfo : null;

  let modal_message_note_reminders = dialogNoteReminder ? <MessageNoteReminder enabled={dialogNoteReminder}
    setEnabled={setDialogNoteReminder}
    isGlobal={true} /> : null

  let render_note_messages = selectedMessageNote !== null && globalClients ? <MessagesNoteModal
    selectedMessage={selectedMessageNote} selectedClient={selectedClient} contactSelected={selectedContact}
    activeNotes={activeMessageNoteModal} setActiveNotesEnabled={setActiveMessageNoteModal}
    clientFullName={clientFullName}
  /> : null;

  const rightToolBarItems = () => {
    let reminder_counter = expirationNotes > 0 ? (
      <span className="fa-layers-counter fa-layers-bottom-right">{expirationNotes}</span>) : null;

    let reminderShakeProp = expirationNotes > 0 ? { 'shake': true } : { 'shake': false };

    let reminderShakePropMore = !itemMenuMore ? expirationNotes > 0 ? { 'shake': true } : { 'shake': false } : null;

    let more_items = (
      <ToolbarButton key="more_items"
        icon={"fa-regular fa-ellipsis-vertical"}
        style={{ width: '2em', height: '2em' }}
        onClick={() => itemMenuMore ? setItemMenuMore(false) : setItemMenuMore(false)}
        {...reminderShakePropMore}>
        {!itemMenuMore ? <div style={{ position: 'relative', right: '10px' }}>{reminder_counter}</div> : null}
        {itemMenuMore ? <FontAwesomeIcon icon={'times'} size={'1x'} transform={'shrink-1 up-12 right-16'} /> : null}
      </ToolbarButton>
    );

    let reminders = (
      <ToolbarButton key="reminders"
        name="Recordatorios"
        icon={"fa-regular fa-calendar-check"}
        size={"xs"}
        style={{ width: '2em', height: '2em' }}
        onClick={() => showMessageNoteReminder()}
        {...reminderShakeProp}>
        <div style={{ position: 'relative', right: '5px' }}>{reminder_counter}</div>
      </ToolbarButton>
    );
    if (itemMenuMore) return [more_items, reminders]
    else return [more_items]

  };

  const showMessageNoteReminder = () => {
    setDialogNoteReminder(true);
  }

  const conversationScroll = (
    <InfiniteScroll
      pageStart={0}
      initialLoad={false}
      loadMore={handleScroll}
      hasMore={true}
      useWindow={false}
      getScrollParent={() => props.parentRef.current}
      loader={loadinglist ? <ConversationSkeleton count={5} /> : null}
    >
      {conversationItems}
    </InfiniteScroll>
  )


  const conversationView = (
    <div className='scrollable' ref={props.parentRef}>
      <div className='conversation-list'>
        {!loadingsearch ? conversationScroll : <ConversationSkeleton count={10} />}
      </div>
    </div>
  );

  const conversationViewMobile = (
    <div className='conversation-list' style={{ width: isMobile() ? height - 20 : 'auto' }}>
      {!loadingsearch ? conversationScroll : <ConversationSkeleton count={5} />}
    </div>
  );

  const toolbar = (
    <div>
      <Toolbar
        rightItems={[]}
        hideShowLeftItems={false}
        leftItems={<ChannelSelector />}
        className={"toolbar toolbar-custom"}>
      </Toolbar>
      <CustomTabHeadersInternalChat fontAwesomeIcon={true} headers={internalChatTabOptions} selectedIndex={selectedTab} onChange={setSelectedTab} />
    </div>
  );

  const searchInput = (
    <div className='search-filter'>
      {showConversationListGroups || showConversationListUsers ? null : <ConversationSearch className='internal-conversation-search' onClearValue={onClearValue} onChange={onChangeFilterHandler} value={searchValue} loading={loadingsearch} />}
    </div>
  )

  const audioCurrent = (
    <div className={isAudioVisible ? "audio-player-visible" : "audio-player-hidden"}>
      {isAudioVisible ? (
        <div className="audio-info-container">
          <button onClick={() => handleAudioHide()}
            type="button"
            class="p-dialog-header-icon p-dialog-header-close-audio p-link"
            aria-label="Close">
            <span class="p-dialog-header-close-icon pi pi-times" />
          </button>
          <Avatar
            maxInitials={1}
            name={player ? player.name : 'Cliente'}
            round={true}
            size="60" />
          <div className="conversation-title">
            <h1 className="conversation-list-item-name">
              {player ? player.name : 'Cliente'}
            </h1>
          </div>
        </div>
      ) : null}
      <audio id="globalAudioPlayer" className='audio-info-container' controls hidden controlsList='nodownload noplaybackrate' />
    </div>
  );

  const conversationUp = (
    <div style={{ width: isMobile() ? height - 20 : 'auto' }}>
      {toolbar}
      {searchInput}
    </div>
  )

  return (
    <div className='messenger-conversation-list-container'>
      {conversationUp}
      <div className='content'>
        {audioCurrent}
        <Toast ref={toast} />
        {isMobile() ? conversationViewMobile : conversationView}
        {!loadingsearch ? loading ? null : itemsToShow.length === 0 ? <ResultNotFount /> : null : null}
        {render_modal}
        {modal_message_note_reminders}
        {render_note_messages}
        {loadingConversation ? <CustomSpinner status={'Obteniendo conversaciónes...'} style={{ paddingLeft: '22%' }} /> : null}
      </div>
    </div>
  );
});

export default ConversationList