import React, {useState, useContext} from 'react';
import './MessageReactions.css';
import {Dialog} from "primereact/dialog";
import {MessageContext} from "../../../context/messageContext";


export default function MessageReactions(props) {
    const {data} = props;
    const [visibleEmojiDialog, setVisibleEmojiDialog] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const {updateMessageReaction} = useContext(MessageContext);

    const reactions = data.map(reaction => {
        return (
            <span key={reaction.id}>{reaction.emoji}</span>
        )
    })

    const deleteReaction = (message_id) => {
        updateMessageReaction({
            message_id: message_id,
            emoji: ''
        });

        setVisibleEmojiDialog(false);
    }

    const reaction_details = data.map(reaction => {
        let reaction_type = reaction.type === 'sent' ? 'Tú' : props.contact;
        let can_delete = reaction.type === 'sent' ? (
            <div className={'reaction-detail-label-remove'} onClick={(e) => deleteReaction(reaction.id)}>
                Toca para eliminar
            </div>
        ) : null;

        return (
            <div className={'mt-2'} key={reaction.id}>
                <div className={'flex p-jc-between'}>
                    <div className="conversation-info">
                        <img className="conversation-photo" src='/assets/layout/images/profile.png' alt={'contact'}/>
                    </div>
                    <div className={'reaction-detail-label '}>
                        <div className={'reaction-detail-label-sender'}>
                            {reaction_type}
                        </div>
                        {can_delete}
                    </div>
                    <div className={'reaction-detail-emoji'}>
                        <span>{reaction.emoji}</span>
                    </div>

                </div>
            </div>
        )
    })

    const onClickButton = (e) => {
        setVisibleEmojiDialog(!visibleEmojiDialog)
        setIsPopoverOpen(!isPopoverOpen)
    }

    const getEmojisForm = () => {

        return (
            <Dialog header={'Reacciones'} visible={visibleEmojiDialog} style={{ width: '20vw' }} onHide={() => setVisibleEmojiDialog(false)}>
                <div>
                    <div className={'reactions-main'}>
                        {reaction_details}
                    </div>
                </div>
            </Dialog>
        )
    }

    return (
        <div className={'reactions'} aria-controls={props.id}   onClick={(e) => onClickButton(e)}>
            <div className='flex p-jc-between message-reactions'>
                {reactions}
                {/*{getEmojisForm()}*/}
            </div>
        </div>
    );

}
