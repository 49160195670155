import React from 'react';
import ContactList from '../components/Clients/Contacts/ContactList';
import StringToHtml from '../components/General/StringToHtml';
import CustomTagsList from '../components/Audiences/Audience/CustomTagsList'
import SmsConfigView from "../components/SmsSettings/SmsSetting/SmsConfigView";
import FrecuencyView from "../components/Frecuency/FrecuencyView";


const CustomComponent = ({name, component_props, value}) =>  {
        let CustomComponent = null;
        switch(name){
            case 'ContactList':
                CustomComponent = ContactList;
                break;
            case 'StringToHtml':
                CustomComponent = StringToHtml;
                break;
            case 'CustomTagsList':
                CustomComponent = CustomTagsList;
                break;
            case 'SmsConfigView':
                CustomComponent = SmsConfigView;
                break;
            case 'FrecuencyView':
                CustomComponent = FrecuencyView;
                break;
            default:
                CustomComponent = (<div></div>);
                break;
        }

        return (
            <CustomComponent value={value} {...component_props}/>
        );
};

export default CustomComponent;