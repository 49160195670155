import React, { createContext, useContext, useState, useEffect } from 'react';

const AudioPlayerContext = createContext();

export const useAudioPlayer = () => useContext(AudioPlayerContext);

export const AudioPlayerProvider = ({ children }) => {
  const [audioEnded, setAudioEnded] = useState(false);
  const player = document.getElementById('globalAudioPlayer');
  const [isAudioVisible, setIsAudioVisible] = useState(false);

  useEffect(() => {
    const handleAudioEnd = () => setAudioEnded(true);
    const handleAudioPause = () => {
      setAudioEnded(true)
    }
    if (player){
      player.addEventListener('ended', handleAudioEnd);
      player.addEventListener('pause', handleAudioPause);
    }

    return () => {
      if (player){
        player.removeEventListener('ended', handleAudioEnd);
        player.removeEventListener('pause', handleAudioPause);
      }
    };
  }, [player]);

    const handlePause = () => {
      setAudioEnded(true);
    }

    const handlePlay = (audioUrl, audioRefs, index, authorName) => {

      audioRefs.current.map((audioRef, indexRef) => {
        if (audioRef) {
          if (indexRef !== index) {
            audioRef.pause();
          } else {
            if (player) {
              player.name = authorName;
              player.ref = audioRefs;
              player.pause();
              player.src = audioUrl;
              player.hidden = true;
              player.currentTime = audioRef.currentTime;
              setIsAudioVisible(false);
            }
            setAudioEnded(false);

            audioRef.play();

            const syncTime = () => {
              if (player){
                player.currentTime = audioRef.currentTime;
              }
                setAudioEnded(false);
            };

            audioRef.addEventListener('timeupdate', syncTime);

            const removeSync = () => {
                audioRef.removeEventListener('timeupdate', syncTime);
            };

            audioRef.addEventListener('pause', removeSync);
            audioRef.addEventListener('ended', removeSync);
          }
        }
      });

    };

    const contextValue = {
      handlePlay,
      handlePause,
      audioEnded,
      setAudioEnded,
      isAudioVisible,
      setIsAudioVisible
    };

    return (
      <AudioPlayerContext.Provider value={contextValue}>
        {children}
      </AudioPlayerContext.Provider>
    );
};
