export const ADMIN = '1';
export const AUDIENCE = '4';
export const SUPERVISOR = '9';

export const switchRole = () => {
    let role;

    switch(localStorage.getItem('role')) {
        case ADMIN:
            role = 'admin';
            break;
        case AUDIENCE:
            role = 'audience';
            break;
        case SUPERVISOR:
            role = 'supervisor';
            break;
        default:
            role = null;
            break;
    }
    return role;
};