import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from './layout/Layout';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import * as actions from './store/actions/index';
import { switchRole } from './shared/roles';
import { withRouter } from "react-router";
import './assets/css/App.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './AppStyle.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { ProSidebarProvider } from 'react-pro-sidebar';
import CompanyContextProvider from './context/providers/CompanyContextProvider';

import ClientForm from "./components/Clients/Client/ClientForm";

import ClientBulk from "./components/Clients/Client/ClientBulk";

import Audiences from "./components/Audiences/Audiences";
import Avatar, { ConfigProvider } from 'react-avatar';

import Providers from "./components/Providers/Providers";
import Provider from "./components/Providers/Provider/Provider";

import ProviderEdit from "./components/Providers/Provider/ProviderEdit";
import ProviderInfoEdit from "./components/Providers/Provider/ProviderInfoEdit";
import ProviderChannel from "./components/Providers/Provider/ProviderChannel";

import Notifications from "./components/Notifications/Notifications";
import NotificationForm from "./components/Notifications/Notification/NotificationForm";

import EmailSetting from "./components/EmailSettings/EmailSetting";
import EmailSettingForm from "./components/EmailSettings/EmailSetting/EmailSettingForm";

import SmsSettings from "./components/SmsSettings/SmsSettings";
import SmsSettingForm from "./components/SmsSettings/SmsSetting/SmsSettingForm";

import CustomTags from "./components/CustomTags/CustomTags";
import CustomTagForm from "./components/CustomTags/CustomTag/CustomTagForm";

import ReportNotification from "./components/Reports/ReportNotification";
import ReportClientExport from './components/Reports/ClientExport/ReportClientExport';

import Channels from "./components/Channels/Channels";
import Channel from "./components/Channels/Channel/Channel";
import ChannelEdit from "./components/Channels/Channel/ChannelEdit";

import Users from "./components/Users/Users";
import Profile from "./components/Users/User/Profile";

import MessengerComponent from "./components/Messenger/MessengerComponent";

import Logout from './containers/Auth/Logout/Logout';

import {SecretRoute} from "./SecretRoute";

import WhatsAppAnalytics from "./components/Analytics/WhatsAppAnalytics";

import ProviderWebhookForm from "./components/ProviderWebhook/ProviderWebhookForm"
import ProviderWebhooks from "./components/ProviderWebhook/ProviderWebhooks";
import BackgroundTasksBulk from "./components/BackgroundTasksBulk/BackgroundTasksBulk";
import ChatBots from "./components/ChatBot/ChatBots";
import ChatBotForm from "./components/ChatBot/ChatBotForm";
import Questionnaires from "./components/ChatBot/Questionnaires/Questionnaires";
import QuestionnaireForm from "./components/ChatBot/Questionnaires/QuestionnaireForm";

import WhatsappWeb from "./components/WhatsappWeb/WhatsappWeb"
import AccountSettings from "./components/AccountSettings/AccountSettings";
import UserForm from "./components/Users/User/UserForm";

import Groups from "./components/Groups/Groups";
import GroupForm from "./components/Groups/GroupForm";
import { pastelColors } from './utils/utils';

import ProviderScheduleds from './components/ProviderScheduled/ProviderScheduled';
import ProviderScheduledForm from './components/ProviderScheduled/ProviderScheduledForm';

import AiAssistants from './components/AiAssistants/AiAssistants';
import AiAssistantForm from './components/AiAssistants/AiAssistant/AiAssistantForm';
import AiAssistantView from './components/AiAssistants/AiAssistant/AiAssistantView';

import ChatChannelContainer from './components/ChatChannelContainer/ChatChannelContainer';
import InternalGroups from './components/InternalGroups/InternalGroups';
import InternalGroupForm from './components/InternalGroups/InternalGroupForm';
import ChatBotsPush from './components/ChatBot/ChatBotsPush';

const asyncAuth = asyncComponent(() => {
    return import('./containers/Auth/Auth');
});


library.add(fas, fab)

class App extends Component {

    constructor(){
        super();
        this.state = { isMobile: false };
    }

    componentDidMount () {
        this.props.onTryAutoSignup();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ isMobile: window.innerWidth <= 1024 });
    };

    render(){
        const token = localStorage.getItem('token');

        let routes = (
            <Switch>
                <Route path="/auth" component={asyncAuth} />
                <Route path='/default' component={() => {window.location = 'http://prontocobro.com'; return null;} }/>
            </Switch>
        );

        let indexHome = MessengerComponent;

        if (switchRole() === 'supervisor' ) {
            indexHome = MessengerComponent;
        }
        else if (switchRole() === 'audience'){
            indexHome = ChatChannelContainer;
        } else if (switchRole() === 'admin'){
            indexHome = Providers;
        }

        if (token){
            routes = (
                <Switch>
                    <SecretRoute path="/" exact component={indexHome} allowed={['admin', 'audience', 'supervisor']}/>
                    <SecretRoute path="/logout" component={Logout} allowed={['admin', 'audience', 'supervisor']}/>

                    <SecretRoute path="/messenger" component={MessengerComponent} allowed={['admin', 'audience', 'supervisor']}/>

                    <SecretRoute path="/audiences" exact component={Audiences} allowed={['audience', 'supervisor']}/>
                    <SecretRoute path="/audiences/new" exact component={ClientForm} allowed={['audience', 'supervisor']}/>
                    <SecretRoute path="/audience/:id/edit" component={ClientForm} allowed={['audience', 'supervisor']}/>

                    <SecretRoute path="/clients" exact component={Providers} allowed={['admin']} />
                    <SecretRoute path="/clients/new" exact component={Provider} allowed={['admin']} />
                    <SecretRoute path="/clients/bulk" exact component={ClientBulk} allowed={['supervisor']} />
                    <SecretRoute path="/client/info" exact component={ProviderInfoEdit} allowed={['supervisor']} />
                    <SecretRoute path="/clients/:id/channels" exact component={ProviderChannel} allowed={['admin']} />
                    <SecretRoute path="/clients/:id/edit" component={ProviderEdit} allowed={['admin']} />
                    
                    <SecretRoute path="/ai_assistants" exact component={AiAssistants} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/ai_assistants/new" exact component={AiAssistantForm} allowed={['admin','supervisor']} />
                    <SecretRoute path="/ai_assistant/:id/edit" exact component={AiAssistantForm} allowed={['admin','supervisor']} />
                    <SecretRoute path="/ai_assistant/:id" exact component={AiAssistantView} allowed={['admin', 'supervisor']} />

                    <SecretRoute path="/email_setting" exact component={EmailSetting} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/email_setting/new" exact component={EmailSettingForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/email_settings/:id/edit" exact component={EmailSettingForm} allowed={['admin', 'supervisor']} />

                    <SecretRoute path="/sms_settings" exact component={SmsSettings} allowed={['admin']} />
                    <SecretRoute path="/sms_settings/new" exact component={SmsSettingForm} allowed={['admin']} />
                    <SecretRoute path="/sms_setting/:id/edit" exact component={SmsSettingForm} allowed={['admin']} />

                    <SecretRoute path="/custom_tags" exact component={CustomTags} allowed={['audience', 'supervisor']} />
                    <SecretRoute path="/custom_tags/new" exact component={CustomTagForm} allowed={['audience', 'supervisor']} />
                    <SecretRoute path="/custom_tags/:id/edit" exact component={CustomTagForm} allowed={['audience', 'supervisor']} />

                    <SecretRoute path="/notifications" exact component={Notifications} allowed={['supervisor']} />
                    <SecretRoute path="/notifications/new" exact component={NotificationForm} allowed={['supervisor']} />
                    <SecretRoute path="/notification/:id/edit" component={NotificationForm} allowed={['supervisor']} />

                    <SecretRoute path="/reports/notification" exact component={ReportNotification} allowed={['admin', 'supervisor']} />

                    <SecretRoute path="/reports/client" exact component={ReportClientExport} allowed={['supervisor']} />

                    <SecretRoute path="/channels" exact component={Channels} allowed={['admin']} />
                    <SecretRoute path="/channels/new" exact component={Channel} allowed={['admin']} />
                    <SecretRoute path="/channel/:id/edit" component={ChannelEdit} allowed={['admin']} />

                    <SecretRoute path="/users" exact component={Users} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/users/new" exact component={UserForm} allowed={['admin','supervisor']} />
                    <SecretRoute path="/user/:id/edit" exact component={UserForm} allowed={['admin','supervisor']} />
                    <SecretRoute path="/account/settings" exact component={AccountSettings} allowed={['supervisor']} />

                    <SecretRoute path="/profile" exact component={Profile} allowed={['admin', 'audience', 'supervisor']} />
                    <SecretRoute path="/analytics/whatsapp" exact component={WhatsAppAnalytics} allowed={['supervisor']} />

                    <SecretRoute path="/provider_webhooks" exact component={ProviderWebhooks} allowed={['supervisor']} />
                    <SecretRoute path="/provider_webhooks/new" exact component={ProviderWebhookForm} allowed={['supervisor']} />
                    <SecretRoute path="/provider_webhooks/:id/edit" exact component={ProviderWebhookForm} allowed={['supervisor']} />

                    <SecretRoute path="/setting_whatsapp" exact component={WhatsappWeb} allowed={['supervisor']} />

                    <SecretRoute path="/provider_scheduled" exact component={ProviderScheduleds} allowed={['supervisor']} />
                    <SecretRoute path="/provider_scheduled/:action" exact component={ProviderScheduleds} allowed={['supervisor']} />
                    <SecretRoute path="/provider_scheduled/new" exact component={ProviderScheduledForm} allowed={['supervisor']} />
                    <SecretRoute path="/provider_scheduled/new/:action" exact component={ProviderScheduledForm} allowed={['supervisor']} />
                    <SecretRoute path="/provider_scheduled/:id/edit/:action" exact component={ProviderScheduledForm} allowed={['supervisor']} />
                    <SecretRoute path="/provider_scheduled/:id/edit" exact component={ProviderScheduledForm} allowed={['supervisor']} />

                    <SecretRoute path="/tasks" exact component={BackgroundTasksBulk} allowed={['supervisor']} />

                    <SecretRoute path="/chat_bots/push" exact component={ChatBotsPush} allowed={['supervisor']} />
                    <SecretRoute path="/chat_bots" exact component={ChatBots} allowed={['supervisor']} />
                    <SecretRoute path="/chat_bots/new" exact component={ChatBotForm} allowed={['supervisor']} />
                    <SecretRoute path="/chat_bots/:id/edit" exact component={ChatBotForm} allowed={['supervisor']} />

                    <SecretRoute path="/questionnaires" exact component={Questionnaires} allowed={['supervisor']} />
                    <SecretRoute path="/questionnaires/new" exact component={QuestionnaireForm} allowed={['supervisor']} />
                    <SecretRoute path="/questionnaires/:id/edit" exact component={QuestionnaireForm} allowed={['supervisor']} />

                    <SecretRoute path="/internal_groups" exact component={InternalGroups} allowed={['supervisor']} />
                    <SecretRoute path="/internal_groups/new" exact component={InternalGroupForm} allowed={['supervisor']} />
                    <SecretRoute path="/internal_groups/:id/edit" exact component={InternalGroupForm} allowed={['supervisor']} />

                    <SecretRoute path="/groups" exact component={Groups} allowed={['supervisor']} />
                    <SecretRoute path="/groups/new" exact component={GroupForm} allowed={['supervisor']} />
                    <SecretRoute path="/groups/:id/edit" exact component={GroupForm} allowed={['supervisor']} />

                    <SecretRoute path="/whatsapp/contact/:phone_number" component={MessengerComponent} allowed={['admin', 'audience', 'supervisor']}/>

                    <Route path='/default' component={() => {window.location = 'http://prontocobro.com'; return null;} }/>

                    <Redirect to="/" />
                </Switch>
            );
        }

        return (
            <CompanyContextProvider>
                <ProSidebarProvider>
                    <ConfigProvider colors={pastelColors}>
                        <Layout key={localStorage.getItem('role')}>
                        {routes}
                        {this.props.children}
                        </Layout>
                    </ConfigProvider>
                </ProSidebarProvider>
            </CompanyContextProvider>
        );
    }

}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() )
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
