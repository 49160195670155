import React, { useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { CompanyContext } from '../../context/companyContext';

const ChannelSelector = () => {
    const { chatChannel, setChatChannel, internalChatEnabled } = useContext(CompanyContext);

    let channels = [
        { name: 'WhatsApp', code: 'whatsapp', image: `${process.env.REACT_APP_PAGE}/images/whatsapp.png` },
        { name: 'Chat Interno', code: 'internal', image: `${process.env.REACT_APP_PAGE}/images/internal-chat.png` },
    ];

    channels = internalChatEnabled ? channels : channels.filter(channel => channel.code !== 'internal');

    const selectedChannel = channels.find(c => c.code === chatChannel);

    const handleChannelChange = (e) => {
        setChatChannel(e.value.code);
    };

    const selectedChannelTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center text-lg font-semibold">
                    <img alt={option.name} src={option.image} style={{ width: '24px', marginRight: '8px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    };

    const channelOptionTemplate = (option) => {
        return (
            <div className="flex w-full align-items-center">
                <img alt={option.name} src={option.image} style={{ width: '24px', marginRight: '8px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div>
            <Dropdown
                className="flex border-none"
                panelClassName=""
                value={selectedChannel}
                options={channels}
                onChange={handleChannelChange}
                optionLabel="name"
                placeholder="Select a Channel"
                valueTemplate={selectedChannelTemplate}
                itemTemplate={channelOptionTemplate}
            />
        </div>
    );
};

export default ChannelSelector;