import React from 'react';
import Resources from '../Resources/Resources';

const InternalGroups = () => {
    const resource = {
        title: 'Grupos',
        url: '/internal_groups',
        colsHeader: [
            {
                field: 'name',
                header: 'Nombre'
            },
            {
                field: 'user_accounts',
                header: 'Participantes'
            },
            {
                field: 'action',
                header: 'Acciones'
            }
        ],
        action: {
            add: '/new',
            show: '/internal_groups/',
            edit: '/edit',
            delete: true,
        },
        urlTable: [
            '/internal_groups',
            '/internal_groups/new',
            '^\\/internal_groups\\/\\d+$',
            '^\\/internal_groups\\/\\d+\\/edit$'
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       action={resource.action} urlTable={resource.urlTable} />);
}

export default InternalGroups;
