import React, {Component} from 'react';
import { Dialog } from 'primereact/dialog';
import '../assets/css/ModalForm.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ModalForm extends Component {

    state = {
        'visible': false
    }

    submitHandler = (event) => {
        this.setState({'visible': false})
    };

    render() {
        //const button = (this.props.button && React.cloneElement(this.props.button, {...this.props.buttonProps ,onClick: (e) => this.setState({visible: true})})) || <Button {...this.props.buttonProps} onClick={(e) => this.setState({visible: true})} />
        const childrenWithProps = React.Children.map(this.props.children, child => React.cloneElement(child, {onSubmit: this.submitHandler}));
        const ModalButton = (this.props.linkBody && (
            <div onClick={(e) => this.setState({visible: true})} style={{cursor: 'pointer'}}>
                {this.props.linkBody}
            </div>)) ||
            (
                <button className="p-link" onClick={(e) => this.setState({visible: true})}
                        style={this.props.styleProps}>

                    <FontAwesomeIcon {...this.props.buttonProps}/>
                </button>
            );

        const dialog_width = this.props.width !== undefined ? this.props.width : '50vw';

        return (<React.Fragment>
                    {this.state.visible && (<div className={"p-component-overlay p-dialog-mask"} style={{'zIndex': 1005}}></div>)}
                    <Dialog header={this.props.title} style={{ width: dialog_width }} visible={this.state.visible}
                            onHide={(e) => this.setState({visible: false})}
                            baseZIndex={1024} blockScroll={true} modal={true}>
                        {this.state.visible && childrenWithProps}
                    </Dialog>

                    {ModalButton}
                </React.Fragment>);
    }

}

export default ModalForm;