import React from 'react';
import { formatMoney } from '../utils/utils.js';

const MoneyField = ({children, decimals, not_dollar_sign}) => {
	let digits = decimals === undefined ? 2 : decimals;
	let sign = not_dollar_sign === undefined ? '$' : '';

	return (
		<React.Fragment>
			{formatMoney(children, digits, ',', '.', sign)}
		</React.Fragment>
	);

}

export default MoneyField;