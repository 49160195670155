import React, {useState, useEffect, useContext} from 'react';
import InputField from "../../Forms/InputField";
import axios from "axios";

import { CompanyContext } from '../../../context/companyContext';

const BotResponseMultimediaForm = (props) => {
    const [formValues, setFormValues] = useState([{
        value: null,
        error: null,
    }]);

    const [acceptedMimeTypes, setAcceptedMimeTypes] = useState([]);
    const [files, setFiles] = useState([]);
    const [templateImgUrl, setTemplateImgUrl] = useState([]);

    const {gatewayWaba} = useContext(CompanyContext);

    useEffect(() => {
        let mimetypes = [
            'image/jpg',
            'image/png',
            'image/jpeg',
            'application/pdf',
        ]
        setAcceptedMimeTypes(mimetypes);
    }, [])

    useEffect(() => {
        if(props.formData !== null){
            let newFormValues = [];
            let newTemplateImgUrl = [];
            props.formData.map((element) => {
                newFormValues.push({value: element.url || element.value, error: null});
                newTemplateImgUrl.push(element.url || element.value);
            });
            setFormValues(newFormValues);
            setTemplateImgUrl(newTemplateImgUrl);
        }
    }, []);

    useEffect(() => {
        if (templateImgUrl !== null && files.length === 0){
            let newTemplateImgUrl = [];
            templateImgUrl.map((element) => {
                newTemplateImgUrl.push({
                    source: axios.defaults.baseURL + '/messages/media?url_media=' + element,
                    options: {
                        type: 'local'
                    }
                });
            })
            setFiles(prevState => {
                return newTemplateImgUrl;
            });
        }
    }, [props.action, templateImgUrl])

    const server_url = axios.defaults.baseURL + '/messages/media'.concat(['CLOUD-API', 'DIALOG_360'].includes(gatewayWaba) ? '?upload=1' : '');

    const onUpdatefiles = (fileItems) => {
        let newFileItems = [];
        setFiles(prevState => {
            return fileItems.map(fileItem => {
                return fileItem.file
            });
        });
        if (props.formData.length > fileItems.length) {
            fileItems.map(fileItem => {
                if (fileItem.file instanceof Blob) {
                    newFileItems.push(fileItem.file);
                }
            });
            let filterFormData = [];
            let newFormData = [...props.formData];
            newFileItems.map((fileItem) => {
                newFormData.map((formData) => {
                    if ((formData.value || formData.url).includes(fileItem.name)){
                        filterFormData.push(formData)
                    }
                })
            });
            props.setFormData(filterFormData);
        }
    }

    const template_multimedia_properties = {
        allowMultiple: true,
        acceptedFileTypes: acceptedMimeTypes,
        maxFileSize: '5MB',
        allowImagePreview: true,
        labelIdle: 'Arrastre y Suelte el archivo o seleccione <span class="filepond--label-action"> Buscar </span>',
        onupdatefiles: (fileItems => {onUpdatefiles(fileItems)}),
        files: files,
        server: {
            url: server_url,
            load:  (url, load, error, progress, abort, headers) => {
                fetch(url,{
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    })
                }).then(res => {
                    return res.blob();
                }).then(a => {
                    load(a);
                }).catch(error);
            },
            process: {
                withCredentials: true,
                timeout: 600000,
                headers: {
                    Authorization: 'Bearer '+ localStorage.getItem('token')
                },
                onerror: (response) => {
                    props.refToast.current.show({severity:'error', summary: 'Multimedia', detail: 'Error al subir', life: 3000});
                },
                onload: (response) => {
                    response = JSON.parse(response);
                    if (response['status_code'] === 200){
                        props.setFormData(response.media);
                        props.refToast.current.show({severity: 'success', summary: 'Multimedia',
                            detail: 'Archivo subido.'});
                    }
                }
            },
            revert: null
        }
    }

    return (
        <div className="grid p-fluid mt-2">
            <div className="field col mt-5">
                <InputField type={'file_upload'} small_errors={formValues.error} value={formValues.value?.url}
                            elementClass='col-12 md-12 field' {...template_multimedia_properties}
                />
            </div>
        </div>

    )

}

export default BotResponseMultimediaForm;