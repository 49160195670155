import React, {Component} from 'react'
import Fields from './Fields'
import { Button } from 'primereact/button';

class FormGeneric extends Component {

    onSubmitHandler = (event) => {
        event.preventDefault();
        this.props.onSubmit(event);
    };

    onChangeHandler = (event, inputIdentifier) => {

        let {fields} = this.props;
        fields.map(field => {
            if(field.name === inputIdentifier){
                field.value = event.value;
                if(field.elementConfig.error !== undefined){
                    field.elementConfig.error = undefined;
                }
            }
            return field;
        });

        this.props.setFields(fields);
    };

    inputClickHandler = (event, inputIdentifier) => {
        let {fields} = this.props;
        this.props.clicked(inputIdentifier, fields)
    };


    render(){
        let {fields} = this.props;
        fields.map(field => {
            if (field.elementType !== 'button'){
                field.elementConfig.onChange = (event) => { this.onChangeHandler(event, field.name) };
            } else {
                field.elementConfig.onClick = (event) => { this.inputClickHandler(event, field.name) };
            }
            return field;
        });


        let buttonSubmit = null;
        if (this.props.dataKey === undefined){
            buttonSubmit = (
                <div className="col-12 md-12" style={{textAlign:'center'}}>
                    <Button label={this.props.buttonLabel} style={{ marginBottom: 10 }} />
                </div>
            );
        }
        return (
            <div className="grid p-fluid">
                <div className="col-12 form-group">
                    <form onSubmit={this.onSubmitHandler}>
                        <Fields fields={fields}/>
                        {buttonSubmit}
                    </form>
                </div>
            </div>
        );
    }
}

export default FormGeneric
