import React, {Component, Fragment} from "react";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Link, withRouter } from "react-router-dom";
import { Menu } from "primereact/menu";
import { TableContext } from "../../../context/tableContext";
import GeneralAlert from "../../General/GeneralAlert";

class ToolbarGeneric extends Component {
	static contextType = TableContext;

	cleanFilters = () => {
		let params = {};
		params["reset"] = true;
		this.context.updateTableFields(params);
	};

	render() {
		let canCreate = this.props.action.add !== undefined;
		let new_button = null;
		if (canCreate) {
			if (this.props.customTableHeaderButtons) {
				new_button = (
					<Fragment>
						<Menu
							model={this.props.customTableHeaderButtons}
							ref={el => (this.menu = el)}
							popup={true}
						/>
						<Button
							label="Nuevo"
							icon="pi pi-plus"
							onClick={event => this.menu.toggle(event)}
						/>
					</Fragment>
				);
			} else {
				let action_show = this.props.action.show;

				if (action_show !== undefined) {
					if (action_show[action_show.length - 1] === '/'){
						action_show = action_show.slice(0, -1);
					}
				}

				let uri = this.props.match.url === '/' ? action_show : this.props.match.url;
				new_button = (
					<Fragment>
						<Link to={uri + this.props.action.add}>
							<Button
								icon="pi pi-plus"
								label="Nuevo"
							/>
						</Link>
					</Fragment>
				);
			}
		}

		let filter_button = (
			<Fragment>
				<Button
					label="Recargar"
					icon="pi pi-refresh"
					onClick={this.cleanFilters}
				/>
			</Fragment>
		);

		let export_button;

		if (this.props.action.addExportButton){
			export_button = (
				<Fragment>
					<Button
						label="Exportar"
						icon="pi pi-download"
						className="p-button-info ml-2"
						onClick={(e) => this.props.action.addExportButton.eventButton()}
					/>
				</Fragment>
			);
		}



		let add_generic_button;
		if (this.props.action.addGenericButton){
			let generic_button_icon = this.props.action.addGenericButton.icon === undefined ?
				'pi pi-plus' : this.props.action.addGenericButton.icon;
			add_generic_button = (
				<Button
					label={this.props.action.addGenericButton.label}
					icon={generic_button_icon}
					disabled={this.props.action.addGenericButton.button}
					className={"ml-2"}
					onClick={(e) => this.props.action.addGenericButton.eventButton()}
				/>
			);
			if (this.props.action.addGenericButton.alert){
				add_generic_button = (
					<GeneralAlert
						{...this.props.action.addGenericButton.alert}
						onConfirm={this.props.action.addGenericButton.eventButton}
					>
						{(showAlert) => {
							return (
								<Button
									label={this.props.action.addGenericButton.label}
									icon={generic_button_icon}
									disabled={this.props.action.addGenericButton.button}
									className={"ml-2"}
									onClick={(e) => showAlert()}
								/>
							);
						}}
					</GeneralAlert>
				)
			}
		}

		const leftContents = (
			<Fragment>
				{filter_button}
				{export_button}
			</Fragment>
		);

		const rightContents = (
			<Fragment>
				{new_button}
				{add_generic_button}
			</Fragment>
		);

		return (
			<Toolbar left={leftContents} right={rightContents} style={{background: 'none'}} />
		);
	}
}

export default withRouter(ToolbarGeneric);
