export const removeIdsRecursively = (obj, parentKeys = []) => {
    if (obj && typeof obj === 'object') {
        if (Array.isArray(obj)) {
            return obj.map(item => removeIdsRecursively(item, parentKeys));
        } else {
            const newObj = {};
            for (let key in obj) {
                // Update parent keys only for the current level
                const currentParentKeys = [...parentKeys];
                if (['success_message_multimedia_config', 'question_multimedia_config'].includes(key)) {
                    currentParentKeys.push(key);
                }
                newObj[key] = removeIdsRecursively(obj[key], currentParentKeys);
            }
            // Only delete 'id' if not inside specific configs
            if ('id' in newObj && !parentKeys.includes('success_message_multimedia_config') && !parentKeys.includes('question_multimedia_config')) {
                delete newObj.id;
            }
            if ('parent_id' in newObj) {
                delete newObj.parent_id;
            }
            if ('questionnaire_id' in newObj) {
                delete newObj.questionnaire_id;
            }
            return newObj;
        }
    } else {
        return obj;
    }
};