import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import CustomSpinner from "../../../UI/CustomSpinner";
import ApiLoan from "../../../service/ApiLoanService";

import { Toast } from "primereact/toast";

const QuestionnaireProviderAiAssistants = (props) => {
    const refToast = useRef(null);
    const [loadingAiAssistants, setLoadingAiAssistants] = useState(false);
    const [aiAssistants, setAiAssistants] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [selectedAssistant, setSelectedAssistant] = useState(null);

    useEffect(() => {
        setLoadingAiAssistants(true);
        let apiService = new ApiLoan();
        apiService.getResources({
            url: '/ai_assistants'
        }).then(response => {
            const { data } = response;
            const availableAssistants = data.objects.filter(assistant => assistant.available);
            setAiAssistants(availableAssistants);
        }).catch(error => {
            refToast.current.show({severity: 'error', summary: 'Error al recuperar asistentes ai', detail: 'Por favor intente nuevamente en otro momento.'});
        }).finally(() => {
            setLoadingAiAssistants(false);
        });
    }, [props.history]);


    useEffect(() => {
        const formDataAiAssistantId = props.formData;
        if (aiAssistants.length > 0 && props.formData) {
            const matchingAssistant = aiAssistants.find(assistant => assistant.id === formDataAiAssistantId);
            if (matchingAssistant) {
                setSelectedAssistant(matchingAssistant);
            }
        }
    }, [aiAssistants, props.formData]);

    useEffect(() => {
        if (selectedAssistant !== null) {
            setSubmitDisabled(false);
        } else {
            if (submitDisabled === false) {
                setSubmitDisabled(true)
            }
        }
    }, [selectedAssistant, submitDisabled]);

    const handleDelete = () => {
        props.setFormData(null);
        props.hideProviderAiAssistantHandler();
    }

    const onAssistantSelect = (e) => {
        setSelectedAssistant(e.value);
        if (props.onAssistantSelected) {
            props.onAssistantSelected(e.value.id);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = selectedAssistant.id

        props.setFormData(formData);
        props.hideProviderAiAssistantHandler();
    }


    const labelSubmit = props.formData !== null ? 'Seleccionar' : 'Seleccionar';
    let form = (
        <div className="p-fluid mt-2 mb-2">
            <div>
                {loadingAiAssistants ? <CustomSpinner status={'Cargando asistentes...'} /> : null}
                <Toast ref={refToast} />
                <span className="p-float-label mt-4 mb-4">
                    <Dropdown id="dropdown" optionLabel={"name"} value={selectedAssistant} options={aiAssistants}
                        onChange={(e) => onAssistantSelect(e)} />
                    <label htmlFor="dropdown">Asistentes</label>
                </span>
            </div>
            {selectedAssistant && selectedAssistant.description && (
                <div className='mb-5'>
                    <p className='font-bold'>Descripción</p>
                    <p>{selectedAssistant.description}</p>
                </div>
            )}
            <div className="formgrid grid">
                <div className="field col">
                    <Button label={labelSubmit}
                        disabled={submitDisabled}
                        onClick={(e) => handleSubmit(e)}
                        icon={props.formData !== null ? 'pi pi-pencil' : 'pi pi-plus'} />
                </div>
                {props.formData !== null && (
                    <div className="field col">
                        <Button className="p-button-raised btn-danger"
                            label={'Eliminar'}
                            onClick={handleDelete}
                            icon={'pi pi-times'} />
                    </div>
                )}
            </div>
        </div>
    )
    return form;
}

export default QuestionnaireProviderAiAssistants;