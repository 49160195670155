import React, {useRef, useState} from 'react';
import { Toast } from 'primereact/toast';
import ApiLoan from "../../service/ApiLoanService";
import {Button} from "primereact/button";
import { Calendar } from 'primereact/calendar';
import './ReportNotification.css';
import { addLocale } from 'primereact/api';

const ReportNotification = (props) => {
    const [rangeDate, setRangeDate] = useState(null)
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const toast = useRef(null);

    const showInfoMessage = (severity, summary, message) => {
        toast.current.show({severity: severity, summary: summary, detail: message});
    };

    const yearRange = '2019:'.concat(new Date().getFullYear().toString());

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthNamesShort: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    });
    const maxDate = new Date();

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = {};

        formData['date_start'] = rangeDate[0];
        formData['date_end'] = rangeDate[1];

        setSubmitDisabled(true);

        const apiLoanService = new ApiLoan();
        apiLoanService.postResource({
            url: '/report/notifications',
            data: formData
        }).then(response => {
            showInfoMessage('success', 'Reporte Notificacion', response.data.message)
        }).catch(error => {
            showInfoMessage('error', 'Reporte Notificacion', error.response.data.message)
        }).finally(f => {
            setSubmitDisabled(false);
        })
    }
    const updateCalendar = (value) => {
        setRangeDate(value)
        if (value[0] !== null && value[1] !== null){
            setSubmitDisabled(false)
        } else if (!submitDisabled) {
            setSubmitDisabled(true)
        }
    }

    return (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h5>Reportes de Notificaciones</h5>
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col">
                            <span className="p-float-label">
                                <Calendar value={rangeDate}
                                          dateFormat="dd/mm/yy"
                                          maxDate={maxDate}
                                          onChange={(e) => updateCalendar(e.value)}
                                          selectionMode="range" readonlyInput={true}
                                          showIcon={true}
                                          locale="es" monthNavigator={true}
                                          yearNavigator={true} yearRange={yearRange}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="field p-fluid">
                    <Button label={'Consultar'} className={"mt-4"} onClick={(e) => handleSubmit(e)}
                            disabled={submitDisabled}/>
                </div>
            </div>
        </div>
    )

}

export default ReportNotification;
