import React, {Component} from 'react';
import ApiLoan from '../service/ApiLoanService';
import {Checkbox} from 'primereact/checkbox';
import CustomSpinner from "../UI/CustomSpinner";


class CheckboxForm extends Component {

    state = { checked: this.props.checked,
              loading: false };

    apiService = new ApiLoan();
    statusHandler = this.statusHandler.bind(this);

    statusHandler(event){
        const { url, resource_id } = this.props;
        this.setState({ loading: true });
        this.apiService
            .patchResource({
                url: url,
                resource_id: resource_id,
                data:{
                    notification_subscription: event.checked
                }
            }).then(response => {
                if(response.status === 200){
                    this.setState({
                        checked: event.checked,
                        loading: false
                    });
                }
            }).catch(error => {
                this.setState({loading: false});
            });

    }

    render(){
        const check_box_field = (<Checkbox checked={this.state.checked} onChange={this.statusHandler}></Checkbox>);
        const progress_spinner = (<CustomSpinner style={{width: '18px', height: '18px'}} strokeWidth="8"/>);
        return (!this.state.loading && check_box_field) || progress_spinner;

    }
}

export default CheckboxForm;
