import React, {useEffect, useState, Fragment} from "react";
import { InputNumber } from 'primereact/inputnumber';


const WhatsappMaxNotification = (props) => {
    const [loading, setLoading] = useState(true);
    const [getGateway, setGateway] = useState('');

    useEffect(() => {
        if (props.gateway !== getGateway){
            if (props.value === 0){
                cleanValueHandler(0)
            }
            setGateway(props.gateway)
            setLoading(false)
        }
    }, [props.gateway, props.value]);

    useEffect(() => {
        if (props.gateway !== getGateway && !loading){
            setLoading(false);
        }
    }, [props.gateway]);

    const inputChangeHandler = (event) => {
        props.onChange(event.target.value);
    };

    const cleanValueHandler = (value) => {
        props.onChange(value);
    };

    const getGatewayForm = gateway => {
        let form = null;
        let value = props.value;
        let elementClass = "";

        switch (gateway) {
            case 'CLOUD-API':
                form = (
                    <Fragment>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputNumber
                                            value={value}
                                            onValueChange={(e) => inputChangeHandler(e)}
                                            min={0}
                                            className={props.error && 'p-error'}/>
                                        <label htmlFor="input">Máximo de notificaciones via Whatsapp</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            case 'DIALOG_360':
                form = (
                    <Fragment>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputNumber
                                            value={value}
                                            onValueChange={(e) => inputChangeHandler(e)}
                                            min={0}
                                            className={props.error && 'p-error'}/>
                                        <label htmlFor="input">Máximo de notificaciones via Whatsapp</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            default:
                break;

        }
        return form;
    };

    if (!loading) {
        if (props.gateway === getGateway){
            return getGatewayForm(getGateway);
        } else {
            return null
        }
    } else {
        return null;
    }
};

export default WhatsappMaxNotification;