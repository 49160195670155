import React, {useMemo} from 'react';

import { DateTime } from 'luxon';
import './DateRange.css';

export default function DateRange(props) {
    const Formdate = (date, timezone, id) => {
        const inputDate = useMemo(() => {
            return DateTime.fromISO(date, { zone: 'America/Argentina/Buenos_Aires'}).setZone(timezone);
        }, [id]);

        const today = DateTime.now().setZone(timezone);
        const yesterday = today.minus({ days: 1 });
        const dayBeforeYesterday = today.minus({ days: 2 });

        if (inputDate.hasSame(today, 'day')) {
            return 'Hoy';
        } else if (inputDate.hasSame(yesterday, 'day')) {
            return 'Ayer';
        } else if (inputDate.hasSame(dayBeforeYesterday, 'day')) {
            return 'AnteAyer';
        } else {
            return inputDate.setLocale('es').toFormat('LLLL');
        }
    }

    return (
        <div className='center mt-2 date'>
            <div className="date-container">
                <div className="date">
                    <span>{Formdate(props.date, props.timezone, props.id)}</span>
                </div>
            </div>
        </div>
    );
}