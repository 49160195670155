import React from 'react';
import Resources from '../Resources/Resources';

const channels = () => {
    const resource = {
        title: 'Canales',
        url: '/channels',
        colsHeader: [
            { field: 'name', header: 'Nombre'},
            { field: 'description', header: 'Descripción'},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/channel/',
            edit: '/edit'
        },
        urlTable: [
            '/channels',
            '/channels/new',
            '^\\/channel\\/\\d+\\/edit$'
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader} action={resource.action} urlTable={resource.urlTable} />);
}

export default channels;