import React, {useEffect, useState} from 'react';

import ApiLoan from "../../service/ApiLoanService";
import CustomSpinner from "../../UI/CustomSpinner";
import "../../assets/css/WhatsappAnalytics.css";
import { useHistory } from "react-router-dom";

const WhatsAppAnalytics = () => {
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState(null);
    const [name, setName] = useState('');
    const history = useHistory();

    useEffect(() => {
        const apiService = new ApiLoan();
        const provider_id = localStorage.getItem('provider_id');

        apiService.getResources({
            url: '/providers/'+provider_id+'/analytics',
        }).then(response => {
            const data = response.data;
            let url = data[0].url + '&theme=' + checkModeDark();
            setUrl(url);
            setName(data[0].name);
            setLoading(false);
        }).catch(error => {
            history.push( '/' );
        });
    },[history]);

    const checkModeDark = () => {
        if (localStorage.getItem('dark')) {
            if (localStorage.getItem('dark') === 'true') {
                return 'night';
            } else {
                return 'light';
            }
        } else {
            return 'light';
        }
    }

    const getIframe = () => {
        return (
            <iframe
                title={name}
                className="analytics-dashboard"
                src={url}
                frameBorder="0"
                width="100%"
                height="100%"
                style={{backgroundColor: "#ffffff"}}
                allowTransparency>
            </iframe>
        );
    }

    return loading ? <CustomSpinner/> : getIframe();

}

export default WhatsAppAnalytics;