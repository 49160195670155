import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Button } from 'primereact/button';
import ApiLoan from "../../service/ApiLoanService";
import './ChatBotPushForm.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { set } from "lodash";
import { MultiSelect } from "primereact/multiselect";
import CustomSpinner from "../../UI/CustomSpinner";
import DialogGeneric from '../../UI/DialogGeneric';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Chip } from 'primereact/chip';

const ChatBotPushForm = (props) => {
    const [methodPush, setMethodPush] = useState({ show_users: true, chat_bot_push_custom_tags: [], chat_bot_push_users: [], chat_bot_push_groups: [], date: null, multiple_custom_tags: false });
    const [methodPushErrors, setMethodPushErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [loading, setLoading] = useState(true);
    const [showTemplateWhatsappDialog, setShowTemplateWhatsappDialog] = useState(false);
    const [users, setUsers] = useState([]);
    const [dateOptions, setDateOptions] = useState([]);
    const [groups, setGroups] = useState({
        options: [],
        page: 1,
        num_results: 0,
        loading: true
    });
    const [templateWhatsappDialog, setTemplateWhatsappDialog] = useState({
        value: '',
        template_uuid: '',
        title: ''
    });
    const [customTags, setCustomTags] = useState({
        loading: true,
        values: []
    })

    useEffect(() => {
        setMethodPushErrors({});
    }, [methodPush, templateWhatsappDialog])

    useEffect(() => {
        let date_options = [];

        let date_labels = {
            '1': '1 día antes',
            '2': '2 días antes',
            '3': '3 días antes',
            '4': '4 días antes',
            '5': '5 días antes',
        }

        for (let key in date_labels) {
            date_options.push({
                'value': key,
                'label': date_labels[key]
            });
        }

        setDateOptions(date_options);
        setDisableSubmit(false);
        setLoading(false);
    }, []);

    useEffect(() => {
        let mounted = true;
        const apiService = new ApiLoan();
        if (mounted) {
            apiService.getResources({
                url: '/conversations/users',
            }).then(response => {
                const objects = response.data.objects;
                const users = objects.map(object => {
                    return {
                        'user_id': object.id,
                        'username': object.username,
                    }
                });
                setUsers(users);
            });
        }
        return () => mounted = false;
    }, []);

    useEffect(() => {
        let mounted = true;
        const apiService = new ApiLoan();
        if (mounted) {
            apiService.getResources({
                url: '/groups',
                page: groups.page
            }).then(response => {
                const objects = response.data.objects;
                const group_objects = objects.map(object => {
                    return {
                        'group_id': object.id,
                        'name': object.name,
                    }
                });
                setGroups((prevState) => {
                    return {
                        ...prevState,
                        options: [
                            ...prevState.options,
                            ...group_objects
                        ],
                        page: response.data.page,
                        num_results: response.data.num_results,
                        loading: response.data.page < response.data.total_pages
                    }
                });
            });
        }
        return () => mounted = false;
    }, [groups.page]);

    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResources({
            url: '/all_custom_tags',
        }).then(response => {
            const objects = response.data.objects;
            const tags = objects.map(object => {
                return {
                    'custom_tag_id': object.id,
                    'name': object.name
                }
            });

            tags.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            setCustomTags(prevState => {
                return {
                    ...prevState,
                    loading: false,
                    values: tags
                }
            });
        });
        return () => {
            setCustomTags({
                loading: true,
                values: []
            });
        }
    }, []);

    const selectTemplateWhatsappHandler = (e) => {
        setShowTemplateWhatsappDialog(true)
    }

    const onRemoveTemplateHandler = () => {
        setTemplateWhatsappDialog({
            value: '',
            template_uuid: '',
            title: ''
        })
    }

    const templateWhatsappGateway = (['DIALOG_360', 'CLOUD-API'].includes(localStorage.getItem('gatewayWaba')))

    const templateWhatsappDialogValues = {
        'url': '',
        'method': 'get',
        'header': 'Plantillas Guardadas',
        'show_footer': false,
        'submit_label': 'Crear',
        'blockScroll': true,
        'maximizable': true,
        'sumaryToast': 'Etiquetas',
        'fields': [
            {
                name: 'notification_whatsapp',
                elementType: 'notificationWhatsappBody',
                elementConfig: {
                    onClose: setShowTemplateWhatsappDialog,
                    updateTemplateValue: setTemplateWhatsappDialog,
                    filter: [{ name: "whatsapp_template", op: "eq", val: templateWhatsappGateway }]
                },
                value: templateWhatsappDialog,
                elementClass: 'col-12'
            }],
        'buttons_rounded': true
    }

    const dialogTemplateWhatsapp = showTemplateWhatsappDialog ? (
        <DialogGeneric visible={showTemplateWhatsappDialog} show_footer={false}
            setVisible={setShowTemplateWhatsappDialog}
            closeModal={true} {...templateWhatsappDialogValues} />
    ) : null

    const selectItemUser = (value) => {
        if (value) {
            return (
                <b>{value.username}</b>
            );
        } else {
            return <span className="my-multiselected-empty-token">Usuarios</span>;
        }
    };

    const selectItemGroup = (value) => {
        if (value) {
            return (
                <b>{value.name}</b>
            );
        } else {
            return <span className="my-multiselected-empty-token">Equipos</span>;
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const apiService = new ApiLoan();
        let newMethodPush = { ...methodPush };
        newMethodPush.template_uuid = templateWhatsappDialog ? templateWhatsappDialog.template_uuid : '';
        apiService.postResource({
            'url': '/chat_bot_pushs',
            'data': newMethodPush
        }).then(response => {
            props.refToast.current.show({ severity: 'success', summary: 'Impulsar bot', detail: 'Se guardo satisfactoriamente' });
            props.hideModalChatBotPush();
        }).catch(error => {
            let detail = 'Verifique el formulario'
            let status = 'error'
            setMethodPushErrors(error.response.data);
            if (error?.response?.data?.message) {
                detail = error.response.data.message;
                status = 'warn';
            }
            props.refToast.current.show({ severity: status, summary: 'Impulsar bot', detail: detail });
        });
    }

    const updateFieldChatBotPush = (item, value) => {
        setMethodPush((prevState) => {
            return {
                ...prevState,
                [item]: value
            }
        });
    }

    const handleToggleChange = (item, value) => {
        setMethodPush((prevState) => {
            return {
                ...prevState,
                chat_bot_push_users: [],
                chat_bot_push_groups: [],
                [item]: value
            }
        });
    }

    const getFieldError = (errors, field) => {
        let form = null;
        if (errors[field] !== null) {
            form = <p style={{ color: '#f44336' }} className="p-error"> <small>{errors[field]}</small> </p>
        }
        return form;
    }

    const multiselectCustomTagsTemplate = value => {
        if (value) {
            return (
                <div className="my-multiselected-item-token">
                    <span>{value.name}</span>
                </div>
            );
        } else {
            return <span className="my-multiselected-empty-token">Etiquetas</span>;
        }
    };

    const getForm = () => {
        return (
            <Fragment>
                <div className="formgrid grid">
                    <div className="field col">
                        <span className="p-float-label">
                            <div className={'footer-scheduled-message'}>
                                <p><b>Defina la plantilla a enviar</b></p>
                                {templateWhatsappDialog.title !== '' ? <Chip onRemove={onRemoveTemplateHandler} label={templateWhatsappDialog.title} removable></Chip> : <button className="p-link" onClick={(e) => selectTemplateWhatsappHandler(e)}> {'Seleccione Plantilla de Whatsapp'} </button>}
                                {getFieldError(methodPushErrors, 'template')}
                            </div>
                        </span>
                    </div>
                </div>
                <p><b>Defina etiquestas para asignar</b></p>
                <div className="formgrid grid">
                    <div className="field col-10">
                        <span className="p-float-label">
                            <MultiSelect id="chat_bot_push_custom_tags" className=""
                                value={methodPush.chat_bot_push_custom_tags} optionLabel="name" options={customTags.values}
                                onChange={(e) => updateFieldChatBotPush('chat_bot_push_custom_tags', e.value)}
                                placeholder="Etiquetar"
                                selectedItemTemplate={multiselectCustomTagsTemplate}
                                selectedItemsLabel={'{0} etiquetas'}
                                maxSelectedLabels={0}
                            />
                            <label htmlFor="chat_bot_push_custom_tags">Etiquetar</label>
                        </span>
                        {getFieldError(methodPushErrors, 'chat_bot_push_custom_tags')}
                    </div>
                    <div className={"field col-2"}>
                        <label htmlFor="switch">Acumular etiqueta</label>
                        <span className="p-float-label">
                            <InputSwitch checked={methodPush.multiple_custom_tags} onChange={(e) => updateFieldChatBotPush('multiple_custom_tags', e.value)} />
                        </span>
                    </div>
                </div>

                <p><b>Defina usuarios o grupos para asignar</b></p>
                <div className="formgrid grid">
                    <div className="field col-12">
                        <span className="p-inputgroup">
                            <button className="p-link p-button p-component p-button-icon-only btn-actions-default"
                                onClick={(e) => handleToggleChange('show_users', methodPush.show_users ? false : true)}>
                                <FontAwesomeIcon icon={methodPush.show_users ? 'user' : 'users'} />
                            </button>
                            <span className="p-float-label">
                                <MultiSelect
                                    id="user-group"
                                    className=""
                                    value={methodPush.show_users ? methodPush.chat_bot_push_users : methodPush.chat_bot_push_groups}
                                    name={methodPush.show_users ? 'chat_bot_push_users' : 'chat_bot_push_groups'}
                                    optionLabel={methodPush.show_users ? "username" : 'name'}
                                    options={methodPush.show_users ? users : groups?.options} filter
                                    onChange={(e) => updateFieldChatBotPush(methodPush.show_users ? 'chat_bot_push_users' : 'chat_bot_push_groups', e.value)}
                                    placeholder={methodPush.show_users ? "Usuarios" : 'Equipos'}
                                    selectedItemTemplate={methodPush.show_users ? selectItemUser : selectItemGroup}
                                    resetFilterOnHide={true}
                                    maxSelectedLabels={0}
                                    selectedItemsLabel={methodPush.show_users ? '{0} usuario' : '{0} grupo'}
                                />
                                <label htmlFor="user-group">{methodPush.show_users ? "Usuarios" : 'Equipos'}</label>
                            </span>
                        </span>
                        {getFieldError(methodPushErrors, methodPush.show_users ? 'chat_bot_push_users' : 'chat_bot_push_groups')}
                    </div>
                </div>

                <p><b>Defina periodo de bot sin continuar</b></p>
                <div className="formgrid grid">
                    <div className="field col">
                        <span className="p-float-label">
                            <span className="p-float-label">
                                <Dropdown id="repeat" value={methodPush?.date?.toString()}
                                    options={dateOptions} label={'label'}
                                    onChange={(e) => updateFieldChatBotPush('date', e.value)} />
                                <label htmlFor="repeat">Periodo</label>
                            </span>
                        </span>
                        {getFieldError(methodPushErrors, 'date')}
                    </div>
                </div>
            </Fragment>
        )
    }

    let form = !loading ? (
        <div className="grid p-fluid mt-3 mb-3">
            <div className="field col mt-3">
                <div className="">
                    {getForm()}
                </div>
                <div className="formgrid grid">
                    <div className="field col-12">
                        <Button label={'Guardar'} icon={'pi pi-plus'} className="ml-auto"
                            onClick={(e) => handleSubmit(e)}
                            disabled={disableSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : <Fragment>
        <CustomSpinner status={'Cargando Impulsar Bot...'} />
    </Fragment>
    return (
        <div>
            {dialogTemplateWhatsapp}
            {form}
        </div>
    );

}

export default ChatBotPushForm;
