import React, {Fragment, useState, useRef, useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import StripedList from '../../UI/StripedList';
import Tag from "../../UI/Tag";
import '../../assets/css/Templates.css';
import '../../assets/css/CustomModalTemplateView.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertMimetypeType} from "../../shared/utility";
import ApiLoan from '../../service/ApiLoanService';
import axios from 'axios';
import ResultNotFount from '../../UI/ResultNotFount';

const TemplatesView = ({text, onTemplateSelection, setShowTemplate, setText}) => {
    const scrollParentRef = useRef(null);
    const [ templatesState, setTemplateState ] = useState({
        currentTemplate: null,
        templates: [],
        page: 1,
        num_results: 0,
        action: 'list',
        loading: false,
        searchQuery: null,
        filteredTemplates:[],
        filteredPage: 1,
        scrollRef: null,
        whatsappTemplateValue: false
    });
    const [templates, setTemplates] = useState([]);
    const [hasMoreTemplate, setHasMoreTemplate] = useState(true);
    const scrollRef = useRef();
    const [loading, setLoading] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isMobile = () => {
        return window.innerWidth <= 750;
    };
    
    const resetTemplate = (whatsappTemplateValue=false) => {
        setTemplateState((prevState) => {
            return {
                ...prevState,
                currentTemplate: null,
                templates: [],
                page: 1,
                num_results: 0,
                action: 'list',
                loading: false,
                searchQuery: null,
                filteredTemplates:[],
                filteredPage: 1,
                scrollRef: null,
                whatsappTemplateValue: whatsappTemplateValue,
                total_pages: 0,
            }
        });
        setTemplates([]);
    }
    const searchTemplate = (searchQuery=null) => {
        setTemplateState((prevState) => {
            return {
                ...prevState,
                searchQuery: searchQuery,
            }
        });
    }

    useEffect(() => {
        const updateWindowDimensions = () => {
          const newWidth = window.innerWidth;
          const newHeight = window.innerHeight;
          setWidth(newWidth);
          setHeight(newHeight);
        };
    
        window.addEventListener("resize", updateWindowDimensions);
    
        return () => window.removeEventListener("resize", updateWindowDimensions);
    
    }, []);

    useEffect(() => {
        if(text.startsWith('/')){
            if(text.startsWith('//')){
                if (!templatesState.searchQuery) resetTemplate(true);
                if (text.replace(/^\/\//, '') !== "") {
                    searchTemplate(text.replace(/^\/\//, ''));
                }else{
                    searchTemplate(null);
                };
            }else{
                if (!templatesState.searchQuery) resetTemplate(false);
                if (text.replace(/^\//, '') !== ""){
                    searchTemplate(text.replace(/^\//, ''));
                }else{
                    searchTemplate(null);
                };
            }
        }
        setTimeout(() => {
            setTemplateState((prevState) => {
                return {
                    ...prevState,
                    templates: prevState.templates.filter((element) => element.whatsapp_template === prevState.whatsappTemplateValue)
                }
            });
        }, 250);
    }, [text]);

    useEffect(() => {
        if (!templatesState.searchQuery){
            getTemplates();
        }
    }, [templatesState.searchQuery, templatesState.page, templatesState.whatsappTemplateValue]);

    useEffect(() => {
        setTemplates(templatesState.filteredTemplates.length > 0 ? templatesState.filteredTemplates : templatesState.templates)
    }, [templatesState.filteredTemplates, templatesState.templates]);

    
    useEffect(() => {
        const CancelTokenFilterRequest = axios.CancelToken;
        const filterRequest = CancelTokenFilterRequest.source();

        if (templatesState.searchQuery) {
          if (templatesState.searchQuery.length < 3) {
            getFilteredTemplates(1, templatesState.searchQuery, filterRequest);
          } else {
            getFilteredTemplates(1, templatesState.searchQuery, filterRequest);
          }
        }
        else {
          setTemplateState((prevState) => {
            return {
                ...prevState,
                filteredTemplates: [],
                filteredPage: 1
            }
          });
        } 
    
        return () => {
            filterRequest.cancel()
        }   
    }, [templatesState.searchQuery]);

    const getTemplates = () => {
        setLoading(true);
        const apiService = new ApiLoan();
        apiService.getResources({
            url: '/templates',
            page: templatesState.page,
            filters: [{
                name:'whatsapp_template',
                op: 'eq',
                val: templatesState.whatsappTemplateValue
            }],
            order_by: [{
                  "field": "id",
                  "direction": "desc"
            }]
        }).then(response => {
            setTemplateState((prevState) => {
                return {
                    ...prevState,
                    templates: [
                        ...prevState.templates,
                        ...response.data.objects
                    ],
                    num_results: response.data.num_results,
                    total_pages: response.data.total_pages,
                }
            });
            templatesState.page === response.data.total_pages ? setHasMoreTemplate(false) : setHasMoreTemplate(true);
        }).catch(() => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    };

    const getFilteredTemplates = (page, searchQuery, conversationFilterRequest=null) => {
        setLoading(true);
        const apiService = new ApiLoan();
        let params = {
            page: page,
            filters: [{
                name:'title',
                op: 'like',
                val: `%${searchQuery}%`
            },
            {
                name:'whatsapp_template',
                op: 'eq',
                val: templatesState.whatsappTemplateValue
            }],
            cancelToken: conversationFilterRequest ? conversationFilterRequest.token : null
        }
 
        apiService.getResources({
            url: '/templates',
            ...params
        }).then(response => {
            setTemplateState((prevState) => {
                const filteredTemplates = page <= 1 ? [...response.data.objects] : [...prevState.filteredTemplates, ...response.data.objects];
                return {
                    ...prevState,
                    filteredTemplates: filteredTemplates,
                    filteredPage: page
                }
            });
        }).catch(() => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    };

    const getMoreTemplate = async () => {
        if(templatesState.searchQuery){
            setTemplateState((prevState) => {
                return {
                    ...prevState,
                    searchQuery: templatesState.searchQuery,
                    filteredPage: prevState.page + 1,
                }
            });
        } else {
            if (templatesState.templates.length !== templatesState.num_results){
                setTemplateState((prevState) => {
                    return {
                        ...prevState,
                        page: prevState.total_pages <= prevState.page ? prevState.page : prevState.page + 1
                    }
                });
            }
        }
    }

    const selectTemplate = (content, whatsapp_template, template_uuid, template_header_config) => {
        setText('');
        setShowTemplate(false);
        onTemplateSelection({content, whatsapp_template, template_uuid, template_header_config });
    }

    const onCloseTemplate = (event) =>{
        event.preventDefault();
        setShowTemplate(false);
        setText('');
    }

    const templateList = templates.map((template) => {

        const templateStatuses = {
            'APPROVED': 'Aprobado',
            'DELETED': 'Borrado',
            'DISABLED': 'Desactivado',
            'IN_APPEAL': 'En apelación',
            'PENDING': 'Pendiente',
            'PENDING_DELETION': 'Pendiente de eliminación',
            'REJECTED': 'Rechazado'
        }

        const showTemplateTag = template.whatsapp_template ? (
            <Tag>{templateStatuses[template.status]}</Tag>
        ) : null;

        const canSelectTemplate = (template.status === 'APPROVED' || !template.whatsapp_template);

        const templateValueSelection = (canSelectTemplate) ? (
            <a href='' onClick={(event) => {
                event.preventDefault();
                selectTemplate(template.body, template.whatsapp_template, template.template_uuid, template.template_header_config);
            }}>
                <h5><strong>{template.title} {showTemplateTag}</strong></h5>
            </a>

        ) : <h5><strong>{template.title} {showTemplateTag} </strong></h5>;

        const getIcon = (mimetype) => {
            let type_icon = convertMimetypeType(mimetype)
            if (type_icon === 'document'){
                type_icon = 'file-pdf'
            }
            return type_icon
        }

        const template_icon = template.template_header_config ? (
            getIcon(template.template_header_config.mimetype)
        ) : null

        const templateImg = template.template_header_config ? (
            <Fragment>
                <button data-tip data-for="imageButton"
                        disabled={template_icon !== 'image'}
                        className={'ml-2 template-value-img p-button-rounded btn-actions-default'}>
                    <FontAwesomeIcon icon={template_icon} style={{background: '#8ea38f', marginRight:'.25em', width: '1.5em', height: '1.5em'}} color={'white'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                </button>
            </Fragment>
        ) : null;

        return (<Fragment>
            <div className={"template-value"}>
                {templateValueSelection}
                <span>{template.body}</span>
            </div>
            <div className={'right-item-list inline-flex'}>
                {templateImg}
            </div>
        </Fragment>);
    })

    const body = (
        <div className='scrollable' style={{maxWidth: "100%", height: height <= 800 ? '25.3vh' : '30.4vh', paddingLeft: '20px', paddingRight: '20px'}} ref={scrollRef}>
            <InfiniteScroll
                pageStart={templatesState.page}
                loadMore={() => getMoreTemplate()}
                isReverse={false}
                threshold={1}
                initialLoad={false}
                useWindow={false}
                hasMore={hasMoreTemplate}
                getScrollParent={() => scrollParentRef.current}
            >
                <StripedList items={templateList}/>
            </InfiniteScroll>
            {(templates.length === 0 && !loading)  && (
                <ResultNotFount/>
            )}
        </div>
    );

    const header = (
        <Fragment>
            <div className="custom-modal-header-title-view">
                {templatesState.whatsappTemplateValue ? 'Plantillas de Inicio de Conversación' : 'Plantillas de Uso Rápido'}
            </div>
            <span className="custom-modal-close" onClick={onCloseTemplate}>&times;</span>
        </Fragment>
    );

    return (
        <Fragment>
            <div className="custom-modal-header-view">
                {header}
            </div>
            <div className='card' style={{maxWidth: isMobile() ? width : width <= 1024 ? width - 435 : width - 550}}>
                {body}
            </div>
        </Fragment>
    );

}

export default TemplatesView;


