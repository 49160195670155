import {FormattedMessage} from '../../../types/InternalMessenger/MessagesListTypes'

export const formatInternalMessage = (message): FormattedMessage => {
    return {
      id: message.id,
      user_id: message.user_id,
      author: message.author,
      author_name: message.author_name,
      parent: message.parent ? message.parent : null,
      message: message.message,
      created: message.created,
      username: message.username,
      failed: message.failed,
      mimetype: message.mimetype,
      message_type: message.message_type,
      message_type_id: message.message_type_id,
      reaction: message.reaction,
      recipient_internal_client_id: message.recipient_internal_client_id,
      recipient_name: message.recipient_name,
      seen: message.seen,
      updated: message.updated,
      potential: message.potential,
      captions: message.caption,
      internal_conversation_id: message.internal_conversation_id,
      message_state: message.message_state,
      group_id: message.group_id
    };
  };