export function showToastMessage(sender,messageSeverity, messageSummary, messageDetail) {
    sender.props.showToast({life: 2000, severity: messageSeverity, summary: messageSummary, detail: messageDetail });
}

export function showSuccessMessage(sender, messageSummary, messageDetail) {
    showToastMessage(sender,'success',messageSummary, messageDetail)
}

export function showInfoMessage(sender, messageSummary, messageDetail) {
    showToastMessage(sender,'info',messageSummary, messageDetail)
}

export function showWarningMessage(sender, messageSummary, messageDetail) {
    showToastMessage(sender,'warn',messageSummary, messageDetail)
}

export function showErrorMessage(sender, messageSummary, messageDetail) {
    showToastMessage(sender,'error',messageSummary, messageDetail)
}