import React, {Component} from 'react';
import Attribute from './Attribute';
import ApiLoan from '../../service/ApiLoanService';
import CustomSpinner from "../../UI/CustomSpinner";

class AssociatedAttributes extends Component {
    _isMounted = false;

    state = {
    	loading: true,
    	attributes: {}
    }
    apiService = new ApiLoan();


    componentDidMount(){
        this._isMounted = true;

        this.apiService.getResource({
            url: this.props.url,
        }).then(response => {
            if (this._isMounted) {
                const allowed_fields = this.props.fields.map(field => field.name);
                let attributes = Object.keys(response.data)
                    .filter(key => allowed_fields
                        .includes(key))
                    .reduce((obj,key)=>{
                        obj[key] = response.data[key]
                        return obj
                    },{});

                this.setState({
                    attributes: attributes,
                    loading: false
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render(){
        const progress_spinner = (<CustomSpinner style={{width: '18px', height: '18px'}} strokeWidth="8"/>);
        const list = this.props.fields.map(field => {
                        return (<Attribute name={field.name} key={field.name} traduction={field.traduction}>{this.state.attributes[field.name]}</Attribute>);
                    });

        return (<React.Fragment key={this.props.name} >
        			{(this.loading && progress_spinner) || list }
        		</React.Fragment>);
    }

}

export default AssociatedAttributes;