import React from 'react';

export const CompanyContext = React.createContext({
    privateInbox: false,
    privateGroup: false,
    socket: null,
    providerTimezone: null,
    chatbotEnabled: false,
    providerUrlVersion: null,
    notificationAvailable: false,
    soundAvailable: false,
    webhookEnabled: false,
    gatewayWaba: null,
    isTopBarVisible: true,
    aiAssistantEnabled: false,
    hasExportClients: false,
    internalChatEnabled: false,
    mrTurnoIntegration: false,
    aiAssistantIntegration: false,
    chatChannel: null,
    setPrivateInbox: () => {},
    setPrivateGroup: () => {},
    setIsTopBarVisible: () => {},
    setChatChannel: () => {},
});
