import React, {Component, useEffect} from 'react';
import ResourceFormGeneric from '../../Forms/ResourceFormGeneric';
import {switchRole}  from '../../../shared/roles';
import {roles} from './userConfig';

class UserForm extends Component {

    state = {
        uri: '/users',
        resource_id: this.props.match.params.id,
        action: this.props.match.params.id ? 'edit' : 'create',
        title: (this.props.match.params.id ? 'Editar' : 'Crear') + ' Usuario',
        fields: [
            {
                name: 'name',
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Nombre Completo'
            },
            {
                name: 'email',
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'email'
                },
                value: '',
                traduction: 'Email'
            },
            {
                name: 'username',
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Usuario'
            },
            {
                name: 'inbox_available',
                elementType: 'switch',
                elementConfig: {
                },
                value: true,
                traduction: 'Recibir mensajes por autoasignador'
            },
            {
                name: 'chatbot_available',
                elementType: 'switch',
                elementConfig: {
                },
                value: true,
                traduction: 'Recibir mensajes por bot'
            },
            {
                name: 'notification_available',
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Recibir notificaciones de nuevo mensaje'
            },
            {
                name: 'sound_available',
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Recibir sonido de nuevo mensaje'
            },
            {
                name: 'role_id',
                elementType: 'dropdown',
                elementConfig: {
                    options: null,
                    placeholder: 'Seleccione un rol'
                },
                value: '',
                traduction: 'Rol',
                requestResource: {
                    request:{
                        url: '/roles/available',
                        params: {},
                    },
                    format: {
                        value: 'id',
                        label: 'description'
                    }
                }
            },
            {
                name: 'provider_id',
                elementType: 'dropdown',
                elementConfig: {
                    options: null,
                    placeholder: 'Seleccione un cliente'
                },
                value: '',
                traduction: 'Cliente',
                requestResource: {
                    request:{
                        url: '/clients',
                        params: {},
                        page: 1,
                        filters: [
                            {
                                name: "available",
                                op: "eq",
                                val: true
                            }
                        ],
                    },
                    format: {
                        value: 'id',
                        label: 'name'
                    }
                }
            }
        ],
        loading: true,
    };

    componentDidMount(){
        let edit = this.props.match.params.id !== undefined;
        if (edit){
            let new_fields = [...this.state.fields];

            let reset_password = {
                name: 'reset_password',
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Restablecer contraseña'
            }
            new_fields.push(reset_password)

            let username_index = new_fields.findIndex(x => x.name === 'username')
            new_fields[username_index].elementConfig = {
                disabled: true
            }
            this.setState({fields: new_fields})
        }
        this.setState({loading: !this.state.loading})
    }

    setFields = (fields) => {
        this.setState({fields: fields})
    };

    render(){
        let {fields} = this.state;

        if(roles[switchRole()]){
            fields = fields.filter(field => {
                return !roles[switchRole()].exclude_fields.includes(field.name)
            })
        }

        const form = (<ResourceFormGeneric
            setFields={this.setFields}
            fields={fields}
            url={'/users'}
            resource_id={this.state.resource_id}
            resource_traduction={this.state.title}/>);

        return (
            <div className="grid p-fluid">
                <div className="col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1><strong>{this.state.title}</strong></h1>
                        {!this.state.loading && form}
                    </div>
                </div>
            </div>
        );
    }
}

export default UserForm;