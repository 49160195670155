import React, { useState, useEffect, useRef } from 'react';
import ApiLoan from "../../service/ApiLoanService";
import { InputText } from 'primereact/inputtext';
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";

const InternalGroupForm = (props) => {
    const [action, setAction] = useState('create');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);
    const [group, setGroup] = useState({
        name: '',
        enabled: true,
        users: []
    })

    useEffect(() => {
        let mounted = true;
        const apiService = new ApiLoan();

        if (mounted) {
            apiService.getResources({
                url: '/conversations/users',
            }).then(response => {
                const objects = response.data.objects;
                const users = objects.map(object => {
                    return {
                        'user_id': object.id,
                        'username': object.username,
                    }
                });
                setUsers(users);
            });
        }
        return () => mounted = false;
    }, []);

    useEffect(() => {
        const apiService = new ApiLoan();
        let edit = props.match.params.id !== undefined;

        if (edit) {
            if (users.length > 0) {
                apiService.getResource({
                    url: '/internal_groups/',
                    resource_id: props.match.params.id
                }).then(response => {
                    const data = response.data;
                    data['users'] = data.users.map(x => {
                        return {
                            'user_id': x.id,
                            'username': users.find(y => y['user_id'] === x.id)?.username,
                        }
                    });

                    setGroup(data);

                }).catch(error => {
                    refToast.current.show({
                        severity: 'error', summary: 'Grupo',
                        detail: 'Error al cargar el grupo.'
                    });
                    setTimeout(() => {
                        props.history.push('/internal_groups')
                    }, 2000);
                }).finally(f => {
                    setAction('edit');
                });
            }
        }
    }, [props.history, props.match.params.id, users]);

    useEffect(() => {
        if (loading) {
            if (users.length !== 0) {
                setLoading(false)
            }
        }
    }, [users, loading]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const apiService = new ApiLoan();

        let url = '/internal_groups'
        const formData = group;
        let api_request = null;
        if (action === 'create') {
            api_request = apiService.postResource;
        } else {
            api_request = apiService.patchResource;
            url = url + '/' + props.match.params.id;
        }

        api_request({
            'url': url,
            'data': formData
        }).then(response => {
            let message_action = action === 'create' ? 'creado' : 'editado';
            refToast.current.show({
                severity: 'success', summary: 'Grupo',
                detail: `El grupo se ha ${message_action} satisfactoriamente.`
            });
            setTimeout(() => {
                props.history.push('/internal_groups')
            }, 2000);
        }).catch(error => {
            setErrors(error.response.data)
            refToast.current.show({
                severity: 'error', summary: 'Grupo',
                detail: 'Verifique los datos del formulario.'
            });
        });
    }

    const updateInput = (value, item) => {
        setGroup(prevState => {
            return {
                ...prevState,
                [item]: value
            }
        });
    }

    const selectItemUser = (value) => {
        if (value) {
            return (
                <b>{value.username}</b>
            );
        } else {
            return <span className="my-multiselected-empty-token">Añadir Participantes</span>;
        }
    };

    const refToast = useRef(null);

    const labelSubmit = action === 'create' ? 'Crear' : 'Guardar';
    let form = loading ? null : (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h5>Grupo</h5>
                    <Toast ref={refToast} />
                    <div className="formgrid grid">
                        <div className="field col-8">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={group.name}
                                    onChange={(e) => updateInput(e.target.value, 'name')}
                                />
                                <label htmlFor="inputtext">Nombre del Grupo</label>
                            </span>
                            {errors?.name !== undefined ?
                                <small style={{ color: '#f44336' }} className="p-error">{errors?.name[0]}</small> : null}
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label">
                                <MultiSelect id="users"
                                    className=""
                                    value={group.users}
                                    name='users' optionLabel="username"
                                    options={users} filter
                                    onChange={(e) => updateInput(e.target.value, 'users')}
                                    placeholder="Usuarios"
                                    selectedItemTemplate={selectItemUser}
                                    resetFilterOnHide={true}
                                    maxSelectedLabels={1}
                                    selectedItemsLabel={'{0} usuarios seleccionados'}
                                />
                                <label htmlFor="users">Usuarios</label>
                            </span>
                            {errors?.users !== undefined ?
                                <small style={{ color: '#f44336' }} className="p-error">{errors?.users[0]}</small> : null}
                        </div>
                        <div className="field col-8">
                            <Button label={labelSubmit} onClick={handleSubmit} className="ml-auto"
                                icon={'pi pi-plus'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    return form;
}

export default InternalGroupForm;