import React, { useState, useEffect } from 'react';
import '../assets/css/CustomSpinner.css';

const CustomSpinner = (props) => {
  const [isImageActive, setIsImageActive] = useState(false);
  const [animationCount, setAnimationCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsImageActive(true);
      setAnimationCount((prevCount) => prevCount + 1);

      if (animationCount >= 1) {
        clearInterval(intervalId);
      }

      setTimeout(() => {
        setIsImageActive(false);
      }, 2000);
    }, 500);

    return () => clearInterval(intervalId);
  }, [animationCount]);

  return (
    <div className="custom-spinner-backdrop" style={props.style}>
      <div className="custom-spinner-containter">
        <div className="custom-spinner-content">
          <div style={{ textAlign: 'center' }}>
            <div className="image-container">
              <img
                className={`image-loading ${isImageActive ? 'active' : ''}`}
                src={`${process.env.REACT_APP_PAGE}/favicon.ico`}
                alt="Loading"
              />
            </div>
          </div>
          {props.status && (
            <span style={{ fontSize: '20px', color: '#31D1AEE5', position: 'relative', textAlign: 'center' }}>
              <br />
              {props.status}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomSpinner;