import React from 'react'
import Channel from "./Channel";

const channelEdit = (props) => {

    const resource = {
        title: 'Editar Canal',
        url: '/channels/',
        channel_id: props.match.params.id,
        fields: [
            {
                name: {
                    elementType: 'text',
                    elementConfig: {
                    },
                    value: '',
                    traduction: 'Nombre'
                },
                description: {
                    elementType: 'text',
                    elementConfig: {
                    },
                    value: '',
                    traduction: 'Descripción'
                }
            }
        ],
        action: 'edit'
    }

    return (
        <Channel title={resource.title} url={resource.url} fields={resource.fields}
              resource_id={resource.channel_id} action={resource.action}/>
    );
}

export default channelEdit;