import React from 'react';

import ModalForm from '../../../UI/ModalForm';
import ContactList from './ContactList';

const ModalContactList = ({client_id}) => {
    return (<ModalForm title={'Contactos'}
                       width={'35vw'}
                       buttonProps={{size: 'xs', className: 'btn-actions btn-actions-default btn-fontawesome', icon: 'address-book'}}>
                <ContactList client_id={client_id}/>
            </ModalForm>);
}

export default ModalContactList;