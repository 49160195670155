import React from 'react';
import Resources from '../Resources/Resources';

const AiAssistants = () => {
    let resource = {
        title: 'Asistentes IA',
        url: '/ai_assistants',
        colsHeader: [
            { field: 'name', header: 'Nombre'},
            { field: 'description', header: 'Descripción'},
            { field: 'instructions', header: 'Instrucciones'},
            { field: 'file_name', header: 'Archivo'},
            { field: 'action', header: 'Acciones' }
        ],
        params: {
        },
        action: {
            add: '/new',
            show: '/ai_assistant/',
            edit: '/edit',
            delete: true
        },
        urlTable: [
            '/ai_assistants',
            '/ai_assistants/new',
            '^\\/ai_assistant\\/\\d+$',
            '^\\/ai_assistant\\/\\d+\\/edit$'
        ]
    };

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                        params={resource.params}   action={resource.action} urlTable={resource.urlTable} />);
}

export default AiAssistants;