import React from 'react';
import ReactTooltip from 'react-tooltip';

const CustomTag = ({ iconComponent, color = 'green', text, tooltip, className }) => {
    const tagClass = `custom-tags ${className || ''}`.trim();

    return (
        <>
            <span className={tagClass} style={{ backgroundColor: color }} data-tip={tooltip}>
                {iconComponent} {text}
            </span>
            {tooltip && <ReactTooltip place="right" effect="solid" />}
        </>
    );
};

export default CustomTag;