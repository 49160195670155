import React, {useState, useEffect, useRef, Fragment, useContext} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {Toast} from "primereact/toast";
import {MultiSelect} from "primereact/multiselect";
import {SplitButton} from 'primereact/splitbutton';
import { Accordion, AccordionTab } from 'primereact/accordion';
import "../../../assets/css/BotResponseForm.css";
import {chatBotContext} from "../../../context/chatBotContext";
import {jsonPathToValue, byString, keyify} from '../../../utils/utils';
import {removeIdsRecursively} from '../../../utils/questionnaires';
import {InputTextarea} from "primereact/inputtextarea";
import Picker from "emoji-picker-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import { OPTIONAL, OPEN} from '../../../shared/questionnaireType';
import { EMAIL, NUMERIC, ALPHANUMERIC, FILE, DATE } from '../../../shared/questionnaireValidate';
import SheetForm from './SheetForm';
import { Sidebar } from 'primereact/sidebar';
import { handleCopy } from '../../../utils/utils';
import BotResponseMultimediaForm from "./BotResponseMultimediaForm";
import QuestionnaireProviderWebhook from "./QuestionnaireProviderWebhook";
import QuestionnaireProviderAiAssistants from './QuestionnaireProviderAiAssistants';
import { InputSwitch } from 'primereact/inputswitch';
import VariableForm from './VariableForm';
import { ScrollPanel } from 'primereact/scrollpanel';
import {isAnyOtherConfigActive} from './utils/botReponseFormUtil.js';
import { CompanyContext } from "../../../context/companyContext"

const BotResponseForm = (props) => {
    const [visibleSidebar, setVisibleSidebar] = useState(false);
    const [showAttributes, setShowAttributes] = useState(null);
    const {users, tags, groups} = useContext(chatBotContext);
    const [validateOptions, setValidateOptions] = useState([]);
    const [formValues, setFormValues] = useState({
        responses: [
            {
                name: '', success_message: null, success_message_multimedia_config: [], users: [], groups: [], custom_tags: [],
                error: {}, questionnaires: [], can_create_child: true, show_users: true, show_groups: false, stop: false
            }
        ],
        config: {
            sheet: null,
            provider_webhook: null,
        },
        question: '',
        question_multimedia_config: [],
        question_variable_config: {name: null, variable: null, error: {}},
        questionnaire_type_id: OPTIONAL,
        questionnaire_validate_id: null,
        multiple: false,
        error: {},
        ai_assistant_id: null
    });

    const [loading, setLoading] = useState(true);

    const [showPicker, setShowPicker] = useState({
        show: false,
        cursorPosition: null,
        index: null,
        type: null,
        popup: false,
    })

    const [showSidebar, setShowSidebar] = useState({show: false, index: null, form: null});
    const [showEditSuccessMessage, setShowEditSuccessMessage] = useState({show: false, index: null, item: null});
    const [showEditOption, setShowEditOption] = useState({show: false, index: null, item: null});
    const [showEditQuestion, setShowEditQuestion] = useState({show: false, form: null});
    const [showSheet, setShowSheet] = useState({show: false, index: null, form: null});
    const [showMultimedia, setShowMultimedia] = useState({
        show: false,
        form: null
    });
    const [showVarible, setShowVarible] = useState({
        show: false,
        form: null
    });

    const [showQuestionMultimedia, setShowQuestionMultimedia] = useState({
        show: false,
        form: null
    });

    const [showProviderWebhook, setShowProviderWebhook] = useState({
        show: false,
        index: null,
        form: null
    });

    const [showAiAssistanOptionsState, setShowAiAssistanOptionsState] = useState({
        show: false,
        index: null,
        form: null
    });

    const companyContext = useContext(CompanyContext);

    useEffect(() => {
        if (props.action === 'edit' && props.resource !== null){
            setFormValues(props.questionnaire);
        } else {
            if (props.questionnaire !== null){
                setFormValues(props.questionnaire);
            }
        }
        setLoading(false);

    }, [props.action, props.resource, props.questionnaire]);

    useEffect(() => {
        if (props.parent) props.modifyOrAddQuestion(formValues, props.index);
    }, [formValues]);

    useEffect(() => {
        if (!showProviderWebhook.show) setVisibleSidebar(showProviderWebhook.show);
    }, [showProviderWebhook]);

    useEffect(() =>{
        let options = [];
        let validate = {
            '1': 'Correo',
            '2': 'Numérica',
            '3': 'Alfanumérica',
            '4': 'Archivo',
            '5': 'Fecha',
            '6': 'Geolocalización',
        };
        for (let key in validate) {
            options.push({
                'value':  key,
                'label': validate[key]
            });
        }
        if(formValues.config.sheet !== null){
            if (formValues.questionnaire_validate_id === FILE) {
                let newFormValues = {...formValues};
                newFormValues['questionnaire_validate_id'] = null;
                setFormValues(newFormValues);
                if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
                    props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
                }
            }
        }
        setValidateOptions(options);
    }, [props.questionnaires,formValues]);

    useEffect(() => {
        if (formValues.questionnaire_type_id !== OPTIONAL){
            let newFormValues = {...formValues};
            newFormValues.questionnaire_type_id = OPEN;
            newFormValues.responses = [
                {
                    id: newFormValues.responses[0].id,
                    name: ' ',
                    success_message: newFormValues.responses[0].success_message,
                    success_message_multimedia_config: newFormValues.responses[0].success_message_multimedia_config,
                    users: newFormValues.responses[0].users,
                    groups: newFormValues.responses[0].groups,
                    custom_tags: newFormValues.responses[0].custom_tags,
                    error: newFormValues.responses[0].error,
                    questionnaires: newFormValues.responses[0].questionnaires,
                    can_create_child: newFormValues.responses[0].can_create_child,
                    show_users: newFormValues.responses[0].show_users,
                    show_groups: newFormValues.responses[0].show_groups,
                    stop: newFormValues.responses[0].stop,
                }
            ]
            setFormValues(newFormValues);
            if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
                props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
            }
        }
    }, [props.questionnaires,formValues.questionnaire_type_id]);

    useEffect(() => {
        if (props.errors === null || props.errors === undefined){
            return null
        }

        let key_errors = keyify(props.errors)
        let key_errors_parsed = key_errors.map(k => {
            return k.slice(0, -2)
        })
        key_errors_parsed = key_errors_parsed.map(x => {
            let find_error = byString(props.errors,x);
            let search_key_index = x.lastIndexOf('.');
            let value = x;
            let key_without_value = '';
            if (search_key_index !== -1){
                value = x.slice(search_key_index +1, x.length)
                key_without_value = x.slice(0, search_key_index)
            }

            return {
                'key': value,
                'error': find_error,
                'path': x,
                'path2': key_without_value
            }
        })

        let newFormValues = {...formValues};

        key_errors_parsed.map(x => {
            if (x.key === 'question'){
                if (x.path2 !== ''){
                    let question = jsonPathToValue(newFormValues, x.path2);

                    if (question.error !== undefined){
                        let question_error = {};
                        if (Object.keys(question.error).length !== 0){
                            question_error = question.error
                        }
                        if (Object.keys(question.error).filter(v => v === 'question').length === 0){
                            question_error['question'] = x.error
                        }
                        question.error = question_error
                    }
                } else if (x.path2 === '') {
                    newFormValues.error['question'] = x.error
                }

            }
            else if (x.key === 'custom_tags' || x.key === 'users' || x.key === 'groups' ||  x.key === 'name'){
                let child_path = x.path2
                if (x.path2 === ''){
                    child_path = '.'
                }
                let child_fields = {};
                if (child_path.includes('_schema')){
                    child_path = child_path.replace('_schema.0','responses')
                    child_fields = jsonPathToValue(newFormValues, child_path);
                } else {
                    child_fields = jsonPathToValue(newFormValues, child_path);
                }

                if (child_fields.error !== undefined){
                    let child_fields_error = {};
                    if (Object.keys(child_fields.error).length !== 0){
                        child_fields_error = child_fields.error;
                    }
                    if (x.key === 'users'){
                        if (Object.keys(child_fields_error).filter(v => v === 'users').length === 0){
                            child_fields_error['users'] = x.error;
                        }
                    }
                    if (x.key === 'groups'){
                        if (Object.keys(child_fields_error).filter(v => v === 'groups').length === 0){
                            child_fields_error['groups'] = x.error;
                        }
                    }
                    if (x.key === 'custom_tags'){
                        if (Object.keys(child_fields_error).filter(v => v === 'custom_tags').length === 0){
                            child_fields_error['custom_tags'] = x.error;
                        }
                    }
                    if (x.key === 'name'){
                        if (Object.keys(child_fields_error).filter(v => v === 'name').length === 0){
                            child_fields_error['name'] = x.error;
                        }
                    }

                    child_fields.error = child_fields_error;
                }
            }
        });

        setFormValues(newFormValues)

        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
        }

    }, [props.errors, props.questionnaire]);

    const handleChange = (i, e) => {
        let newFormValues = {...formValues}

        newFormValues.responses[i][e.name] = e.value;
        if (Object.keys(newFormValues.responses[i]['error']).length !== 0){
            newFormValues.responses[i]['error'] = {};
        }
        setFormValues(newFormValues);

        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
        }
    }
    const handleSheet = (value) => {
        let newFormValues = {...formValues};

        newFormValues.config.sheet = value
        setFormValues(newFormValues);
        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
        }
    }

    const handleVariable = (value) => {
        let newFormValues = {...formValues};
        newFormValues.question_variable_config.name = value.name
        newFormValues.question_variable_config.variable = value.variable
        newFormValues.question_variable_config.error = {}
        setFormValues(newFormValues);
        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
        }
    }

    const handleProviderWebhook = (value) => {
        let newFormValues = {...formValues};

        newFormValues.config.provider_webhook = value
        setFormValues(newFormValues);
        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
        }
    }

    const handleSelectedAiAssistant = (value) => {
        let newFormValues = {...formValues};

        newFormValues.ai_assistant_id = value
        setFormValues(newFormValues);
        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
        }
    }

    const handleQuestionMultimedia = (value) => {
        let newFormValues = {...formValues};

        if (Array.isArray(value)){
            newFormValues["question_multimedia_config"] = value;
        } else {
            newFormValues["question_multimedia_config"].push(value);
        }

        setFormValues(newFormValues);

        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
        }
    }

    const handleMultimedia = (value) => {
        let index = showMultimedia.index;

        let newFormValues = {...formValues};

        if (Array.isArray(value)){
            newFormValues.responses[index]['success_message_multimedia_config'] = value;
        } else {
            newFormValues.responses[index]['success_message_multimedia_config'].push(value);
        }

        if (Object.keys(newFormValues.responses[index]['error']).length !== 0){
            newFormValues.responses[index]['error'] = {};
        }

        setFormValues(newFormValues);

        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
        }

    }

    const handleToggleChange = (i, item) => {
        let newFormValues = {...formValues}

        newFormValues.responses[i]['show_users'] = !newFormValues.responses[i].show_users
        newFormValues.responses[i]['show_groups'] = !newFormValues.responses[i].show_groups
        newFormValues.responses[i]['users'] = []
        newFormValues.responses[i]['groups'] = []

        setFormValues(newFormValues);

        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues]
        }
    }

    const addFormFields = () => {
        let newFormValues = {...formValues};

        newFormValues.responses = [...newFormValues.responses, {
            name: '', success_message: null, success_message_multimedia_config: [], users: [], groups: [], custom_tags: [],
            error: {}, questionnaires: [], can_create_child: true, show_users: true, show_groups: false
        }]

        setFormValues(newFormValues);
    }

    const addFormFieldsCopy = (response) => {
        let newFormValues = JSON.parse(JSON.stringify(formValues));
        let newResponse = {...response};
        newFormValues.responses = [...newFormValues.responses, removeIdsRecursively(newResponse)];

        setFormValues(newFormValues);
    }

    const removeFormFields = (index) => {
        let newFormValues = {...formValues};
        newFormValues.responses = newFormValues.responses.filter((x,i)=> i !== index);
        setFormValues(newFormValues);

        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues];
        }
    }

    const stopFormFields = (index) => {
        let newFormValues = { ...formValues };
        const response = newFormValues.responses[index];
        if (response.stop) response.stop = false;
        else response.stop = true;

        setFormValues(newFormValues);

        if (props.parentFormValues !== undefined || props.parentIndex !== undefined) {
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues];
        }
    }

    const addFormFieldsChild = (index) => {
        let newFormValues = {...formValues};

        newFormValues.responses[index].can_create_child = false;
        newFormValues.responses[index].custom_tags = [];
        newFormValues.responses[index].success_message = null;
        newFormValues.responses[index].stop = false;
        newFormValues.responses[index].success_message_multimedia_config = [];
        newFormValues.responses[index].users = [];
        newFormValues.responses[index].error = {};
        newFormValues.responses[index].show_users = true;
        newFormValues.responses[index].show_groups = false;
        newFormValues.responses[index].groups = [];
        newFormValues.responses[index].questionnaires = [{
            responses: [
                {
                    question: '', name: '', success_message: null, success_message_multimedia_config: [], users: [], groups: [],
                    custom_tags: [], error: {}, questionnaires: [], can_create_child: true, show_users: true,
                    show_groups: false, stop: false
                }
            ],
            config: {
                sheet: null,
                provider_webhook: null,
            },
            question: '',
            question_multimedia_config: [],
            question_variable_config: {name: null, variable: null, error: {}},
            questionnaire_type_id: OPTIONAL,
            multiple: false,
            error: {},
            ai_assistant_id: null
        }];

        setFormValues(newFormValues);
    }

    const deleteFormFieldsChild = (index) => {
        let newFormValues = {...formValues};
        newFormValues.responses[index].can_create_child = true;
        newFormValues.responses[index].questionnaires = [];
        setFormValues(newFormValues);

        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues];
        }

        setFormValues(newFormValues);
    }

    const refToast = useRef(null);
    const refInputQuestions = useRef(null);
    const refInputQuestionsPopup = useRef(null);
    const refInputOptions = useRef([]);
    const refInputSuccessMessage = useRef([]);
    const refInputSuccessMessagePopup = useRef(null);
    const refInputOptionPopup = useRef(null);

    const selectItemTemplate = (value) => {
        if (value) {
            return (
                <b>{value.name}</b>
            );
        } else {
            return <span className="my-multiselected-empty-token">Etiquetar</span>;
        }
    };

    const selectItemUser = (value) => {
        if (value) {
            return (
                <b>{value.username}</b>
            );
        } else {
            return <span className="my-multiselected-empty-token">Usuarios</span>;
        }
    };

    const selectItemGroup = (value) => {
        if (value) {
            return (
                <b>{value.name}</b>
            );
        } else {
            return <span className="my-multiselected-empty-token">Equipos</span>;
        }
    }

    const updateQuestion = (e) => {
        let newFormValues = {...formValues}
        if (e === 'submit') {
            if (newFormValues['questionnaire_type_id'] === OPTIONAL){
                newFormValues['questionnaire_type_id'] = OPEN;
            } else {
                newFormValues['questionnaire_type_id'] = OPTIONAL;
                newFormValues['questionnaire_validate_id'] = null;
                newFormValues['config'] = {
                    sheet: null,
                    provider_webhook: null,
                };
                newFormValues.responses[0].name = '';
                newFormValues['ai_assistant_id'] = null;
            }
        }
        else if (e?.name !== undefined) {
            newFormValues[e.name] = e.value;
        } else {
            let questionnaire_value = e === null ? null : parseInt(e);
            newFormValues['questionnaire_validate_id'] = questionnaire_value;
            if (questionnaire_value !== 4) newFormValues['multiple'] = false;
        }

        if (newFormValues['multiple']) newFormValues['question_variable_config'] = {
            name: null,
            variable: null,
            error: {},
        }

        if (Object.keys(newFormValues.error).length > 0){
            newFormValues.error = {};
        }

        setFormValues(newFormValues);

        if (props.parentFormValues !== undefined || props.parentIndex !== undefined){
            props.parentFormValues.responses[props.parentIndex].questionnaires = [newFormValues];
        }
    }

    const getChildForm = (item, index) => {
        return item.questionnaires.map((questionnaire, questionnaire_index) => {
            return (
                <div key={'child-' + index +1}>
                    <BotResponseForm resource={props.resource}
                                     action={props.action}
                                     labelSubmit={props.labelSubmit}
                                     parentFormValues={formValues}
                                     parentIndex={index}
                                     displayButton={false}
                                     label={props.level === undefined ? 'Nivel ' + 1 : 'Nivel ' + ((props.level + 1) + 1)}
                                     loading={true}
                                     level={props.level === undefined ? 0 : props.level + 1}
                                     questionnaire={questionnaire}
                                     errors={props.errors}
                                     modifyOrAddQuestion={props.modifyOrAddQuestion}
                                     addQuestionSubmit={props.addQuestionSubmit}
                                     removeQuestionSubmit={props.removeQuestionSubmit}
                                     index={props.index}
                                     parent={false}
                                     formValues={props.formValues}
                                     questionnaires={props.questionnaires}
                                     setQuestionnaires={props.setQuestionnaires}
                    />
                </div>
            )
        })

    }

    const getFieldError = (errors, field) => {
        let form = null;

        if (errors !== null){
            if (Object.keys(errors).filter(x => x === field)[0] !== undefined){
                form = (
                    <small style={{color:'#f44336'}} className="p-error">
                        {errors[field][0]}
                    </small>
                );
            }
        }

        return form;
    }

    const getQuestionnaireFieldError = (errors, field) => {
        if (errors === undefined){
            return
        }
        let form = null;

        if (Object.keys(errors).filter(x=> x === field).length > 0){
            form = (
                <small style={{color:'#f44336'}} className="p-error">
                    {errors[field][0]}
                </small>
            );
        }

        return form;
    }

    const getItemsMenu = (index, responses_length, can_create_child, level, questionnaire_type, questionnaires) => {
        let disabled_form_child = !can_create_child;
        let options = []
        if (level === 8){
            disabled_form_child = true;
        }

        options = [
            {
                label: 'Eliminar opción',
                icon: 'pi pi-times',
                command: () => {
                    removeFormFields(index)
                },
                disabled: responses_length === 1
            },
            {
                label: 'Duplicar opción',
                icon: 'pi pi-copy',
                command: () => {
                    addFormFieldsCopy(formValues.responses[index])
                },
            },
            {
                label: disabled_form_child ? 'Eliminar nivel' : 'Agregar nivel',
                icon: disabled_form_child ? 'pi pi-minus-circle' : 'pi pi-plus-circle',
                command: () => {
                    disabled_form_child ? deleteFormFieldsChild(index) : addFormFieldsChild(index)
                },
            },
            {
                label: formValues.responses[index].stop ? 'Continuar Secuencia' : 'Terminar Secuencia',
                icon: formValues.responses[index].stop ? 'pi pi-check-circle' : 'pi pi-ban',
                command: () => {
                    stopFormFields(index)
                },
                disabled: questionnaires.length === 1 || !can_create_child
            },
        ];
        if (questionnaire_type !== 1){
            options.shift()
            options.shift()
        }
        return options;
    }

    useEffect(() => {
        if (props.questionnaires.length === 1) {
            formValues.responses.map((element, index) =>{
                setFormValues((prevState) => {
                    const newState = { ...prevState };
                    newState.responses[index].stop = false;
                    return newState;
                });
            })
        }
    },[props.questionnaires])

    const onEmojiClick = (event, emojiObject) => {
        event.preventDefault();

        let element_ref = showPicker.type;
        let input_element = element_ref.current
        if (showPicker.index !== null){
            input_element = element_ref.current[showPicker.index]
        }

        let text_before_cursor_position = input_element.value.substring(0, showPicker.cursorPosition)
        let text_after_cursor_position = input_element.value.substring(showPicker.cursorPosition, input_element.value.length)
        input_element.value = text_before_cursor_position + emojiObject.emoji + text_after_cursor_position

        if(!showPicker.popup){
            if (showPicker.index !== null){
                handleChange(showPicker.index, input_element)
            } else {
                updateQuestion(input_element)
            }
        }else{
            if(showEditSuccessMessage.show){
                showEditSuccessMessageHandler(showEditSuccessMessage.index, {...showEditSuccessMessage, item: {...showEditSuccessMessage.item}, success_message: input_element.value})
            }
            if (showEditQuestion.show) {
                showEditQuestionHandler({...showEditQuestion, form: {...showEditQuestion.form}, question: input_element.value})
            }
            if(showEditOption.show){
                showEditOptionHandler(showEditOption.index, {...showEditOption, item: {...showEditOption.item}, name: input_element.value})
            }
        }
        setShowPicker((prevState) => {
            return {
                ...prevState,
                cursorPosition: null,
                show: false,
                index: null,
                type: null,
                popup: false,
            }

        })
    };

    const showPickerHandler = (start, index, type, popup=false) => {
        setShowPicker((prevState) => {
            return {
                ...prevState,
                cursorPosition: start,
                show: true,
                index: index,
                type: type,
                popup: popup
            }
        })
    }

    const showSheetHandler = (index, form) => {
        setShowSheet((prevState) => {
            return {
                ...prevState,
                show: true,
                index: index,
                form: form,
            }
        })
    }

    const showMultimediaHandler = (index, form) => {
        setShowMultimedia((prevState) => {
            return {
                ...prevState,
                show: true,
                index: index,
                form: form,
            }
        })
    }

    const showProviderWebhookHandler = (index, form) => {
        setShowProviderWebhook((prevState) => {
            return {
                ...prevState,
                show: true,
                index: index,
                form: form,
            }
        })
    }

    const showProviderAiAssistantHandler = (index, form) => {
        const ai_assistant_id = form;
        setShowAiAssistanOptionsState((prevState) => {
            return {
                ...prevState,
                show: true,
                index: index,
                form: ai_assistant_id,
            }
        })
    }

    const hideProviderAiAssistantHandler = () => {
        setShowAiAssistanOptionsState((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }


    const showSidebarHandler = (index, form) => {
        setShowSidebar((prevState) => {
            return {
                ...prevState,
                show: true,
                index: index,
                form: form,
            }
        })
    }

    const showEditQuestionHandler = (form) => {
        setShowEditQuestion((prevState) => {
            return {
                ...prevState,
                show: true,
                form: form,
            }
        })
    }

    const showEditSuccessMessageHandler = (index, item) => {
        setShowEditSuccessMessage((prevState) => {
            return {
                ...prevState,
                show: true,
                index: index,
                item: item,
            }
        })
    }

    const showEditOptionHandler = (index, item) => {
        setShowEditOption((prevState) => {
            return {
                ...prevState,
                show: true,
                index: index,
                item: item,
            }
        })
    }

    const hidePicker = () => {
        setShowPicker((prevState) => {
            return {
                ...prevState,
                cursorPosition: null,
                show: false,
                index: null,
                type: null,
                popup: false,
            }
        })
    }

    const hideSheet = () => {
        setShowSheet((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }

    const hideQuestionMultimedia = () => {
        setShowQuestionMultimedia((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }

    const hideMultimedia = () => {
        setShowMultimedia((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }

    const hideVariable = () => {
        setShowVarible((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }

    const hideProviderWebhook = () => {
        setShowProviderWebhook((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }

    const hideSidebar  = () => {
        setShowSidebar ((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }

    const hideEditQuestion  = () => {
        setShowEditQuestion ((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }

    const hideEditSuccessMessage  = () => {
        setShowEditSuccessMessage ((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }

    const hideEditOption  = () => {
        setShowEditOption ((prevState) => {
            return {
                ...prevState,
                show: false,
            }
        })
    }

    const handleCopySidebar  = (e) => {
        handleCopy(e.target.value);
        hideSidebar();
    }

    const questionnaireValidateSanitize = (value) =>{
        return typeof(value) === 'number' || typeof(value) === 'string'  ? String(value): null
    }

    const successMultimediaMimetypeIcon = (mimetype) => {
        let mimetypes_icons = {
            'image/jpg': 'image',
            'image/png': 'image',
            'image/jpeg': 'image',
            'application/pdf': 'file-pdf',
        }
        return mimetypes_icons[mimetype];
    }

    let question_items = [
        {
            label: formValues?.question_multimedia_config?.length !== 0 ? 'Editar multimedia' : 'Agregar multimedia',
            icon: 'pi pi-image',
            command: () => {
                setShowQuestionMultimedia((prevState) => {
                    return {
                        ...prevState,
                        show: true,
                        form: formValues,
                    }
                })
            }
        },
        {
            label: formValues?.question_variable_config.name ? 'Editar variable' : 'Agregar variable',
            icon: 'pi pi-hashtag',
            command: () => {
                setShowVarible((prevState) => {
                    return {
                        ...prevState,
                        show: true,
                        form: formValues,
                    }
                })
            }
        },
        {
            label: formValues.config.provider_webhook !== null ? 'Editar Webhook' : 'Agregar Webhook',
            icon: 'pi pi-share-alt',
            command: () => {
                showProviderWebhookHandler(0, formValues.config.provider_webhook)
            },
            disabled: isAnyOtherConfigActive(formValues, 'provider_webhook')
        }
    ]

    if (formValues.questionnaire_type_id === OPEN) {
        question_items.push(
            {
                label: formValues.config.sheet !== null ? 'Editar Sheet' : 'Agregar Sheet',
                icon: 'pi pi-file-excel',
                command: () => {
                    showSheetHandler(0, formValues.config.sheet);
                },
                disabled: isAnyOtherConfigActive(formValues, 'sheet')
            },
        );

        if (companyContext.aiAssistantEnabled) {
            question_items.push({
                label: 'Elegir asistente',
                icon: 'fa-regular fa-head-side-gear',
                command: () => {
                    showProviderAiAssistantHandler(0, formValues.ai_assistant_id);
                },
                disabled: isAnyOtherConfigActive(formValues, 'ai_assistant')
            })
        }
    }

    let buttonRemoveAndAddBotSequential = (
        <div className="grid">
            {props.parent && (
                <div className='accordion-bot-response-button'>
                    {props.index === 0 && (
                        <i onClick={()=>props.addQuestionSubmit()} className="pi pi-plus mr-2"></i>
                    )}
                    {props.index > 0 && (
                        <i onClick={()=>props.removeQuestionSubmit(props.index)} className="pi pi-minus mr-2"></i>
                    )}
                </div>
            )}
        </div>
    )

    let form = (
        <div className="grid mb-3">
            <div className="col-12 md-12">
                {buttonRemoveAndAddBotSequential}
                <Accordion className={'accordion-bot-response-form'} multiple activeIndex={[0]}>
                    <AccordionTab disabled={true} header={props.label}>
                        <Toast ref={refToast}/>
                        <div className="grid formgrid">
                            <div className="col-12">
                        <div className="col-12">
                            <div className="grid formgrid">
                                <div className="field col">
                                    <div className='p-inputgroup bot-response-multimedia-group'>
                                        <button className="p-link p-button p-component p-button-icon-only btn-actions-default"
                                                       onClick={() => {updateQuestion('submit')}}>
                                            <FontAwesomeIcon icon={formValues.questionnaire_type_id === OPTIONAL ? 'circle-question' : 'question'}/>
                                        </button>
                                        <span className="p-float-label">
                                            <InputText name={'question'} value={formValues.question}
                                                       onChange={(e) => updateQuestion(e.target)}
                                                       ref={el => refInputQuestions.current = el}
                                                       className={'bot-response-question'} rows={1} />
                                            <label htmlFor="inputtext">{formValues.questionnaire_type_id === OPTIONAL ?
                                                'Escribir pregunta con opciones' : 'Escribir pregunta abierta'}
                                            </label>
                                            <div className={"popup-edit"}>
                                                <i className="pi pi-pencil"
                                                onClick={() => {showEditQuestionHandler(formValues)}}/>
                                            </div>
                                            <SplitButton icon={null} className="success_message_items"
                                                         model={question_items} />
                                        </span>
                                        <div className="icons">
                                            {formValues.question_variable_config.name && (
                                                <i className="pi pi-hashtag"/>
                                            )}
                                            {formValues.config.provider_webhook && (
                                                <i className="pi pi-share-alt"/>
                                            )}
                                            {formValues.config.sheet && (
                                                <i className="pi pi-file-excel"/>
                                            )}
                                            {formValues?.question_multimedia_config?.length !== 0 && (
                                                <i className={"pi pi-file"}/>
                                            )}
                                            {formValues.ai_assistant_id && (
                                                <FontAwesomeIcon
                                                    icon={'fa-regular fa-head-side-gear'}
                                                    color={'#31D1AEE5'}
                                                    className={"bot-response-icons"} />
                                            )}
                                        </div>
                                    </div>
                                    {getQuestionnaireFieldError(formValues.error, 'question')}
                                </div>
                                {formValues.questionnaire_type_id !== OPTIONAL &&
                                formValues.ai_assistant_id == null && (
                                    <div className={"p-col-2"}>
                                        <span className="p-float-label p-col space-left">
                                            <Dropdown id="validate" value={questionnaireValidateSanitize(formValues.questionnaire_validate_id)} optionLabel="label" optionValue="value"
                                            options={validateOptions} showClear onChange={(e) => updateQuestion(e.value) }/>
                                            <label htmlFor="validate">Validación</label>
                                        </span>
                                    </div>
                                 )}
                                 {(formValues.questionnaire_type_id !== OPTIONAL && formValues?.questionnaire_validate_id === FILE) &&
                                    <div className={"field"}>
                                        <label htmlFor="switch">Múltiples</label>
                                        <span className="p-float-label">
                                            <InputSwitch checked={formValues.multiple} onChange={(e) => updateQuestion({name: 'multiple', value: e.value})} />
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>

                        {formValues.responses.map((item, index) => {
                            let success_message_items = [
                                {
                                    label: item?.success_message_multimedia_config?.length !== 0 ? 'Editar Multimedia' : 'Agregar multimedia',
                                    icon: 'pi pi-image',
                                    command: () => {
                                        showMultimediaHandler(index, item)
                                    }
                                }
                            ]

                            return (
                                <Fragment key={index}>
                                    <div className="grid formgrid" key={index}>
                                        <div className="field col inline-flex">
                                            {formValues.questionnaire_type_id === OPTIONAL && (
                                                <Fragment>
                                                    <div className="field">
                                                        <span className="p-float-label">
                                                            <InputText name={'name'} value={item.name}
                                                                       ref={el => refInputOptions.current[index] = el}
                                                                       onChange={(e) => handleChange(index, e.target)}/>
                                                            <label htmlFor="inputtext">Opción {index + 1}</label>
                                                        </span>
                                                        {getFieldError(item.error, 'name')}
                                                    </div>
                                                    <div className={"popup-option-edit"}>
                                                        <i className="pi pi-pencil"
                                                            onClick={() => {showEditOptionHandler(index, item)}}/>
                                                    </div>
                                                </Fragment>
                                            )}
                                            <div className="field col">
                                                <div className='p-inputgroup bot-response-multimedia-group'>
                                                    <span className="p-float-label">
                                                        <InputText className={'bot-response-textarea'} name={'success_message'} value={item.success_message === null ? '' : item.success_message}
                                                               onChange={(e) => handleChange(index, e.target)}
                                                               ref={el => refInputSuccessMessage.current[index] = el}
                                                               disabled={!item.can_create_child ? true : false} rows={1} />
                                                        <label htmlFor="inputtext">Defina mensaje de cierre</label>
                                                        {item.can_create_child && (
                                                            <div className={"popup-edit"}>
                                                                <i className="pi pi-pencil"
                                                                   onClick={() => {showEditSuccessMessageHandler(index, item)}}/>
                                                            </div>
                                                        )}
                                                        <div className="icons">
                                                            {item.stop && (
                                                                <i className="pi pi-ban"/>
                                                            )}
                                                            {item?.success_message_multimedia_config?.length !== 0 && (
                                                                <i className={"pi pi-file"}/>
                                                            )}
                                                        </div>
                                                        <SplitButton disabled={!item.can_create_child ? true : false}
                                                                     className="success_message_items"
                                                                     model={success_message_items} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <SplitButton icon="pi pi-plus" className={formValues.questionnaire_type_id === OPTIONAL ? "p-button-sm p-button-pip" : "p-button-sm p-button-pip-disable"}
                                                         onClick={(e) => formValues.questionnaire_type_id === OPTIONAL ? addFormFields() : null}
                                                         model={getItemsMenu(index, formValues.responses.length,
                                                             item.can_create_child, props.level, formValues.questionnaire_type_id, props.questionnaires)}>
                                            </SplitButton>
                                        </div>
                                        <div className="field inline-flex">
                                            <div className="field col">
                                                <div className="p-inputgroup">
                                                    <span className="p-float-label">
                                                        <button className="p-link p-button p-component p-button-icon-only btn-actions-default"
                                                                onClick={(e) => handleToggleChange(index, item )}>
                                                            <FontAwesomeIcon icon={item.show_users ? 'user' : 'users'}/>
                                                        </button>
                                                        <span className="p-float-label">
                                                            <MultiSelect
                                                                id="user-group"
                                                                className=""
                                                                value={item.show_users ? item.users : item.groups}
                                                                name={item.show_users ? 'users' : 'groups'}
                                                                optionLabel={item.show_users ? "username" : 'name'}
                                                                options={item.show_users ? users : groups} filter
                                                                onChange={(e) => handleChange(index, e.target)}
                                                                placeholder={item.show_users ? "Usuarios" : 'Equipos'}
                                                                selectedItemTemplate={item.show_users ? selectItemUser : selectItemGroup}
                                                                resetFilterOnHide={true}
                                                                maxSelectedLabels={0}
                                                                selectedItemsLabel={item.show_users ? '{0} usuario' : '{0} grupo'}
                                                                disabled={!item.can_create_child}
                                                            />
                                                            <label htmlFor="user-group">{item.show_users ? "Usuarios" : 'Equipos'}</label>
                                                        </span>
                                                    </span>
                                                </div>
                                                {item.show_users && (
                                                    (item.can_create_child) && (
                                                        !(item.users.length !== 0) && (
                                                            !(item.custom_tags.length !== 0) && (
                                                                getFieldError(item.error, 'users')
                                                            )
                                                        )
                                                    )
                                                )}
                                                {item.show_groups && (
                                                    (item.can_create_child) && (
                                                        !(item.groups.length !== 0) && (
                                                            !(item.custom_tags.length !== 0) && (
                                                                getFieldError(item.error, 'groups')
                                                            )
                                                        )
                                                    )
                                                )}
                                            </div>
                                            <div className="field col-mb-8">
                                                <span className="p-float-label">
                                                    <MultiSelect id="custom_tags"
                                                                 className=""
                                                                 value={item.custom_tags}
                                                                 name='custom_tags' optionLabel="name"
                                                                 options={tags} filter
                                                                 onChange={(e) => handleChange(index, e.target)}
                                                                 placeholder="Etiquetar"
                                                                 selectedItemTemplate={selectItemTemplate}
                                                                 resetFilterOnHide={true}
                                                                 maxSelectedLabels={0}
                                                                 selectedItemsLabel={'{0} etiquetas'}
                                                                 disabled={!item.can_create_child}
                                                    />
                                                    <label htmlFor="custom_tags">Etiquetar</label>
                                                </span>
                                                {(item.can_create_child) && (
                                                    !(item.custom_tags.length !== 0) && (
                                                        !(item.show_groups ? item.groups.length !== 0 : item.users.length !== 0) && (
                                                            getFieldError(item.error, 'custom_tags')
                                                        )
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-fixed" style={{ width: '30px'}}></div>
                                        <div className="col">
                                            {getChildForm(item, index)}
                                        </div>
                                    </div>

                                </Fragment>
                            );
                        })}
                    </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>

            {showPicker.show && (
            <Dialog header="Emojis" visible={showPicker.show} style={{ width: '40vw' }} onHide={() => hidePicker()}>
                <div>
                    <Picker
                        disableSearchBar={true}
                        pickerStyle={{ width: "100%", zIndex: "0" }}
                        onEmojiClick={(ev, data) => onEmojiClick(ev, data)}/>
                </div>
            </Dialog>)}

            {showQuestionMultimedia.show && (
            <Dialog header="Configuración Multimedia" visible={showQuestionMultimedia.show} style={{ width: '50%', height: 'auto' }} onHide={() => hideQuestionMultimedia()}>
                <div>
                    <BotResponseMultimediaForm
                        refToast={refToast}
                        formData={showQuestionMultimedia.form.question_multimedia_config}
                        closeModal={hideQuestionMultimedia}
                        setFormData={(data) => handleQuestionMultimedia(data)}
                        action={props.action}
                    />
                </div>
            </Dialog>)}

            {showSheet.show && (
            <Dialog header="Configuración Sheet" visible={showSheet.show} style={{ width: '50%', height: 'auto' }} onHide={() => hideSheet()}>
                <div>
                   <SheetForm index={showSheet.index} refToast={refToast} formData={showSheet.form} hideSheet={hideSheet} setFormData={handleSheet}></SheetForm>
                </div>
            </Dialog>)}

            {showMultimedia.show && (
            <Dialog header="Configuración Multimedia" visible={showMultimedia.show} style={{ width: '50%', height: 'auto' }} onHide={() => hideMultimedia()}>
                <div>
                    <BotResponseMultimediaForm
                        refToast={refToast}
                        formData={showMultimedia.form.success_message_multimedia_config}
                        closeModal={hideMultimedia}
                        setFormData={(data) => handleMultimedia(data)}
                        action={props.action}
                    />
                </div>
            </Dialog>)}

            {showVarible.show && (
            <Dialog header="Configuración Variable" visible={showVarible.show} style={{ width: '50%', height: 'auto' }} onHide={() => hideVariable()}>
                <div>
                   <VariableForm setQuestionnaires={props.setQuestionnaires}
                                 questionnaires={props.questionnaires}
                                 refToast={refToast}
                                 formData={showVarible.form}
                                 formValues={props.formValues}
                                 hideVariable={hideVariable}
                                 setFormData={handleVariable}/>
                </div>
            </Dialog>)}

            {showEditOption.show && (
            <Dialog header='Editar Opción'
                    visible={showEditOption.show}
                    style={{ width: '50%', height: 'auto' }}
                    onHide={() => hideEditOption()}>
                <div>
                    <InputTextarea className='p-inputgroup chatbox inline-flex'
                                   rows={20}
                                   onChange={(e) => showEditOptionHandler(showEditOption.index, {...showEditOption, item: {...showEditOption.item}, name: e.target.value})}
                                   value={showEditOption.item.name === null ? '' : showEditOption.item.name}
                                   ref={el => refInputOptionPopup.current = el}
                    />
                    <div className='p-inputgroup'>
                        <Button label={'Guardar'}
                            onClick={() => {
                                handleChange(showEditOption.index, {name: 'name', value: showEditOption.item.name});
                                hideEditOption();
                            }}
                            className="p-button-raised ml-auto" style={{width: '100%'}}
                            icon={'pi pi-save'}
                        />
                        <span>&nbsp;</span>
                        <Button label={'Emojis'}
                                className="p-button-raised ml-auto"
                                style={{width: '20%', backgroundColor: '#607D8B'}}
                                onClick={() => showPickerHandler(refInputOptionPopup.current.selectionStart, null, refInputOptionPopup, true)}
                        >
                            <FontAwesomeIcon icon={"face-grin-wide"}/>
                        </Button>
                    </div>
                </div>
            </Dialog>)}

            {showEditSuccessMessage.show && (
            <Dialog header='Editar Mensaje de Cierre'
                    visible={showEditSuccessMessage.show}
                    style={{ width: '48%', height: 'auto' }}
                    onHide={() => hideEditSuccessMessage()}>
                <div>
                    <InputTextarea className='p-inputgroup chatbox inline-flex' rows={20}
                                   onChange={(e) => showEditSuccessMessageHandler(showEditSuccessMessage.index, {...showEditSuccessMessage, item: {...showEditSuccessMessage.item}, success_message: e.target.value})}
                                   value={showEditSuccessMessage.item.success_message === null ? '' : showEditSuccessMessage.item.success_message}
                                   ref={el => refInputSuccessMessagePopup.current = el}
                    />
                    <div className='p-inputgroup'>
                        <Button label={'Guardar'}
                            onClick={() => {
                                handleChange(showEditSuccessMessage.index, {name: 'success_message', value: showEditSuccessMessage.item.success_message});
                                hideEditSuccessMessage();
                            }}
                            className="p-button-raised ml-auto" style={{width: '100%'}}
                            icon={'pi pi-save'}
                        />
                        <span>&nbsp;</span>
                        <Button label={'Emojis'}
                                className="p-button-raised ml-auto"
                                style={{width: '20%', backgroundColor: '#607D8B'}}
                                onClick={() => showPickerHandler(refInputSuccessMessagePopup.current.selectionStart, null, refInputSuccessMessagePopup, true)}
                        >
                            <FontAwesomeIcon icon={"face-grin-wide"}/>
                        </Button>
                    </div>
                </div>
            </Dialog>)}

            {showEditQuestion.show && (
            <Dialog header='Editar Pregunta'
                    visible={showEditQuestion.show}
                    style={{ width: '50%', height: 'auto' }}
                    onHide={() => hideEditQuestion()}>
                <div>
                    <InputTextarea className='p-inputgroup chatbox inline-flex'
                                   rows={20}
                                   onChange={(e) => showEditQuestionHandler({...showEditQuestion, form: {...showEditQuestion.form}, question: e.target.value})}
                                   value={showEditQuestion.form.question === null ? '' : showEditQuestion.form.question}
                                   ref={refInputQuestionsPopup}
                    />
                    <div className='p-inputgroup'>
                        <Button label={'Guardar'}
                            onClick={() => {
                                updateQuestion({name: 'question', value: showEditQuestion.form.question})
                                hideEditQuestion();
                            }}
                            className="p-button-raised ml-auto" style={{width: '100%'}}
                            icon={'pi pi-save'}
                        />
                        <span>&nbsp;</span>
                        <Button label={'Emojis'}
                                className="p-button-raised ml-auto"
                                style={{width: '20%', backgroundColor: '#607D8B'}}
                                onClick={() => showPickerHandler(refInputQuestionsPopup.current.selectionStart, null, refInputQuestionsPopup, true)}
                        >
                            <FontAwesomeIcon icon={"face-grin-wide"}/>
                        </Button>
                    </div>
                </div>
            </Dialog>)}

            {showProviderWebhook.show && (
            <Fragment>
                <Dialog header="Configuración Webhook" visible={showProviderWebhook.show} style={{ width: '50%', height: 'auto' }} onHide={() => hideProviderWebhook()}>
                    <div>
                        <QuestionnaireProviderWebhook
                            formValues={props.formValues}
                            visibleSidebar={visibleSidebar}
                            setVisibleSidebar={setVisibleSidebar}
                            questionnaires={props.questionnaires}
                            setShowAttributes={setShowAttributes}
                            index={showProviderWebhook.index} refToast={refToast} formData={showProviderWebhook.form}
                            hideProviderWebhook={hideProviderWebhook} setFormData={handleProviderWebhook}
                        />
                    </div>
                </Dialog>
                <Sidebar visible={visibleSidebar} closeOnEscape={false} modal={false} dismissable={false} fullScreen={false} position="right" baseZIndex={1000000} onHide={() => setVisibleSidebar(false)}>
                    <h3 style={{ fontWeight: 'normal' }}>Variables Disponibles</h3>
                    <ScrollPanel style={{ height: '85vh' }}>
                        {showAttributes}
                    </ScrollPanel>
                </Sidebar>
            </Fragment>
            )}

            {showAiAssistanOptionsState.show && (
                <Fragment>
                    <Dialog header="Configuración Ai Assistant" visible={showAiAssistanOptionsState.show} style={{ width: '50%', height: 'auto' }} onHide={() => hideProviderAiAssistantHandler()}>
                        <QuestionnaireProviderAiAssistants
                            index={showAiAssistanOptionsState.index}
                            refToast={refToast}
                            hideProviderAiAssistantHandler={hideProviderAiAssistantHandler}
                            formData={showAiAssistanOptionsState.form}
                            setFormData={handleSelectedAiAssistant}
                        />
                    </Dialog>
                </Fragment>
                )}
            </div>
    )

    if (loading){
        return null
    } else {
        return form;
    }

}

export default BotResponseForm;