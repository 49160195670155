import React, { useState, useEffect } from 'react';
import { MRTURNO } from '../../shared/ProviderScheduledType';
import MrTurnoForm from './ProviderAction/MrTurnoForm';

const ProviderScheduledForm = (props) => {
    const [providerScheduledAction, setProviderScheduledAction] = useState(null);

    useEffect(() => {
        if (props.match && props.match.params && props.match.params.action) {
            const action = props.match.params.action.toUpperCase();
            if (action === 'MRTURNO') {
                setProviderScheduledAction(MRTURNO);
            }
        }
    }, [props.match]);

    return (
        <>
            {providerScheduledAction === MRTURNO && <MrTurnoForm {...props}/>}
        </>
    );
}

export default ProviderScheduledForm;