import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import MessagePreview from '../MessagePreview';
import './Message.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { Menu } from "primereact/menu";
import { MessageContext } from "../../../context/messageContext";
import Picker from "emoji-picker-react";
import { Dialog } from "primereact/dialog";
import MessageReactions from "../MessageReaction/MessageReactions";
import { Checkbox } from "primereact/checkbox";
import { internalMarkAsStar } from '../../../utils/messenger';


export default function Message(props) {
    const {
        data,
        isMine,
        startsSequence,
        endsSequence,
        showTimestamp,
        animated,
        index,
        handlePlay,
        audioRefs
    } = props;

    const [showOptions, setShowOptions] = useState(false);
    const [showOptionsGateway, setOptionsGateway] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [optionItems, setOptionsItems] = useState([]);
    const menu = useRef(null);
    const friendlyTimestamp = moment(data.created).format('LLLL');
    const time = moment(data.created).format('HH:mm');
    const date = moment(data.created).format('DD/MM/YY');
    const fulltime = time.concat('hs').concat(' - ').concat(date);
    const ackIcon = data.potential ? 'clock' : !data.seen ? 'check-double' : 'check-double';
    const { selectedForwardMessages, forward, updateMessageContext, updateMessageReaction, updateForward,
        updateSelectedForwardMessages } = useContext(MessageContext);
    const [animateMessage, setAnimateMessage] = useState(false);
    let isUser = localStorage.getItem('username') === data?.username
    let isGroup = data?.group_id !== null;

    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    useEffect(() => {
        let items = []
        items.push(
            {
                label:
                    <i>
                        <FontAwesomeIcon icon={"fa-regular fa-reply"} style={{ width: '1.4em', height: '1.4em' }} />
                        <span className="menu-font-awesome-icon"> Responder </span>
                    </i>,
                command: (e) => getMessageData()
            },
            // {
            //    label:
            //    <i>
            //        <FontAwesomeIcon icon={"fa-regular fa-share-from-square"} style={{width: '1.4em', height: '1.4em'}}/>
            //        <span className="menu-font-awesome-icon"> Reenviar </span>
            //    </i>,
            //    command: (e) => onReplyHandler()
            // },
            {
                label:
                    <i>
                        <FontAwesomeIcon icon={"fa-regular fa-face-smile"} style={{ width: '1.4em', height: '1.4em' }} />
                        <span className="menu-font-awesome-icon"> Reacción </span>
                    </i>,
                command: (e) => setShowPicker(!showPicker)
            },
            {
                label:
                    <i>
                        <FontAwesomeIcon icon={"fa-regular fa-star"} style={{ width: '1.4em', height: '1.4em' }} />
                        <span className="menu-font-awesome-icon"> {['star'].includes(data?.message_state?.name) ? 'No Favorito' : 'Favorito'}</span>
                    </i>,
                command: (e) => onStarHandler()
            }
        );
        setOptionsGateway(true)

        setOptionsItems(items);

    }, [data])

    useEffect(() => {
        if (!animateMessage) {
            setAnimateMessage(animated)
        } else {
            setTimeout(() => {
                setAnimateMessage(animated)
            }, 3000);
        }
    }, [animated])

    const onReplyHandler = () => {
        let _selected_forwared_messages = [...selectedForwardMessages]
        _selected_forwared_messages.push(data.id)
        updateSelectedForwardMessages(_selected_forwared_messages)
        updateForward(true)
    }

    const onStarHandler = () => {
        internalMarkAsStar(data.id);
    }

    const getDescription = (fulltime) => {
        let description = ''
        if (isMine) {
            description = data?.message_type?.description + ' - ' + fulltime;
        } else {
            description = fulltime
        }
        return description
    }

    const star = (
        ['star'].includes(data?.message_state?.name) && (
            <small>
                <FontAwesomeIcon icon={'fa-regular fa-star'}
                    size={"4x"}
                    style={{ width: '0.4em', height: '0.4em' }} />
                &nbsp;&nbsp;
            </small>
        )
    )
    const description = data.author_name !== null ? (data.author_name.concat(' - ').concat(fulltime)) : getDescription(fulltime);
    const note = (
        <div className='center mt-2 message-note'>
            <div className="bubble-container">
                <div className="bubble">
                    <div className="p-button-raised">

                        <FontAwesomeIcon icon={"fa-regular fa-comment-dots"} color={'grey'}
                            size={"4x"}
                            style={{ width: '0.4em', height: '0.4em' }} />
                        &nbsp;&nbsp;
                        {data.message}

                    </div>
                    <p className="" style={{ color: "#4a4141" }}>
                        <small>{description}</small>
                    </p>
                </div>
            </div>
        </div>
    );

    const note_reminder = () => {
        const date_now = moment(new Date()).format('MM/DD/YYYY')
        let note_reminder_date = moment(moment(data.note_reminder.reminder_date).format('MM/DD/YYYY'))

        let active_label = note_reminder_date.isSameOrAfter(date_now) && !data.note_reminder.seen ? 'activo' : 'inactivo';
        let color = note_reminder_date.isSameOrAfter(date_now) && !data.note_reminder.seen ? 'orange' : 'grey';

        let tooltip_label = (
            <p>Recodatorio {active_label} : {moment(data.note_reminder.reminder_date).format('DD-MM-YYYY HH:mm')}</p>
        )

        let tooltip_id = 'note-reminder-bell' + data.id
        return (
            <div className='center mt-2 note-reminder'>
                <div className="bubble-container">
                    <div className="bubble">
                        <div className="p-button-raised">
                            <FontAwesomeIcon icon={"fa-regular fa-bell"} color={color} data-tip data-for={tooltip_id}
                                size={"4x"}
                                style={{ width: '0.4em', height: '0.4em' }} />
                            &nbsp;&nbsp;
                            {data.emoji}
                        </div>
                        <p className="" style={{ color: "#4a4141" }}>
                            <small>{description}</small>
                        </p>
                    </div>
                </div>
                {!isMobile() &&
                    <ReactTooltip id={tooltip_id} place="top" effect="solid" key={color}>
                        {tooltip_label}
                    </ReactTooltip>
                }
            </div>
        )
    };

    const ack = (
        isMine && (
            <small>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={data.read ? 'check-double' : ackIcon} spin={ackIcon === "clock"}
                    color={data.read ? '#028dc9' : '#4a5960'}
                    size={"4x"}
                    style={{ width: '0.4em', height: '0.4em' }} />
            </small>
        )
    )

    const getMessageData = () => {
        let message_context = {
            'id': data.id,
            'body': data.message,
            'mime_type': data.mimetype
        }
        updateMessageContext(message_context)
    }

    const toggleMenu = (e) => {
        menu.current.toggle(e);
    }

    const showIconOptions = showOptions && showOptionsGateway && !forward ? (
        <Fragment>
            <Menu
                model={optionItems} popup ref={menu}
                onHide={(e) => setShowOptions(false)}
            />
            <button className="p-link" onClick={(e) => toggleMenu(e)}>
                <FontAwesomeIcon icon={"chevron-down"} style={{ position: 'relative', bottom: '12px' }} color={'#515151'} size={"sm"} />
            </button>
        </Fragment>
    ) : null;

    const onEmojiClick = (event, emojiObject) => {
        event.preventDefault();
        updateMessageReaction({
            message_id: data.id,
            emoji: emojiObject.emoji
        })
        setShowPicker(false);
    };

    const getEmojisForm = () => {
        return (
            <Dialog header="Emojis" visible={showPicker} style={{ width: '40vw' }} onHide={() => setShowPicker(false)}>
                <div>
                    {showPicker && (<Picker
                        disableSearchBar={true}
                        pickerStyle={{ width: "100%", zIndex: "0" }}
                        onEmojiClick={onEmojiClick} />)}
                </div>
            </Dialog>
        )
    }

    const messageReactions = data?.reactions?.length > 0 ? (
        <Fragment>
            <MessageReactions data={data.reactions} body={data.emoji} id={data.id} contact={data.author_name} />
        </Fragment>
    ) : null;

    const checkboxForwards = forward ? (
        <div className={'forward-message'}>
            <Checkbox name={'forward'} value={data.id} onChange={props.onMessageForwardChange}
                checked={selectedForwardMessages.some((item) => item === data.id)}
                disabled={data.mimetype !== null && data.message_media_id === null} />
        </div>
    ) : null;

    const _animateMessage = animateMessage ? (isMine ? 'bubble-container-animation-in' : 'bubble-container-animation-in') : '';

    const message = (
        <div className={[
            'flex message',
            `${isMine ? 'mine' : data?.message_type?.name === 'ciphertext' ? 'retry' : 'other'}`,
            `${data.message_type?.name}`,
            `${data.message_state?.name}`,
            `${startsSequence ? 'start' : ''}`,
            `${endsSequence ? 'end' : ''}`,
            `${data.failed ? 'failed' : ''}`,
        ].filter(x => x !== '').join(' ')}>
            {
                showTimestamp &&
                <div className='center mt-2 date'>
                    <div className="date-container">
                        <div className="timestamp">
                            {friendlyTimestamp}
                        </div>
                    </div>
                </div>
            }
            {getEmojisForm()}

            <div className={[
                'bubble-container',
                `${selectedForwardMessages.filter(item => item === data.id)[0] !== undefined ? 'message-selection-highlight' : ''}`
            ].filter(x => x !== '').join(' ')}
                onMouseEnter={() => setShowOptions(true)}
                onMouseLeave={() => setShowOptions(false)}>
                {checkboxForwards}
                <div className={['bubble',
                    `${isMine ? 'ml-auto' : ''}`,
                    `${_animateMessage}`].filter(x => x !== '').join(' ')}
                    title={friendlyTimestamp} >
                    <div className={''}>
                        <MessagePreview index={index} data={data} options={showIconOptions} isMine={isMine} handlePlay={handlePlay} audioRefs={audioRefs} />
                    </div>
                    <p className={`${isMine ? 'flex justify-content-end flex-wrap"' : 'flex justify-content-start flex-wrap"'}`} style={{ color: "#4a4141" }}>
                        {star}
                        <small>{description}</small>
                        {ack}
                    </p>
                </div>
                {messageReactions}
            </div>
        </div>
    );

    const renderMessage = (message_type) => {
        let form = null;
        if (message_type === 'internal_note') {
            if (isUser && !isGroup) return form = note;
            if (isGroup) return form = note;
            else return form = null;
        } else if (message_type === 'note_reminder') {
            form = note_reminder();
        } else {
            form = message;
        }
        return form
    }

    return (
        renderMessage(data.message_type?.name)
    );
}