import React, {useEffect, useState, Fragment} from 'react';
import {InputText} from "primereact/inputtext";
import {Dropdown} from 'primereact/dropdown';
import {PHONE, EMAIL, ADDRESS} from "../shared/contactTypes";
import {Message} from "primereact/message";

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/InputContact.css'
import {lookup, countries} from "country-data";
import {InputSwitch} from "primereact/inputswitch";

const InputContact = props => {
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [getCountries, setCountries] = useState([]);

    useEffect(() => {
        let contact_type = getContactType(props.contact_type_id);

        let error = props.error;

        if (error !== undefined){
            if (contact_type === 'phone'){
                let errors = error.split(' {{CLIENT_ID}} ');
                setErrorMessage(errors[0]);
                if (errors.length > 1){
                    let error =  null;
                    error = (
                        <div>
                            <span>{errors[0]}</span>
                            <br></br>
                            <a className='link' target="_blank" href={"#/audience/" + errors[1] + '/edit'}>Mostrar Número Existente </a>
                        </div>
                    )
                    setErrorMessage(error)
                }
            } else {
                setErrorMessage(error);
            }
        }
    }, [props.contact_type_id, props.error]);

    useEffect(() => {
        let countries_parsed = [];
        countries.all.map(country => {
            let countries_json = {
                'value': country.alpha2,
                'label': country.name,
                'icon': country.emoji
            };

            if (countries_parsed.filter(x => x.label === country.name).length === 0){
                countries_parsed.push(countries_json);
            }
            return countries_parsed;

        });
        setCountries(countries_parsed);
    }, []);

    const getContactType = optionValue => {
        let contact_type = null;

        switch(optionValue) {
            case PHONE:
                contact_type = 'phone';
                break;
            case EMAIL:
                contact_type = 'email';
                break;
            case ADDRESS:
                contact_type = 'address';
                break;
            default:
                contact_type = null;
                break;
        }
        return contact_type;
    };

    const inputChangeAddressHandler = (event, field) => {
        let field_key = Object.keys(field)[0];
        let value = props.value;

        if  (canParseJson(value)) {
            value = JSON.parse(props.value);
        }

        if (typeof(value) === 'string' || typeof(value) === 'undefined'){
            value = {
                city: "",
                name: "",
                state: "",
                postal_code: "",
                line_1: "",
                country: "AR"
            };
            value[field_key] = event.target.value;
        }

        if (value[field_key] !== undefined){
            value[field_key] = event.target.value;
        }

        props.onChange({value: value});

    };

    const inputChangeHandler = (event) => {
        props.onChange({value: event.target.value});
    };

    const inputChangePhoneHandler = (phone_valid, phone_national, country_obj, phone_international) => {
        let phone = {
            country_prefix: null,
            value: null
        };

        phone['value'] = phone_national.replace(/\D/g,'');
        phone['country_iso'] = country_obj.iso2;
        phone['country_prefix'] = country_obj.dialCode;
        if (phone.validate_contact === undefined){
            phone['validate_contact'] = true
        }


        props.onChange({value: phone});
    };

    const inputValidateContactHandler = (validate_contact) => {
        let phone = props.value;

        phone['validate_contact'] = validate_contact.target.value;

        props.onChange({value: phone});
    };

    const inputChangePhoneSelectFlagHandler = (phone_national, country_obj, phone_international, phone_valid) => {
        inputChangePhoneHandler(phone_valid, phone_national, country_obj, phone_international);
    };

    const getCountryIso = (country_prefix) => {
        let prefix = '+'+country_prefix;
        let country_iso = lookup.countries({countryCallingCodes: prefix})[0];

        if (country_iso === undefined){
            country_iso = 'ar';
        } else {
            country_iso = country_iso['alpha2'].toLowerCase();
        }

        return country_iso;
    };

    const cleanValueHandler = (value) => {
        props.onChange({value: value});
    };

    const canParseJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const countryTemplate = (option) => {
        if(!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <span style={{display:'inline-block',margin:'5px 0 0 5px'}} width="24">{option.icon}</span>
                    <span style={{float:'right', margin:'.5em .25em 0 0'}}>{option.label}</span>
                </div>
            );
        }
    };

    const getContactForm = contactType => {
        let form = null;
        let validate_phone = new RegExp("^[0-9]*$");
        let validate_address = new RegExp("^\\{");
        let value = props.value;
        let clean = false;

        let elementClass = "";
        if (errorMessage === undefined){
            elementClass = "col-12 md-12"
        }

        switch (contactType) {
            case 'phone':
                let country_iso = getCountryIso(props.country_prefix);
                let phone_number = {
                    value: value,
                    country_prefix: props.country_prefix,
                    country_iso: country_iso,
                    validate_contact: props.validate_contact
                };

                if (typeof value === 'object'){
                    phone_number.value = value.value;
                    phone_number.country_prefix = value.country_prefix;
                    phone_number.country_iso = value.country_iso;
                    phone_number.validate_contact = value.validate_contact;
                }

                form = (
                    <div className="grid">
                            <div className="col-12 md-12">
                                <label htmlFor="active" className={"mr-2"}>Validar Contacto</label>
                                <InputSwitch id="active" disabled={phone_number.value === ''} checked={phone_number.validate_contact}
                                             onChange={(e) => inputValidateContactHandler(e)}/>
                            </div>
                            <div className="col-12 md-12">
                                <IntlTelInput
                                    preferredCountries={['ar']}
                                    separateDialCode={true}
                                    formatOnInit={false}
                                    value={phone_number.value}
                                    inputClassName="col-12 md-12 intl-tel-input-custom"
                                    defaultCountry={phone_number.country_iso}
                                    onPhoneNumberChange={
                                        (phone_valid, phone_national, country_obj, phone_international) =>
                                            inputChangePhoneHandler(phone_valid, phone_national,
                                                country_obj, phone_international)}
                                    onSelectFlag={
                                        (phone_national, country_obj, phone_international, phone_valid) =>
                                            inputChangePhoneSelectFlagHandler(phone_national, country_obj,
                                                phone_international, phone_valid)}
                                    nationalMode={false}
                                    />
                            </div>
                            {errorMessage !== undefined &&
                                (<div className="col-12 md-12">
                                    <small style={{color: 'red'}}>
                                        {errorMessage}
                                    </small>
                                </div>)
                            }
                        </div>

                );
                break;
            case 'email':
                if (typeof(value) === 'object'){
                    value = '';
                    clean = true;
                }
                if (value !== ''){
                    if (validate_address.test(value)){
                        value = '';
                        clean = true;
                    }
                    if (validate_phone.test(value)){
                        value = '';
                        clean = true;
                    }
                }

                if (clean){
                    cleanValueHandler(value);
                }

                form = (
                    <div className="grid form-group">
                        <div className={elementClass || "col-12 md-8"}>
                            <span className="p-float-label">
                                <InputText
                                    keyfilter="email"
                                    value={value}
                                    onChange={(e) => inputChangeHandler(e)}
                                    className={errorMessage && 'p-error'}
                                />
                                <label htmlFor="input">Email</label>
                            </span>
                            {errorMessage !== undefined &&
                            (<div className="col-12 md-12">
                                <small style={{color: 'red'}}>
                                    {errorMessage}
                                </small>
                            </div>)}
                        </div>
                    </div>
                );
                break;
            case 'address':
                if  (canParseJson(value)) {
                    value = JSON.parse(props.value);
                }
                let errorMessages = {};
                if (errorMessage !== undefined){
                    errorMessages = errorMessage.map(error => {
                        let field = Object.keys(error)[0];
                        return errorMessages = {
                            ...errorMessages,
                            [field]: error[field]
                        }
                    });
                }

                if (typeof(value) === 'string' || typeof(value) === 'undefined'){
                    value = {
                        city: "",
                        name: "",
                        state: "",
                        postal_code: "",
                        line_1: "",
                        country: "AR"
                    };
                    cleanValueHandler(value);
                }

                let city = value.city !== undefined ? value.city : '';
                let state = value.state !== undefined ? value.state : '';
                let postal_code = value.postal_code !== undefined ? value.postal_code : '';
                let street = value.line_1 !== undefined ? value.line_1 : '';

                form = (
                    <Fragment>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <Dropdown value={value.country} options={getCountries}
                                              onChange={(e) => inputChangeAddressHandler(e, {country: value.country})}
                                              itemTemplate={countryTemplate} filter={true}
                                              filterPlaceholder="Seleccione un País" filterBy="label,value"/>
                                </div>
                                {errorMessages['country'] !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessages['country']}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={city}
                                            onChange={(e) => inputChangeAddressHandler(e, {city: city})}
                                        />
                                        <label htmlFor="input">Ciudad</label>
                                    </span>
                                </div>
                                {errorMessages['city'] !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessages['city']}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={state}
                                            onChange={(e) => inputChangeAddressHandler(e, {state: state})}
                                            keyfilter={"hidden"}
                                        />
                                        <label htmlFor="input">Provincia</label>
                                    </span>
                                </div>
                                {errorMessages['state'] !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessages['state']}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={postal_code}
                                            onChange={(e) => inputChangeAddressHandler(e, {postal_code: postal_code})}
                                        />
                                        <label htmlFor="input">Código Postal</label>
                                    </span>
                                </div>
                                {errorMessages['postal_code'] !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessages['postal_code']}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={street}
                                            onChange={(e) => inputChangeAddressHandler(e, {line_1: street})}
                                        />
                                        <label htmlFor="input">Calle</label>
                                    </span>
                                </div>
                                {errorMessages['name'] !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessages['line_1']}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                    </Fragment>

                );
                break;
            default:
                form = null;
                break;
        }
        return form;
    };


    let optionValue = props.contact_type_id;
    let contact_type = getContactType(optionValue);
    let input_field = getContactForm(contact_type);

    let input_contact = (
        input_field
    );

    return input_contact;

};

export default InputContact;