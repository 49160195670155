import React from 'react';
import {Route,Redirect} from 'react-router-dom';
import * as roles from './shared/roles';

export const SecretRoute = ({ component: Component, allowed, ...rest}) => {

    let role = roles.switchRole();

    if (allowed.includes(role)){
        return (
            <Route {...rest} render={(props) => (
                localStorage.getItem('token') !== null
                    ? <Component {...props} allowed={role} />
                    : <Redirect to='/' />
            )} />
        );
    } else {
        return <Redirect to='/' />;
    }

};
