import React from 'react';
import './ToolbarButton.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ToolbarButton(props) {
    const { icon, name, onClick, color, shake, children, style, className } = props;

    return (
      <span data-tip={name} className={className ? className : "toolbar-button fa-layers fa-fw"} style={{color:color}} onClick={onClick}>
        <FontAwesomeIcon icon={icon} size='xs' style={style} shake={shake} />
          {children}
      </span>
    );
}
