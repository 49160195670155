import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {switchRole} from '../../shared/roles';
import StripedList from '../../UI/StripedList';
import { Dialog } from 'primereact/dialog';

const CLIENT_INFORMATION = [
    {name:'{ClientName}', description: 'Nombre del cliente.'},
    {name:'{ClientLastname}', description: 'Apellido del cliente.'},
    {name:'{SanitizedClientName}', description: 'Nombre del deudor sanitizado.'},
    {name:'{SanitizedClientLastname}', description: 'Apellido del deudor sanitizado.'},
    {name:'{ClientDocumentNumber}', description: 'Documento del cliente.'},
    {name:'{ClientCuil}', description: 'Cuil del cliente.'},
    {name:'{Variable1}', description: 'Información extra 1.'},
    {name:'{Variable2}', description: 'Información extra 2.'},
    {name:'{Variable3}', description: 'Información extra 3.'},

];

const PROVIDER_INFORMATION = [
    {name:'{ProviderName}', description: 'Nombre de la empresa.'},
    {name:'{ProviderAddress}', description: 'Dirección de la empresa.'},
    {name:'{ProviderEmail}', description: 'Email de la empresa.'},
    {name:'{ProviderPhone}', description: 'Teléfono de la empresa.'},
];

const TemplateVariables = ({ onClose, setShowDialog, showDialog }) => {

    let variables = [...CLIENT_INFORMATION];
    if(['supervisor'].includes(switchRole())){
        if (process.env.REACT_APP_PAGE === 'pip-app'){
            variables = [
                ...variables,
                ...PROVIDER_INFORMATION
            ]
        }
    }

    const component = variables.map((reference) => {
        return (
            <Fragment>
                <div className={"template-value"}>
                    <CopyToClipboard text={reference.name}>
                        <a href={''} onClick={(event) => {event.preventDefault()}}
                           className='btn-actions' style={{padding: '10px 1px 0px 10px', borderRadius: '30px'}}>
                            <span>
                                <FontAwesomeIcon icon={'fa-regular fa-copy'} size="sm" color={'#FD971F'} style={{width: '1.5em', height: '1.5em'}}/>
                            </span>
                        </a>
                    </CopyToClipboard>
                    <b className='pl-3'>{reference.name}:</b> {reference.description} <br/>
                </div>
            </Fragment>
        );
    });

    const header = (        
        <div className="custom-modal-header">
            <div className="custom-modal-header-title">
            <span className="custom-tag-get-back" onClick={onClose}>
                <FontAwesomeIcon icon={'arrow-left'}/>
            </span>
            Personalizar
            </div>
        </div>
    );
    const body = (
        <div className='scrollable' style={{height: "100%"}}>
            <StripedList items={component}/>
        </div>
    );

    return (
        <Dialog header={header} visible={showDialog} style={{ width: 'auto', minWidth: '50vw' }} onHide={() => setShowDialog(false)}>
            <div className="custom-modal-body">
                {body}
            </div>
        </Dialog>
    );
    
}

export default TemplateVariables;