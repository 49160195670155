import React  from 'react';
import Resources from '../Resources/Resources';

const BackgroundTasksBulk = (props) => {

    const resource = {
        title: 'Tareas',
        url: '/tasks/bulk',
        colsHeader: [
            { field: 'task', header: 'Tarea'},
            { field: 'success', header: 'Exitosa', checkbox: true},
            { field: 'user', header: 'Usuario'},
            { field: 'created', header: 'Fecha', datetime: true},
        ],
        action: {
        },
        urlTable: [
            '/tasks',
        ]
    }


    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       action={resource.action} urlTable={resource.urlTable} />);
}

export default BackgroundTasksBulk;
