import React from 'react';
import moment from "moment";
import './DateRange.css';

export default function DateRange(props) {
    const formdate = (date) => {
        if (moment(date).format('YYYY/MM/DD') === moment(new Date(Date.now())).format('YYYY/MM/DD')) {
            return 'Hoy'
        } else if (moment(date).format('YYYY/MM/DD') === moment(new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 1)).format('YYYY/MM/DD')) {
            return 'Ayer'
        } else if (moment(date).format('YYYY/MM/DD') === moment(new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 2)).format('YYYY/MM/DD')) {
            return 'AnteAyer'
        } else {
            return moment(date).format('LL')
        }
    }

    return (
        <div className='center mt-2 date'>
        <div className="date-container">
            <div className="date">
                <span>{formdate(props.date)}</span>
            </div>
        </div>
        </div>
    );
}