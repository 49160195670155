import React, {Component} from 'react';
import ResourceFormGeneric from '../../Forms/ResourceFormGeneric';
import NotificationHelper from "./NotificationHelper";

class NotificationForm extends Component {

    state = {
        uri: '/notifications',
        resource_id: this.props.match.params.id,
        action: this.props.match.params.id ? 'edit' : 'create',
        title: 'Notificación',
        fields: [
            {
                name: 'channel_id',
                elementType: 'dropdown',
                elementConfig: {
                    options: null,
                    disabled: this.props.match.params.id ? true : false,
                    placeholder: 'Seleccione un tipo de canal'
                },
                value: '',
                traduction: 'Canal',
                requestResource: {
                    request: {
                        url: '/channels/available',
                        params: {},
                    },
                    format: {
                        value: 'channel_id',
                        label: 'description'
                    }
                }
            },
            {
                name: 'title',
                elementType: 'text',
                elementConfig: {},
                value: '',
                traduction: 'Título'
            },
            {
                name: 'notification_type',
                elementType: 'notificationBody',
                elementConfig: {},
                dependence_of: [
                    'channel_id',
                    'notification_custom_tags'
                ],
                colsClass: {
                    input: "col-12 md-12"
                },
                value: {
                    subject: '',
                    body: '',
                    template_uuid: ''
                }
            },
            {
                name: 'enabled',
                elementType: 'switch',
                elementConfig: {
                    onLabel: "SI",
                    offLabel: "NO",
                },
                value: false,
                traduction: 'Habilitado'
            },
            {
                name: 'notification_custom_tags',
                elementType: 'multiSelect',
                elementConfig: {
                    filter: {
                        value: 'id',
                        label: 'name'
                    },
                    options: null,
                    fieldValue: "custom_tag_id",
                    placeholder: 'Seleccione los Tags a utilizar',
                    selectedItemsLabel: '{0} tags seleccionados',
                    maxSelectedLabels: 5,
                    url: '/custom_tags',
                    filters: [
                        {
                            name: 'active',
                            op: 'eq',
                            val: true
                        }
                    ],
                },
                value: {
                    custom_tag_id: ''
                },
                traduction: 'Tags'
            },
            {
                name: 'frecuency',
                elementType: 'frecuency',
                elementConfig: {
                    elementClass: 'col-12 md-12',
                },
                value: {
                    minute: 0,
                    hour: 0,
                    day_month: '*',
                    month: '*',
                    day_week: '*',
                    type: null,
                    cron_expression: null
                }
            }
        ],
        loading: false
    };

    setFields = (fields) => {
        this.setState({fields: fields})
    };

    render() {
        let {fields} = this.state;

        const form = (<ResourceFormGeneric
            setFields={this.setFields}
            fields={fields}
            url={this.state.uri}
            resource_id={this.state.resource_id}
            resource_traduction={this.state.title}/>);

        return (
            <div className="grid p-fluid">
                <div className="col-12 p-lg-12">
                    <div className="card card-w-title">
                        <div className="grid p-fluid">
                            <div className="col-11 md-10 p-lg-9">
                                <h1><strong>{this.state.title}</strong></h1>
                                {!this.state.loading && form}
                            </div>
                            <div className="col-1 md-2 p-lg-3">
                                <NotificationHelper/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotificationForm;

