import React, {Fragment, useRef, useContext, useEffect, useState} from 'react';
import { Toast } from 'primereact/toast';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import ApiLoan from "../../../service/ApiLoanService";
import InputField from "../../Forms/InputField";

import {debounce, throttle} from "throttle-debounce";
import axios from "axios";
import Avatar from "react-avatar";

import './MessageForwardContact.css';
import {MessageContext} from "../../../context/messageContext";
import { removeNumbersAndSpecialCharacters } from '../../../utils/utils';

const MessageForwardContact = (props) => {
    const [paginating, setPaginating] = useState(false);
    const [searchContact, setSearchContact] = useState(null);
    const [clientsState, setClientsState] = useState({
        clients: [],
        filteredClients: [],
        searchLoading: false,
    });

    const [lastClientReached, setLastClientReached] = useState(false);
    const scrollContainer = useRef();
    const {updateSelectedForwardContact} = useContext(MessageContext)


    useEffect(() => {
        setPaginating(true)
        getClients()
    }, [])

    useEffect(() => {
        if (searchContact === ''){
            setSearchContact(null)
        }
    }, [searchContact, clientsState.clients])

    useEffect(() => {
        const CancelTokenFilterRequest = axios.CancelToken;
        const filterRequest = CancelTokenFilterRequest.source();

        if (searchContact) {
            if (searchContact.length < 5) {
                getFilteredClientsThrottled.current(1, searchContact, filterRequest, {...clientsState, page: 1});
            } else {
                getFilteredClientsDebounced.current(1, searchContact, filterRequest, {...clientsState, page: 1});
            }
        }
        else {
            setClientsState((prevState) => {
                return {
                    ...prevState,
                    filteredClients: [],
                }
            });
        }

        return () => {
            filterRequest.cancel()
        }
    }, [searchContact]);

    const handleScroll = () => {
        if(scrollContainer.current.scrollHeight === scrollContainer.current.scrollTop + scrollContainer.current.clientHeight && !lastClientReached && !paginating){
            const lastClient = clientsState.clients[clientsState.clients.length - 1];

            if(searchContact === null){
                setPaginating(true);
                getClients(lastClient.client_id);
            }
        }
    }

    useEffect(() => {
        if (scrollContainer.current){
            scrollContainer.current.addEventListener('scroll', handleScroll);
            return () => {
                if (scrollContainer.current){
                    scrollContainer.current.removeEventListener('scroll', handleScroll);
                }
            }
        }
    },[clientsState.clients, clientsState.filteredClients, scrollContainer, paginating])

    const getClients = (last_client_id) => {
        let url = '/recipients';
        let apiService = new ApiLoan();

        let params = {
            'url': url,
            'results_per_page': 15
        }

        if (last_client_id){
            params['from_id'] = last_client_id
        }

        apiService.getResources(params).then(response => {
            const clients = response.data.objects.map((client) => {
                return {
                    client: {
                        id: client.id,
                        name: client.firstname + ' ' + client.lastname,
                        client_custom_tags: [],
                        contacts: client.contacts,
                    },
                    client_id: client.id,
                    id: null
                }
            })

            setClientsState((prevState) => {
                let data = {
                    ...prevState,
                    clients: [
                        ...prevState.clients,
                        ...clients
                    ]
                }

                if (last_client_id === undefined){
                    data['filteredClients'] = [
                        ...clients
                    ]
                }
                return data
            });

            if(response.data.objects.length === 0){
                setLastClientReached(true);
            }

            setPaginating(false)
        }).catch(() => { return });
    }

    const getFilteredClients = (page, searchQuery, conversationFilterRequest= null, currentState) => {
        const apiService = new ApiLoan();
        let params = {
            search_query: searchQuery,
            results_per_page: 10,
            cancelToken: conversationFilterRequest ? conversationFilterRequest.token : null
        }

        apiService.getResources({
            url: '/recipients',
            ...params
        }).then(response => {
            const filteredClients = response.data.objects.map((client) => {
                return {
                    client: {
                        id: client.id,
                        name: client.firstname + ' ' + client.lastname,
                        client_custom_tags: [],
                        contacts: client.contacts,
                    },
                    client_id: client.id,
                    id: null
                }
            });

            setClientsState({
                ...currentState,
                filteredClients: filteredClients,
            })
        }).catch(() => { return });
    };

    const getFilteredClientsThrottled = useRef(throttle(600, getFilteredClients));
    const getFilteredClientsDebounced = useRef(debounce(500, getFilteredClients));

    let typeList = searchContact === null ? clientsState.clients : clientsState.filteredClients;

    let clientList = typeList.map(data => {
        return (
            <Fragment key={data.client_id}>
                <div className="field col">
                    <div className={'flex'}>
                        <Avatar maxInitials={1} className="conversation-photo-forward" name={removeNumbersAndSpecialCharacters(data.client.name)}
                                round={true} size="50"/>
                        <div className="conversation-info-forward">
                            <h1 className="conversation-title-forward">
                                {data.client.name}
                            </h1>
                        </div>
                        <div className="ml-auto">
                            <button className="p-link" onClick={(e) => updateSelectedForwardContact(data.client_id)}>
                                <FontAwesomeIcon icon={"share"} color={'#54656f'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    })

    const toast = useRef(null);

    return (
        <Fragment>
            <Toast ref={toast} />
            <div className={"p-fluid formgrid grid"}>
                <div className="field col">
                    <InputField type={'text'} value={searchContact === null ? '' : searchContact} traduction={'Buscar'}
                                elementClass={'col-12 md-12'} onChange={(e) => setSearchContact(e.target.value)}/>
                </div>
            </div>
            <div className='scrollable' style={{height: "30vw"}} ref={scrollContainer}>
                <div className={`p-fluid`}>
                    {clientList}
                </div>
            </div>
            <div style={{height:'20px'}}></div>
        </Fragment>
    );
}

export default MessageForwardContact;
