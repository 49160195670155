import React, { useState, useEffect, Fragment, useRef } from 'react';
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses, sidebarClasses } from 'react-pro-sidebar';
import ReactTooltip from "react-tooltip";

const AppMenu = (props) => {
    const [items, setItems] = useState(null);
    let colorDark = "#303030";
    let colorHoverDark = "#3d3d3d";
    let color = props.colorMode === 'dark' ? "#2ed4b0e5" : "#19418BE5";

    useEffect(() => {

        if (props?.model !== null){
            setItems(props?.model[0]?.items);
        }

    }, [props.model]);

    const hexToRgba = (hex, alpha) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const modeDark = {
      icon: {
        color: color,
        [`&.${menuClasses.disabled}`]: {
          color: color,
        },
      },
      SubMenuExpandIcon: {
        color: color,
      },
      button: {
        [`&.${menuClasses.disabled}`]: {
          color: colorDark,
        },
        '&:hover': {
          backgroundColor: hexToRgba(colorHoverDark, 1),
          color: colorHoverDark,
        },
      },
    };

    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    const isClick = (click) => {
        props.onSidebarClick(click);
    };

    const renderMenuItem = (menuItem, index) => {
        if (menuItem.items === undefined) {
          return (
            <MenuItem
              key={`${menuItem.slug}-${index}`}
              onClick={() => isClick(false)}
              data-tip
              data-for={menuItem.slug}
              component={<NavLink to={menuItem?.to}/>}
              icon={<FontAwesomeIcon icon={menuItem.icon} size={"lg"} color={color} />}
            >
              {menuItem?.label}
            </MenuItem>
          );
        } else {
          return (
            <SubMenu
              key={`${menuItem.slug}-${index}`}
              onClick={() => isClick(true)}
              data-tip
              data-for={menuItem.slug}
              icon={<FontAwesomeIcon icon={menuItem.icon} size={"lg"} color={color}/>}
              label={menuItem?.label}
            >
              {menuItem.items.map((subItem, subIndex) => renderMenuItem(subItem, subIndex))}
            </SubMenu>
          );
        }
    };

    const item = () => {
        return items && items.map((menuItem, index) => renderMenuItem(menuItem, index));
    };

    const tooltip = () =>{
        return items && items.map((item, index) =>{
            return (
                <Fragment key={`${item.slug}-${index}`}>
                    {props.layoutMode === 'static' && !isMobile() && (
                    <ReactTooltip id={item.slug} place="left" effect="solid">
                        {item.label}
                    </ReactTooltip>)}
                </Fragment>
            );
        })
    }

    return (
        <Sidebar transitionDuration={1} collapsedWidth={'70px'} collapsed={!isMobile() ? props.layoutMode === 'static' : false } style={{left: !isMobile() ? props.layoutMode === 'static' ? '171px' : '' : ''}}>
            <Menu  menuItemStyles={props.colorMode === 'dark' ? modeDark : null} rootStyles={{['.css-uocmrg']: {display: 'none !important '}, ['.ps-menuitem-root']: {background: props.colorMode === 'dark' ? colorDark : ''}, ['.ps-menu-label']: {color: color}}} closeOnClick={true}>
                {tooltip()}
                {item()}
            </Menu>
        </Sidebar>
    );

}

export default AppMenu;