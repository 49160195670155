import React, {useState, useEffect, useRef, Fragment} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ApiLoan from "../../service/ApiLoanService";
import {Toast} from "primereact/toast";
import {InputSwitch} from "primereact/inputswitch";
import './BotConditionsForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'primereact/tooltip';
import { NEW_CONVERSATION } from '../../shared/chatBotConditionTypes';

const BotConditionsForm = (props) => {
    const [conditions, setConditions] = useState([]);
    const [maxConditions, setMaxConditions] = useState(20);
    const [conditionTypes, setConditionTypes] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [activeConditionsNewConversation, setActiveConditionsNewConversation] = useState(false);
    const refToast = useRef(null);

    useEffect(() => {
        let apiService = new ApiLoan();
        apiService.getResources({
            url: '/chat_bot_condition_types'
        }).then(response => {
            setConditionTypes(response.data.objects)
        })
    }, []);

    useEffect(() => {
        if (conditions.map((item) => item.chat_bot_condition_type_id).includes(null)){
            setDisableSubmit(true)
        }
        else if (conditions.map((item) => item.value).includes(null)
            || conditions.map((item) => item.value).includes('')){
            setDisableSubmit(true)
        }
        else if (conditions.map((item) => item.root_questionnaire_id).includes(null)){
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }
        if (conditions.find((item) => item.chat_bot_condition_type_id === NEW_CONVERSATION)) {
            setDisableSubmit(false)
            setActiveConditionsNewConversation(true)
        } else {
            setActiveConditionsNewConversation(false)
        }
    }, [conditions])

    useEffect(() => {
        let _new_data = []
        if (props.formData.length === 0){
            _new_data.push({
                chat_bot_condition_type_id: null,
                chat_bot_id: null,
                root_questionnaire_id: null,
                value: null,
                active: false,
                more_conditions: false,
            });
        } else {
            _new_data = props.formData.map((item, index) => {
                return {
                    ...item,
                    more_conditions: index < props.formData.length - 1,
                }
            });
        }
        setConditions(_new_data)
    }, []);

    const filterOptionNewConversation = () => {
        return conditionTypes.filter((element) => element.id !== NEW_CONVERSATION);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setFormData({index: props.index, conditions: conditions});
        props.hideModalBotConditions();
    }

    const labelSubmit = 'Modificar';

    const updateFieldCondition = (item, index, value) => {
        setConditions((prevState) => {
            let values = [...prevState];
            values[index][item] = value;
            return values;
        });
    }

    const updateMoreConditionHandler = (value, index) => {
        if (value){
            setConditions((prevState) => {
                let new_values = [...prevState];
                new_values.at(index).more_conditions = true

                new_values.push({
                    chat_bot_condition_type_id: null,
                    chat_bot_id: null,
                    root_questionnaire_id: null,
                    value: null,
                    active: false,
                    more_conditions: false,
                });
                return new_values;

            });

            setDisableSubmit(true)
        } else {
            if (conditions.length > 1){
                setConditions((prevState) => {
                    let new_values = [...prevState];
                    new_values.splice(index + 1, 1);
                    new_values.at(-1).more_conditions = false;
                    return new_values;
                });
            }
        }
    }

    const addNewConditionField = (more_conditions, index) => {
        return (
            <div className="field col-8">
                <label htmlFor="new_condition">Nueva Condición</label>
                <span className="p-float-label">
                    <InputSwitch id="new_condition"
                                 checked={more_conditions}
                                 onChange={(e) => updateMoreConditionHandler(e.value, index)}
                                 disabled={index === maxConditions - 1}
                    />
                </span>
            </div>
        )
    }

    const stopBot = () => {
        return (
            <div className='flex'>
                <div className="field col-11">
                    <span className="p-float-label">
                        <Dropdown id="stop" value={(props.stop === true || props.stop === '0') ? "0" : "1"}
                            options={props.stopOptions} label={'label'}
                            onChange={(e) => props.updateStopBotHandler(e.value)} />
                        <label htmlFor="stop">Ejecución de condiciones</label>
                    </span>
                </div>
                <div className="field col">
                    <Tooltip target=".info">
                        Esta opción permite continuar o no continuar el flujo del bot en caso de que no se cumpla ninguna de las condiciones establecidas.
                    </Tooltip>
                    <button className="p-link info">
                        <FontAwesomeIcon icon={"info"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"} />
                    </button>

                </div>
            </div>
        )
    }

    const getForm = () => {
        return conditions.map((item, index) => {

            let isNewConversation = item.chat_bot_condition_type_id === NEW_CONVERSATION;
            let dropdownOptions = isNewConversation ? conditionTypes : activeConditionsNewConversation ? filterOptionNewConversation() : conditionTypes;

            return (
                <Fragment key={index}>
                    <div className={'mt-2 mb-3 condition-number'}>
                        Condición {index + 1}
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="dropdown" value={item.chat_bot_condition_type_id} options={dropdownOptions}
                                          optionLabel={"description"} optionValue={"id"}
                                          onChange={(e) => {
                                            updateFieldCondition('chat_bot_condition_type_id', index, e.value);
                                            if (e.value === NEW_CONVERSATION) updateFieldCondition('value', index, '');
                                          }}/>
                                <label htmlFor="dropdown">Tipo de condición</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <InputText id="inputtext" disabled={isNewConversation} value={item.value || ''}
                                           onChange={(e) => updateFieldCondition('value', index, e.target.value)}/>
                                <label htmlFor="inputtext">Defina texto o palabra</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="questionnaires" value={item.root_questionnaire_id} optionLabel="name"
                                          optionValue="id"
                                          options={props.questionnairesOptions}
                                          onChange={(e) => updateFieldCondition('root_questionnaire_id', index, e.value)}/>
                                <label htmlFor="questionnaires">Seleccione cuestionario</label>
                            </span>
                        </div>
                        <div className="field col inline-flex">
                            <div className="field">
                                <label htmlFor="active">Habilitado</label>
                                <span className="p-float-label">
                                    <InputSwitch id="active" checked={item.active} onChange={(e) => updateFieldCondition('active', index, e.value)}/>
                                </span>
                            </div>
                            {addNewConditionField(item.more_conditions, index)}
                        </div>
                    </div>
                </Fragment>
            )
        });
    }

    let form = (
        <div className="grid p-fluid mt-3 mb-3">
            <div className="field col mt-3">
                <div className="">
                    <Toast ref={refToast}/>
                    {stopBot()}
                    {getForm()}
                </div>
                <div className="formgrid grid">
                    <div className="field col-12">
                        <Button label={labelSubmit} icon={'pi pi-plus'} className="ml-auto"
                                onClick={(e) => handleSubmit(e)}
                                disabled={disableSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

    return form;
}

export default BotConditionsForm;
