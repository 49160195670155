import React, {Component, Fragment} from 'react';

import {Sidebar} from 'primereact/sidebar';
import {Button} from 'primereact/button';

import '../../../assets/css/NotificationHelper.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CopyToClipboard from 'react-copy-to-clipboard';

class NotificationHelper extends Component {

    state = {
        visible: false
    };

    render() {
        const copy = (value) => {
            return (
                <CopyToClipboard style={{top: '10px'}} text={value}>
                    <FontAwesomeIcon icon={'fa-regular fa-copy'} color={'#FD971F'}
                                     size="xs" className={"btn-actions btn-fontawesome"}/>
                </CopyToClipboard>
            )
        }
        const varList = (<Fragment>
            <div className='grid'>
                <div className='col-12'>
                    <h1 style={{fontWeight: 'normal'}}>Variables Disponibles</h1>
                </div>
            </div>
            <div className='grid'>
                <div className='col-12 notification-helper'>
                    <b>{"{ClientName}"}:</b> Nombre {copy('{ClientName}')}<br/>
                    <b>{"{ClientLastname}"}:</b> Apellido {copy('{ClientLastname}')}<br/>
                    <b>{"{SanitizedClientName}"}:</b> Nombre sanitizado {copy('{SanitizedClientName}')}<br/>
                    <b>{"{SanitizedClientLastname}"}:</b> Apellido sanitizado {copy('{SanitizedClientLastname}')}<br/>
                    <b>{"{ClientDocumentNumber}"}:</b> Nº Doc {copy('{ClientDocumentNumber}')}<br/>
                    <b>{"{ClientCuil}"}:</b> Cuil {copy('{ClientCuil}')}<br/>
                    <b>{"{Variable1}"}:</b> Información extra 1 {copy('{Variable1}')}<br/>
                    <b>{"{Variable2}"}:</b> Información extra 2 {copy('{Variable2}')}<br/>
                    <b>{"{Variable3}"}:</b> Información extra 3 {copy('{Variable3}')}<br/>
                </div>
            </div>
        </Fragment>);

        const viewVarList = (<Fragment>
            <Sidebar className='p-sidebar-md' visible={this.state.visible} position="right"
                     onHide={(e) => this.setState({visible: false})}>
                {varList}
            </Sidebar>
            <Button className="p-button-rounded btn-actions-default" icon="pi pi-th-large"
                    onClick={(e) => this.setState({visible: true})}/>
        </Fragment>);

        return (<Fragment>
            {viewVarList}
        </Fragment>);

    }

}

export default NotificationHelper