import React, {useState, useEffect, useContext} from 'react';
import {MessageNoteContext} from './messageNoteContext'
import ApiLoan from "../service/ApiLoanService";
import {CompanyContext} from "./companyContext";

const MessageNoteContextProvider = ({children}) => {
    const [messageNotes, setMessageNotes] = useState({
        note_reminders: [],
        scheduled_messages: [],
    });

    const [selectedMessageNote, setSelectedMessageNote] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [globalClient, setGlobalClient] = useState(false);
    const [expirationNotes, setExpirationNotes] = useState(0);
    const [errorNotes, setErrorNotes] = useState(null);
    const [loadingConversation, setLoadingConversation] = useState(false);
    const [foundMessage, setFoundMessage] = useState(false);
    const [pageNote, setPageNote] = useState(1);
    const [pageScheduled, setPageScheduled] = useState(1);
    const [hasMoreNote, setHasMoreNote] = useState(true);
    const [hasMoreScheduled, setHasMoreScheduled] = useState(true);
    const [loadingNote, setLoadingNote] = useState(false);
    const [loadingScheduled, setLoadingScheduled] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [activeMessageNoteModal, setActiveMessageNoteModal] = useState(false)
    const [clientFullName, setClientFullName] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedDate, setSeletedDate] = useState(null);
    const [countNote, setCountNote] = useState(null);
    const {providerUrlVersion} = useContext(CompanyContext);

    const typeArrayNote = {
        scheduled_message: 'scheduled_messages',
        note_reminder: 'note_reminders'
    };

    const getExpirationNote = async (type) => {
        const apiService = new ApiLoan();
        const response = await apiService.getResourcesGeneric({
            url: '/note/reminders/expiration',
            filters: {
                message_types: [type]
            },
        }, providerUrlVersion);
        setExpirationNotes(response?.data?.count || 0);
    }

    const getMoreNote = async (type, page) => {
        const apiService = new ApiLoan();
        const response = await apiService.getResourcesGeneric({
            url: '/note/reminders',
            filters: {
                message_types: [type],
                client_id: selectedClient,
                template: selectedTemplate,
                date: selectedDate,
            },
            results_per_page: 10,
            page: page
        }, providerUrlVersion);
        let dataObjects = response.data.objects;
        let newNoteMessages = dataObjects.filter(x => x.message_type.name === type);
        setMessageNotes((prevState) => {
            return {
                ...prevState,
                [typeArrayNote[type]]: [
                    ...prevState[typeArrayNote[type]],
                    ...newNoteMessages
                ]
            };
        });
        getHasMore(response, type);
        setCountNote((prevState) => {
            return {
                ...prevState,
                [typeArrayNote[type]]: response.data.num_results
            };
        });
    }

    const getResetNote = async (type) => {
        type.map(async (element)=> {
            const apiService = new ApiLoan();
            const response = await apiService.getResourcesGeneric({
                url: '/note/reminders',
                filters: {
                    message_types: [element],
                    client_id: selectedClient,
                    template: selectedTemplate,
                    date: selectedDate,
                },
                results_per_page: 10,
                page: 1
            }, providerUrlVersion);
            let dataObjects = response.data.objects;
            let newNoteMessages = dataObjects.filter(x => x.message_type.name === element);
            setMessageNotes((prevState) => {
                return {
                    ...prevState,
                    [typeArrayNote[element]]: [
                        ...newNoteMessages
                    ]
                };
            });
            getResetHasMore(element);
            setCountNote((prevState) => {
                return {
                    ...prevState,
                    [typeArrayNote[element]]: response.data.num_results
                };
            });
        });
    }

    const getResetHasMore = (type) => {
        switch (type) {
            case 'scheduled_message':
                setHasMoreScheduled(true);
                setPageScheduled(1);
                break;
            case 'note_reminder':
                setHasMoreNote(true);
                setPageNote(1);
                break;
            default:
                break;
        }
    }

    const getHasMore = (response, type) => {
        if (response?.data?.objects?.length === 0){
            switch (type) {
                case 'scheduled_message':
                    setHasMoreScheduled(false);
                    break;
                case 'note_reminder':
                    setHasMoreNote(false);
                    break;
                default:
                    break;
            }
        }
    }

    const getPageNote = () => {
        setPageNote(pageNote + 1);
    }

    const getPagesSheduled = () => {
        setPageScheduled(pageScheduled + 1);
    }

    useEffect(() => {
        if (messageNotes.note_reminders.length > 0){
            return getExpirationNote('note_reminder');
        }

    }, [messageNotes.note_reminders])

    useEffect(() => {
        setLoadingScheduled(true);
        try {
            return getMoreNote('scheduled_message', pageScheduled);
        } finally {
            setLoadingScheduled(false);
        }
    }, [pageScheduled])

    useEffect(() => {
        setLoadingNote(true);
        try {
            return getMoreNote('note_reminder', pageNote);
        } finally {
            setLoadingNote(false);
        }
    }, [pageNote])

    const updateSelectedMessageNote = ({message_id, message_type, contact, reset}) => {
        if (selectedClient === null && globalClient){
            let messageNoteKey = message_type === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
            let client_id = messageNotes[messageNoteKey].filter(x => x.id === message_id)[0].client_id //FIX
            setSelectedClient(client_id);
            setSelectedContact(contact);
            setLoadingConversation(!loadingConversation);
            const client = messageNotes[messageNoteKey].filter(x => x.id === message_id)[0].client;

            setClientFullName(client.firstname + ' ' + client.lastname);
        }
        setSelectedMessageNote(message_id);

        if (reset){
            setFoundMessage(true)
            setSelectedClient(null);
            setSelectedContact(null);
            setSelectedMessageNote(null);
            setClientFullName(null);
        }
    }

    const updateSelectedClient = (client_id) => {
        setSelectedClient(client_id);
    }

    const updateGlobalClients = (value) => {
        setGlobalClient(value);
    }

    const deleteReminder = ({message_id, message_type}) => {
        const apiService = new ApiLoan();
        apiService.deleteResourceGeneric({
            url: '/note/reminder',
            resource_id: message_id
        }, providerUrlVersion).catch(error => {
            setErrorNotes(error.response.data);
        });
    }

    const seenNoteReminder = (message_id) => {
        const apiService = new ApiLoan();
        const formData = {
            'message_id': message_id
        }
        apiService.patchResourceGeneric({
            'url': '/note/reminder/seen',
            'data': formData
        }, providerUrlVersion).catch(error => {
            setErrorNotes(error.response.data);
        });
    }

    return (
        <MessageNoteContext.Provider
            value={{
              messageNotes: messageNotes,
              selectedMessageNote: selectedMessageNote,
              selectedClient: selectedClient,
              globalClients: globalClient,
              expirationNotes: expirationNotes,
              errorNotes: errorNotes,
              loadingConversation: loadingConversation,
              foundMessage: foundMessage,
              selectedContact: selectedContact,
              activeMessageNoteModal: activeMessageNoteModal,
              clientFullName: clientFullName,
              loadingNote: loadingNote,
              loadingScheduled: loadingScheduled,
              hasMoreNote: hasMoreNote,
              hasMoreScheduled: hasMoreScheduled,
              selectedTemplate: selectedTemplate,
              selectedDate: selectedDate,
              countNote: countNote,
              getPagesSheduled: (e) => getPagesSheduled(e),
              getPageNote: (e) => getPageNote(e),
              updateSelectedClient: (e) => updateSelectedClient(e),
              updateSelectedMessageNote: (e) => updateSelectedMessageNote(e),
              updateGlobalClients: (e) => updateGlobalClients(e),
              deleteReminder: (e) => deleteReminder(e),
              updateMessageNotes: (e) => setMessageNotes(e),
              updateExpirationNotes: (e) => setExpirationNotes(e),
              seenNoteReminder: (e) => seenNoteReminder(e),
              updateErrorNotes: (e) => setErrorNotes(e),
              updateLoadingConversation: (e) => setLoadingConversation(e),
              setFoundMessage: (e) => setFoundMessage(e),
              setActiveMessageNoteModal: (e) => setActiveMessageNoteModal(e),
              getResetNote: (e) => getResetNote(e),
              setSelectedTemplate: (e) => setSelectedTemplate(e),
              setSeletedDate: (e) => setSeletedDate(e),
            }}>
            {children}
        </MessageNoteContext.Provider>
    );
};

export default MessageNoteContextProvider;