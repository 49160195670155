import React, {Component} from 'react';
import ResourceFormGeneric from '../../Forms/ResourceFormGeneric';


class CustomTagForm extends Component {

    state = {
        uri: '/custom_tags',
        resource_id: this.props.match.params.id,
        action: this.props.match.params.id ? 'edit' : 'create',
        title: (this.props.match.params.id ? 'Editar' : 'Crear') + ' Tag',
        fields: [
            {
                name: 'name',
                elementType: 'text',
                elementConfig: {
                    onChange: this.onChangeHandler
                },
                value: '',
                traduction: 'Nombre'
            },
            {
                name: 'description',
                elementType: 'text',
                elementConfig: {
                    onChange: this.onChangeHandler
                },
                value: '',
                traduction: 'Descripción'
            },
            {
                name: 'active',
                elementType: 'switch',
                elementConfig: {
                    onLabel: "SI",
                    offLabel: "NO",
                    onChange: this.onChangeHandler
                },
                value: true,
                traduction: 'Activo'
            },
            {
                name: 'fixed',
                elementType: 'switch',
                elementConfig: {
                    onLabel: "SI",
                    offLabel: "NO",
                    onChange: this.onChangeHandler
                },
                value: false,
                traduction: 'Fijo'
            },
            {
                name: 'color',
                elementType: 'color',
                elementConfig: {},
                value: '',
                traduction: 'Color'
            },
        ],
        loading: false
    };

    onChangeHandler = (event) => {
        return event
    };

    setFields = (fields) => {
        this.setState({fields: fields})
    };

    render() {
        let {fields} = this.state;

        const form = (<ResourceFormGeneric
            setFields={this.setFields}
            fields={fields}
            url={'/custom_tags'}
            resource_id={this.state.resource_id}
            resource_traduction={this.state.title}/>);

        return (
            <div className="grid p-fluid">
                <div className="col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1><strong>{this.state.title}</strong></h1>
                        {!this.state.loading && form}
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomTagForm;