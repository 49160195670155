import React, { Component } from 'react';
import ResourceFormGeneric from '../../Forms/ResourceFormGeneric';


class SmsSettingForm extends Component {
    state = {
        uri: '/sms_settings',
        resource_id: this.props.match.params.id,
        action: this.props.match.params.id ? 'edit' : 'create',
        title: 'Configuración de sms',
        fields: [
            {
                name: 'provider_id',
                elementType: 'dropdown',
                elementConfig: {
                    options: null,
                    placeholder: 'Seleccione un cliente'
                },
                value: '',
                traduction: 'Cliente',
                requestResource: {
                    request:{
                        url: '/clients',
                        params: {},
                        filters: [
                            {
                                name: "available",
                                op: "eq",
                                val: true
                            }
                        ],
                    },
                    format: {
                        value: 'id',
                        label: 'name'
                    }
                }
            },
            {
                name: 'gateway',
                elementType: 'dropdown',
                elementConfig: {
                    options: [
                        {label: 'Smsc', value: 'Smsc'},
                        {label: 'Twilio', value: 'Twilio'},
                        {label: 'SmsMasivos', value: 'SmsMasivos'},
                        {label: 'Movilgate', value: 'Movilgate'},
                        {label: 'Teleprom', value: 'Teleprom'}
                    ],
                    placeholder: 'Seleccione un Gateway',
                    required: true
                },
                value: '',
                traduction: 'Tipo de Gateway'
            },
            {
                name: 'config',
                elementType: 'sms',
                elementConfig: {
                },
                dependence_of: [
                    'gateway'
                ],
                traduction: 'Sms'
            },
            {
                name: 'active',
                elementType: 'checkbox',
                elementConfig: {
                },
                value: false,
                traduction: 'Activo'
            },
        ],
        loading: false
    };

    setFields = (fields) => {
        this.setState({fields: fields})
    };

    render(){
        let {fields} = this.state;

        const form = (<ResourceFormGeneric
            setFields={this.setFields}
            fields={fields}
            url={this.state.uri}
            resource_id={this.state.resource_id}
            resource_traduction={this.state.title}/>);

        return (
            <div className="grid p-fluid">
                <div className="col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1><strong>{this.state.title}</strong></h1>
                        {!this.state.loading && form}
                    </div>
                </div>
            </div>
        );
    }
}

export default SmsSettingForm;