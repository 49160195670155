import React, { Component } from 'react';
import InputForm from '../../../UI/InputForm';
import { Button } from 'primereact/button';
import ApiLoan from '../../../service/ApiLoanService';
import {withRouter} from "react-router-dom";

import {showToast} from "../../../store/actions";
import {showSuccessMessage, showErrorMessage} from "../../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";
import {messageErrorObject} from "../../../shared/utility";
import axios from "axios";

class ProviderInfoEdit extends Component {

    _isMounted = false;

    state = {
        apiService: new ApiLoan(),
        url: null,
        uri: '/clients',
        title: 'Cliente',
        resource_id: null,
        providerInfoForm: {
            name: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Nombre Completo'
            },
            address: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Dirección'
            },
            email: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'email'
                },
                value: '',
                traduction: 'Email'
            },
            phone: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Teléfono'
            },
            gateway: {
                elementType: 'text',
                elementConfig: {
                },
                disabled: true,
                value: '',
                traduction: 'Gateway'
            },
            logo: {
                elementType: 'file_upload',
                elementConfig: {
                    allowMultiple: false,
                    maxFiles: 1,
                    acceptedFileTypes: ['image/jpeg', 'image/png'],
                    allowImagePreview: true,
                    name: 'logo'
                }
            },
        },
        loading: true,
        files: null,
        server: null,
        api_key: {
            elementType: 'text',
            elementConfig: {
            },
            value: '',
            traduction: 'Api Key'
        },
        waba_id: {
            elementType: 'text',
            elementConfig: {
            },
            value: '',
            traduction: 'Waba Id'
        },
        namespace: {
            elementType: 'text',
            elementConfig: {
            },
            value: '',
            traduction: 'Namespace'
        }
    };


    componentDidMount(){
        this._isMounted = true;

        let provider_id = localStorage.getItem('provider_id');
        let url = '/clients/';
        this.state.apiService.getResource({
            url: url,
            resource_id: provider_id
        }).then(response => {
            if (this._isMounted) {
                let fetched_resource = {};
                for(let field in response.data){
                    if (this.state.providerInfoForm.hasOwnProperty(field)){
                        if (response.data[field] === null){
                            response.data[field] = '';
                        }
                        let field_provider = this.state.providerInfoForm[field];
                        field_provider.value = response.data[field];
                        fetched_resource[field] = field_provider;
                    }
                }
                if (this.state.providerInfoForm['logo']){
                    fetched_resource['logo'] = this.state.providerInfoForm['logo'];
                }

                this.setState({
                    providerInfoForm: fetched_resource,
                    title: this.props.title,
                    resource_id: provider_id,
                    server: {
                        url: axios.defaults.baseURL + '/clients/'+provider_id+'/logo',
                        load: (uniqueFileId, load, error) => {
                            fetch(uniqueFileId,
                                {
                                    headers: new Headers({
                                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                                    })
                                })
                                .then(res => {
                                    if (res.status !== 200){
                                        this.setState({
                                            files: null
                                        });
                                    }
                                    return res.blob();
                                })
                                .then(load)
                                .catch(error)
                        },
                        process: {
                            withCredentials: true,
                            timeout: 240000,
                            headers: {
                                Authorization: 'Bearer '+ localStorage.getItem('token')
                            }
                        },
                        revert: null
                    },
                    loading: false,
                    files: [{
                        source: axios.defaults.baseURL + '/clients/'+provider_id+'/logo',
                        options: {
                            type: 'local'
                        }
                    }],

                });
            }
        }).catch(error => {
            showErrorMessage(this, "Clientes", 'Error en la carga del cliente');
            this.props.history.push( '/' );
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    providerInfoHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formData = {};

        for (let formElementIdentifier in this.state.providerInfoForm) {
            formData[formElementIdentifier] = this.state.providerInfoForm[formElementIdentifier].value;
        }
        formData['providerInfo']= true
        this.state.apiService.patchResource({
            url: this.state.uri,
            resource_id: this.state.resource_id,
            data: formData
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Cliente", "Se edito satisfactoriamente");
            this.props.history.push( '/' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let providerInfoForm = {
                ...this.state.providerInfoForm
            };

            let errorMessages = messageErrorObject(providerInfoForm, messageError);

            if (errorMessages !== null){
                this.setState({
                    providerInfoForm: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Cliente", messageError);
            }
        });
    };

    inputChangedHandler = (event, inputIdentifier) => {
        let inputFormChanged = this.state.providerInfoForm;

        const updatedProviderForm = {
            ...inputFormChanged
        };

        const updatedFormElement = {
            ...updatedProviderForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedProviderForm[inputIdentifier] = updatedFormElement;

        if (updatedFormElement.elementConfig.error !== undefined){
            updatedFormElement.elementConfig.error = null;
        }

        this.setState({providerInfoForm: updatedProviderForm});
    }

    render(){
        const formElementsArray = [];
        let providerInfoForm = this.state.providerInfoForm;
        let submitForm = this.providerInfoHandler;
        let submitFormButton = 'Actualizar';

        for (let key in providerInfoForm) {
            formElementsArray.push({
                id: key,
                config: providerInfoForm[key]
            });
        }

        let form = (
            <form onSubmit={submitForm}>
                {formElementsArray.map(formElement => (
                    <InputForm
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        disabled={formElement.config.disabled}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        typeElement={formElement.id}
                        traduction={formElement.config.traduction}
                        title={formElement.config.title}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}
                        url={this.state.url}
                        files={this.state.files}
                        server={this.state.server}
                    />

                ))}

                <div className="col-12">
                    <Button label={submitFormButton} style={{marginBottom:'10px'}} />
                </div>

            </form>
        );

        return (
            <div className="grid p-fluid">
                <div className="col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1><strong>{this.state.title}</strong></h1>
                        <div className="col-12 form-group">
                            {form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(ProviderInfoEdit));