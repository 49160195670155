import React, {Component} from 'react';
import InputForm from "./InputForm";

class ButtonForm extends Component {

    inputClickedButtonHandler = (event, inputIdentifier, elementType) => {

        let event_value = [];

        event_value.push(inputIdentifier.split('.'));
        event_value.push(elementType.split('.'));

        event.button = event_value;

        this.props.clicked(event, inputIdentifier);

    }


    getButtons = () => {
        let inputButtonElement = null;
        const formButtonsArray = [];

        const inputButtonElements = [];

        for (let key in this.props.buttons) {
            formButtonsArray.push({
                id: [this.props.typeElement, '.', this.props.childId ,'.' ,key].join(''),
                config: this.props.buttons[key]
            });
        }

        formButtonsArray.map(button => {
            let dataKey = button.id;

            inputButtonElement =
                <InputForm
                    key={dataKey}
                    elementType={button.config.elementType}
                    typeElement={button.id}
                    value={button.config.value}
                    classes={button.config.classes}
                    clicked={(event) => (this.inputClickedButtonHandler(event, dataKey, button.id)) }
                />;

            inputButtonElements.push(inputButtonElement);
            return inputButtonElements;

        });
        return inputButtonElements;

    }

    render(){
        let element = this.getButtons();

        return (
            element
        );

    }
}

export default ButtonForm;


