import React, {Fragment, Component} from 'react'

import ResourceFormGeneric from "./ResourceFormGeneric";
import {Button} from "primereact/button";
import {Panel} from "primereact/panel";

import '../../assets/css/FormList.css';

class FormsList extends Component{

    componentDidMount(){
        let newValue = [];
        const values = [...this.props.value];

        if(values.length === 0){
            for(let i = 0; i < this.props.elements; i++){
                let childFields = [...this.props.childFields];

                let form = childFields.map((field) => {
                    let newField = {...field};
                    newField.value = '';
                    newField.key = i;
                    return newField;
                });

                newValue.push(form);
            }

            this.props.onChange({value: newValue}, this.props.name);
        }
    }

    setFields(updatedFields, index, name){

        let values = [...this.props.value];
        values[index] = updatedFields;

        this.props.onChange({value: values}, name);
    }

    clicked(action, index, name){
        let values = [...this.props.value];

        if (action === 'delete'){
            values.splice(index, 1);
        }
        else if (action === 'add'){
            let childFields = [...this.props.childFields];

            let form = childFields.map((field) => {
                let newField = {...field};
                newField.value = '';
                newField.key = childFields.length;
                return newField;
            });
            values.push(form);
        }

        this.props.onChange({value: values}, name);
    }

    buttonDanger(index) {
        return (
            <Button className="p-button-danger p-button-rounded button-header"
                    onClick={(event) => this.clicked('delete', index, this.props.name)}
                    type="button" icon="pi pi-times"/>
        )
    }

    buttonSuccess() {
        return (
            <Button className="p-button-rounded btn-actions-default button-header"
                    onClick={(event) => this.clicked('add', null, this.props.name)}
                    type="button" icon="pi pi-plus"/>
        )
    }

    render(){
        let values = [...this.props.value];

        let formComponentsList = values.map((formfields, index) => {
            return (
                <div className="grid p-fluid" key={index}>
                    <div className="col-12 p-lg-12">
                        <div className="col-12 form-group form-list">
                            <Panel icons={this.buttonDanger(index)} header={this.props.panelChildren} className={"form-list-panel"}>
                                <ResourceFormGeneric
                                    fields={formfields}
                                    setFields={(updatedFields) => (this.setFields(updatedFields, index, this.props.name))}
                                    clicked={(event) => this.clicked(event, index, this.props.name)}
                                    dataKey={index}
                                />
                            </Panel>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <Fragment>
                <div className="col-12 form-group form-list" >
                    <Panel icons={this.buttonSuccess()} header={this.props.panelLabel} className={"form-list-panel"} >
                        <div className="p-pt-5 mt-3">
                            {formComponentsList}
                        </div>
                    </Panel>
                </div>
            </Fragment>
        );
    }
}

export default FormsList