import React, { useState, useEffect } from 'react';
import { CompanyContext } from '../companyContext';
import {getProviderSettings} from '../../utils/session';
import io from "socket.io-client";
import jwtDecode from "jwt-decode";

// const urlServer = 'http://127.0.0.1:31089';
const urlServer = process.env.REACT_APP_API_URL;

const CompanyContextProvider = ({children}) => {
    const [privateInbox, setPrivateInbox] = useState(false);
    const [socket, setSocket] = useState(io(urlServer));
    const [privateGroup, setPrivateGroup] = useState(false);
    const [chatbotEnabled, setChatBotEnabled] = useState(false);
    const [providerTimezone, setProviderTimezone] = useState('America/Argentina/Buenos_Aires');
    const [isTopBarVisible, setIsTopBarVisible] = useState(true);
    const [aiAssistantEnabled, setAiAssistantEnabled] = useState(false);
    const [internalChatEnabled, setInternalChatEnabled] = useState(false);
    const [chatChannel, setChatChannel] = useState('whatsapp');
    const [providerUrlVersion, setProviderUrlVersion] = useState('0.1');
    const [notificationAvailable, setNotificationAvailable] = useState(false);
    const [soundAvailable, setSoundAvailable] = useState(false);
    const [webhookEnabled, setWebhookEnabled] = useState(false);
    const [gatewayWaba, setGatewayWaba] = useState(null);
    const [hasExportClients, setHasExportClients] = useState(false);
    const [hasMrTurno, setHasMrTurno] = useState(false);


    const checkDecodeToken = (token) => {
        try {
            const decoded = jwtDecode(token);
            return decoded
        } catch (err) {
            return null;
        }
    }

    const connectSocket = (token) => {
        if (checkDecodeToken(token)){
            const socketNew = io(urlServer, {
                transports: ['websocket']
            })
            socketNew.on('connect', () => {
                console.log('Conectado al servidor Socket.io');
                socketNew.emit('create', token);
            });

            setSocket(socketNew);
        }
    }

    const desconnectSocket = () => {
        console.log('Desconectado al servidor Socket.io');
        socket.disconnect();
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            connectSocket(token);
            getProviderSettings()
                .then((result) => {
                    setPrivateInbox(result.provider.private_inbox);
                    setPrivateGroup(result.provider.private_group);
                    setProviderTimezone(result.provider.provider_timezone)
                    setProviderUrlVersion(result.provider.url_version);
                    setNotificationAvailable(result.notification_available);
                    setSoundAvailable(result.sound_available);
                    setWebhookEnabled(result.provider.webhook_enabled);
                    setGatewayWaba(result.provider.gateway);
                    setHasExportClients(result.provider.export_clients_enabled);
                    setHasMrTurno(result.provider.mr_turno_integration);
                    setChatBotEnabled(result.provider.chatbot_enabled);
                    setAiAssistantEnabled(result.provider.ai_assistant_integration);
                    setInternalChatEnabled(result.provider.internal_chat);

                })
        } else {
            desconnectSocket();
        }
    }, [localStorage.getItem('token')]);

    return (
        <CompanyContext.Provider
          value={{
              privateInbox: privateInbox,
              privateGroup: privateGroup,
              socket: socket,
              providerTimezone: providerTimezone,
              setPrivateInbox: setPrivateInbox,
              setPrivateGroup: setPrivateGroup,
              chatbotEnabled: chatbotEnabled,
              providerUrlVersion: providerUrlVersion,
              notificationAvailable: notificationAvailable,
              soundAvailable: soundAvailable,
              webhookEnabled: webhookEnabled,
              gatewayWaba: gatewayWaba,
              isTopBarVisible: isTopBarVisible,
              aiAssistantEnabled: aiAssistantEnabled,
              hasExportClients: hasExportClients,
              internalChatEnabled: internalChatEnabled,
              chatChannel: chatChannel,
              mrTurnoIntegration: hasMrTurno,
              setIsTopBarVisible: setIsTopBarVisible,
              setChatChannel: (e)=> setChatChannel(e)
          }}
        >
            {children}
        </CompanyContext.Provider>
    );
};

export default CompanyContextProvider;