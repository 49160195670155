import React from 'react'

import '../assets/css/BasicDropDown.css'

const BasicDropDown = (props) => {

    return ( 
    <div className={props.red ? "dropdown-red" : "dropdown"}>
        <div className={props.red ? "dropbtn-red" : "dropbtn"}>
            {props.button}
        </div>
        <div className="dropdown-content">
            {props.children}
        </div>
    </div>
    );
}

export default BasicDropDown;