import React, { useContext } from 'react';
import { CompanyContext } from '../../context/companyContext';
import MessengerComponent from '../Messenger/MessengerComponent';
import InternalMessengerComponent from '../InternalMessenger/InternalMessengerComponent';
const ChatChannelContainer = () => {

    const { chatChannel } = useContext(CompanyContext);

    return (
        <div>
            {chatChannel === 'whatsapp' ? <MessengerComponent /> : <InternalMessengerComponent />}
        </div>
    );
};

export default ChatChannelContainer;