import React, { useState, useRef, useEffect } from "react";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Chips } from 'primereact/chips';
import { Toast } from "primereact/toast";
import { withRouter } from "react-router-dom";
import "./ClientInvalidEmail.css"

import ApiLoan from "../../../service/ApiLoanService";

const ReportClientExport = props => {
    const [emails, setEmails] = useState([]);
    const [invalidEmails, setInvalidEmails] = useState([]);
    const [validatingStatus, setValidatingStatus] = useState({});
    const [disabledButton, setDisabledButton] = useState(true);
    const refToast = useRef(null);

    useEffect(() => {
        const isEmailValidating = Object.values(validatingStatus).some(status => status);
        setDisabledButton(emails.length === 0 || isEmailValidating);
    }, [emails, validatingStatus]);

    const customChip = (item) => {
        const handleRemoveClick = () => {
            setEmails(emails.filter(email => email !== item));
        };

        return (
            <div>
                {validatingStatus[item] ? <><span>{item} </span>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '14px'}}></i></>
                    : <><span>{item} </span>
                    <i className="pi pi-times-circle" style={{ fontSize: '14px', cursor: 'pointer' }} onClick={handleRemoveClick}></i></>
                }
            </div>
        );
    };

    const handleEmailChange = async (updatedEmails) => {
        const newEmail = updatedEmails[updatedEmails.length - 1];
        if (updatedEmails.length > emails.length) {
            setEmails(updatedEmails);
            setValidatingStatus(prevStatus => ({ ...prevStatus, [newEmail]: true }));
            const apiService = new ApiLoan();

            try {
                const response = await apiService.getResources({ validateEmail: true, email: newEmail });
                if (response.data.is_valid) {
                    setEmails(updatedEmails);
                    setInvalidEmails(invalidEmails => invalidEmails.filter(email => email !== newEmail));
                } else {
                    setInvalidEmails(invalidEmails => [...invalidEmails, newEmail]);
                    setEmails(emails => emails.filter(email => email !== newEmail));
                    refToast.current.show({ severity: 'error', summary: 'Invalid Email', detail: `${newEmail} no es un correo válido.` });
                }
            } catch (error) {
                console.log('Error al ingresar email.', error)
            } finally {
                setValidatingStatus(prevStatus => ({ ...prevStatus, [newEmail]: false }));
            }
        } else {
            const removedEmail = emails.find(email => !updatedEmails.includes(email));
            setEmails(updatedEmails);
            setInvalidEmails(invalidEmails => invalidEmails.filter(email => email !== removedEmail));
        }
    };


    const reportClientHandler = async (event) => {
        event.preventDefault();

        const apiService = new ApiLoan();
        const formData = {
            emails: emails
        };
        try {
            const response = await apiService.postResource({
                url: '/export/clients',
                data: formData
            });
            if (response.data.status === 'error') {
                refToast.current.show({ severity: 'info', summary: 'Export Error', detail: response.data.message });
            } else {
                refToast.current.show({ severity: 'info', summary: 'Exportación', detail: 'Enviando exportación a los correos!' });
                setTimeout(() => {
                    props.history.push('/');
                }, 4000);
            }
        } catch (error) {
            refToast.current.show({ severity: 'error', summary: 'Export Error', detail: error.response.data.message });
        }
    };

    const renderInvalidEmails = () => {
        return (
            <div className="invalid-emails-container">
                {invalidEmails.map((email, index) => (
                    <div key={index} className="invalid-email">
                        {email} (Invalido)
                    </div>
                ))}
            </div>
        );
    };


    return (
        <div className="grid dashboard">
            <Toast ref={refToast}/>
            <div className="col-12 md-12 p-lg-12 p-fluid">
                <form onSubmit={reportClientHandler}>
                    <Panel header="Exportar Clientes">
                        <div className="col-12 md-10">
                            <span className="p-float-label" style={{ marginBottom: '5px' }}>
                            <Chips value={emails} onChange={(e) => handleEmailChange(e.value)} itemTemplate={customChip} />
                                <label htmlFor="email">Ingrese direcciones de correo</label>
                            </span>
                            {renderInvalidEmails()}
                            <Button label="Exportar" style={{ marginBottom: '10px' }}
                                    disabled={disabledButton} />
                        </div>
                    </Panel>
                </form>
            </div>
        </div>
    );
};

export default withRouter(ReportClientExport);
