import React, { Fragment } from 'react';
import '../assets/css/CustomTabHeaders.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomTabHeaders = ({fontAwesomeIcon, headers, selectedIndex, onChange}) => {

    const placeholderSwith = (expr) => {
        switch (expr) {
            case 0:
                return 'Entrada';
            case 1:
                return 'No Leídos';
            case 2:
                return 'Contactos';
            case 3:
                return 'Archivados';
            return '';

        }
    }

    const tabHeaders = headers.map((header, headerIndex) => {
        return (<button
                    key={headerIndex}
                    className={'tablinks ' + ((headerIndex === selectedIndex) ? 'active': '')}
                    style={{
                        width: `${(100/headers.length) + '%'}`
                    }}
                    onClick={() => {
                        onChange(headerIndex)
                    }} >
                        {fontAwesomeIcon && (
                            <div className='tab-element'>
                                <span> <FontAwesomeIcon icon={header} size='lg' style={{marginTop: "0.5px"}} /></span>
                                <p>{placeholderSwith(headerIndex)}</p>
                            </div>
                        )}
                        {!fontAwesomeIcon && (
                            header
                        )}
                </button>);

    });

    return (<div className="tab">{tabHeaders}</div>);
}

export default CustomTabHeaders;