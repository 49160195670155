import React, {Component, Fragment} from 'react'
import FormsList from './FormsList'
import InputField from './InputField'

class Fields extends Component {

//TODO: Recursive call for nested resources

    render(){
        let fields = this.props.fields.map((field, index) => {

            let customAttributes = {};
            if (field.dependence_of){
                field.dependence_of.map(dependence => {
                    let filter_field = this.props.fields.filter(x => x.name === dependence);
                    customAttributes[filter_field[0].name] = filter_field[0].value;
                });
            }
            if (field.elementType === 'FormList'){
                return (<FormsList {...field.elementConfig} value={field.value} name={field.name}
                                   panelLabel={field.panelLabel} panelChildren={field.panelChildren} key={index}/>)
            }
            else{
                return (<InputField elementClass={field.elementClass} type={field.elementType} value={field.value} traduction={field.traduction}
                                    copy={field?.copy} key={index} {...field.elementConfig} {...customAttributes}/>)
            }
            
        });
        return (
            <Fragment>
                {fields}
            </Fragment>
        );
    }
}

export default Fields