import React, { useState } from 'react';

import SweetAlert from "react-bootstrap-sweetalert";

import '../../assets/css/GeneralAlert.css';

 const GeneralAlert = (
     {
        cancelBtnText='Cancelar',
        confirmBtnText='Confirmar',
        title='¿Está seguro?',
        alertBody,
        onConfirm,
        children
    }
) => {

    const [displayedAlert, setDisplayedAlert] = useState(false);

    const showAlert = () => {
        setDisplayedAlert(true);
    }

    const hideAlert = () => {
        setDisplayedAlert(false);
    }

    const confirmTask = () => {
        setDisplayedAlert(false);
        onConfirm();
    }

    return displayedAlert ?
        (<SweetAlert
            warning
            showCancel
            cancelBtnText={cancelBtnText}
            confirmBtnText={confirmBtnText}
            title={title}
            onConfirm={e => confirmTask()}
            onCancel={(event) => hideAlert()}
            customClass='general-alert'
            confirmBtnCssClass={
                "p-button p-component p-button-success p-button-rounded"
            }
            cancelBtnCssClass={
                "p-button p-component p-button-danger p-button-rounded"
            }
        >
            {alertBody}
        </SweetAlert>) :

        children(showAlert);
 }

export default GeneralAlert;