import React, {useEffect, useState} from "react";
import {MultiSelect} from "primereact/multiselect";
import ApiLoan from "../service/ApiLoanService";


const MultiSelectField = props => {

    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        let ignore = false;
        const page = 1;

        let dataOptions = [];

        getDropdownResources(dataOptions, page);

        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {

        let reset_values = props.value;

        if (Object.values(reset_values)[0] === ""){
            reset_values = [];
        } else {
            reset_values = Object.values(reset_values)[0];
        }

        setValues(reset_values);
    }, []);

    const getDropdownResources = (dataOptions, page) => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: props.url,
            filters: props.filters,
            page: page,
            results_per_page: 40
        }).then(response => {
            let response_data = response.data;
            if (response_data.objects !== undefined) {
                response_data = response.data.objects;
            }
            for (let key in response_data) {
                dataOptions.push({
                    value: response_data[key][props.filter.value],
                    label: response_data[key][props.filter.label]
                });
            }

            if (response.data.page === response.data.total_pages){
                setOptions(dataOptions);
                setLoading(false);
            } else {
                getDropdownResources(dataOptions, page + 1);
            }
        });
	}

    const inputField = (
        <span className="p-float-label">
            <MultiSelect
                options={options}
                selectedItemsLabel={props.selectedItemsLabel}
                maxSelectedLabels={props.maxSelectedLabels}
                value={values}
                onChange={(e) => inputChangeHandler(e, {[props.fieldValue]: e.value})}
                filter={true}
            />
            <label htmlFor="multiselect">{props.placeholder}</label>
        </span>
    );

    const inputChangeHandler = (event, field) => {
        let propsValues = [];

        event.value.map(val => {
            propsValues.push({
                [Object.keys(field)[0]]: val
            });
        })

        let field_temp = Object.keys(field)[0];
        setValues(event.value);
        let temp_vals = {
            [Object.keys(field)[0]]: event.value
        }

        props.onChange({value: temp_vals,field_temp});

    };

    if (!loading){
        return inputField;
    } else {
        return null;
    }

};

export default MultiSelectField;