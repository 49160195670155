import React from 'react';
import './ConversationSearch.css';
import { InputText } from 'primereact/inputtext';

export default function ConversationSearch(props) {
 
  return (
      <span className={`p-input-icon-left ${props.className}`} >
        <i className={(props.loading && props.value) ? "pi pi-spin pi-spinner" : "pi pi-search"} />
        <InputText
        className={"conversation-search-input"}
        placeholder={"Buscar"}
        value={props.value}
        onChange={props.onChange}/>
        {props.value && (
          <i className={"pi pi-times clean"} onClick={()=>{props.onClearValue()}}/>
        )}
      </span>
  );
}