import React, { useEffect, useContext, useState } from 'react';
import ToolbarGeneric from '../DataTables/DataTable/ToolbarGeneric';
import DataTableGeneric from '../DataTables/DataTable/DataTableGeneric';
import {TableContext} from "../../context/tableContext";

const Resources = (props) =>  {
    const tableContext = useContext(TableContext);

    const [initialRender, setInitialRender] = useState(0);

    useEffect(() => {
        if (tableContext.tableFields.reset){
            setInitialRender(initialRender + 1);
            tableContext.cleanFieldsTable();
        }
    }, [tableContext, initialRender]);

    let toolbarGeneric = <ToolbarGeneric action={props.action}
                                customTableHeaderButtons={props.customTableHeaderButtons} />;

    return (
        <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h1><strong>{props.title}</strong></h1>
                        <div className="card">
                            {toolbarGeneric}
                            <DataTableGeneric emptyMessage="No se encontró ningún resultado" key={initialRender} url={props.url} colsHeader={props.colsHeader} action={props.action}
                                              params={props.params} customActions={props.customActions}
                                              urlTable={props.urlTable} header={props.header}/>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default Resources;