import React, {useEffect, useState, useRef, useContext, Fragment} from 'react';

import {throttle} from "throttle-debounce";
import moment from 'moment';
import jwtDecode from "jwt-decode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Avatar } from 'primereact/avatar';
import { Toast } from 'primereact/toast';
import ApiLoan from "../../../service/ApiLoanService";
import { formatMoney, listToObject } from "../../../utils/utils";
import { markAsSeenInternal } from "../../../utils/internalMessenger.ts";
import {MessageContext} from "../../../context/messageContext";
import MessageContextItem from '../MessageContextItem';
import { WHATSAPP } from "../../../shared/channelTypes";
import { Badge } from 'primereact/badge'
import BasicDropDown from "../../../UI/BasicDropDown";
import Compose from '../Compose';
import Toolbar from '../Toolbar';
import MessageMedia from "../MessageMedia";
import './MessageList.scss';
import AudioRecord from "../AudioRecord";
import ConversationView from "./ConversationView";
import InfiniteScroll from 'react-infinite-scroller';
import Picker from "emoji-picker-react";
import ReactTooltip from "react-tooltip";
import { ProgressBar } from 'primereact/progressbar';
import {MessageNoteContext} from "../../../context/messageNoteContext";
import MessageNoteCalendar from "../MessageNoteCalendar/MessageNoteCalendar";
import MessageNoteReminder from "../MessageNote/MessageNoteReminder";
import CustomSpinner from "../../../UI/CustomSpinner";
import { convertMimetypeType } from '../../../shared/utility';
import MessageForwardModal from "../MessageForward/MessageForwardModal";
import {CompanyContext} from "../../../context/companyContext";
import { MessageMediaPreview } from '../MessageMediaPreview';
import MessagesNoteModal from "../MessageNote/MessagesNoteModal";
import TemplatesView from '../../Templates/TemplatesView';
import { Menu } from 'primereact/menu';
import MessageSkeleton from '../MessageSkeleton';
import { formatInternalMessage } from '../MessageList/MessageListHelpers.ts';

export default function MessageList(props) {
  const [currentClient, setCurrentClient] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [vars, setVars] = useState([]);
  const [templates, setTemplates] = useState({
    loading: false,
    templates: []
  });
  const [text, setText] = useState('');
  const [messageType, setMessageType] = useState({
    value: null,
    name: null
  });
  const [contactSelected, setContactSelected] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [activeNotes, setActiveNotes] = useState(false);
  const [messagesPerPage, setMessagesPerPage] = useState(20);
  const [firstLoading, setFirstLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const toast = useRef(null);
  const messageListComponent = useRef(null);
  const scrollParentRef = useRef(null);
  const [loadingConversation, setLoadingConversation] = useState(true);
  const message_context = useContext(MessageContext);
  const [mimetype, setMimetype] = useState('chat');
  const [showPicker, setShowPicker] = useState(false);
  const [hideShowLeftItems, setHideShowLeftItems] = useState(false);
  const [startAudio, setStartAudio] = useState(false);
  const [files, setFiles] = useState([]);
  const [onFiles, setOnFiles] = useState(false);
  const [whatsappTemplateHeaderConfig, setWhatsappTemplateHeaderConfig] = useState(null);
  const apiService = new ApiLoan();
  const lastMessageRef = useRef(null);
  const [showTemplate, setShowTemplate]= useState(false);
  const [showModal,setShowModal] = useState(false);
  const [showClientView, setShowClientView] = useState(false);
  const [scrollAtBottom, setScrollAtBottom] = useState(true);
  const [scrollAtBottomCount, setScrollAtBottomCount] = useState(0);
  const [templateWhatsappDialog, setTemplateWhatsappDialog] = useState({
    value: '',
    template_uuid: '',
    title: ''
  });
  const refEditBotNotesMenu= useRef(null);
  const [showTemplates, setShowTemplates] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [cursorPosition, setCursorPosition] = useState(null);
  const [onDetach, setOnDetach] = useState(false);
  const composeRef = useRef(null);
  const company_context = useContext(CompanyContext);
  const {setIsTopBarVisible} = company_context;

  useEffect(() => {
    const updateWindowDimensions = () => {
      const isMobileView = window.innerWidth <= 750;
      setIsTopBarVisible(!isMobileView);
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [setIsTopBarVisible]);

  useEffect(() => {
    setText('');
  }, [currentClient]);

  const cursorPositionComposeHandler = (start) => {
    setCursorPosition((prevState) => {
        return {
            ...prevState,
            cursorPosition: start,
        }
    })
  }

  let filesTmp = []

  const {selectedClient, globalClients, selectedMessageNote, messageNotes, errorNotes, loadingNote, setFoundMessage,
    updateSelectedClient, updateMessageNotes, updateErrorNotes, updateExpirationNotes} = useContext(MessageNoteContext);
  const {chatbotEnabled} = company_context;
  const socket = company_context.socket;
  const isMobile = () => {
    return window.innerWidth <= 750;
  };
  const [onDrag, setOnDrag] = useState(false);


  useEffect(() => {
    setIsTopBarVisible(false);
  },[setIsTopBarVisible])


  useEffect(() => {
    if (onFiles) setText('')
  },[onFiles]);

  useEffect(() => {
    if(text.startsWith('/')){
      if(text.startsWith('//')){
          if ((['DIALOG_360', 'CLOUD-API'].includes(localStorage.getItem('gatewayWaba')))){
            setShowPicker(false);
            setShowTemplate(true);
          }else{
            setShowTemplate(false);
          }
      }else{
          setShowPicker(false);
          setShowTemplate(true);
      }
    }else{
      setShowTemplate(false);
    }
  },[text]);

  useEffect(() => {
    if (props.client.conversation_id !== currentClient?.conversation_id){
      setCurrentClient(props.client);
      setMessages([]);
      message_context.updateMessageContext(null)
      setFirstLoading(true)
      message_context.updateForward(false);
      message_context.updateSelectedForwardMessages([]);
    }
    setOnFiles(false);
    setShowClientView(false);
    setFiles([])
    if(props.client.unread_count>0){
      markAsSeenInternal(true, props.client.conversation_id);
    }
    setPage(1);
    setOnDrag(false);
    setShowModal(false);
    setOnDetach(false);
    setScrollAtBottom(true);

    return () => {
      setTotalPages(1)
      setPage(0)
      setHasMoreMessages(false)
    }
  }, [props.client]);

  useEffect(() => {
    if (props.client.conversation_id === currentClient?.conversation_id){
      if (firstLoading && page === 1){
        setLoadingMessages(true);
        getMessages(page);
        setFirstLoading(false)
      }
    }
  }, [page, props.client, currentClient])

  useEffect(() => {
    socket.on('internal-message-' + props.client.conversation_id, addNewMessage)
    socket.on('message_failed-' + props.client.id + '-' + WHATSAPP, failedMessage)
    socket.on('delete_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
    socket.on('seen_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
    socket.on('update_status_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
    socket.on('update_message_reaction-' + localStorage.getItem('provider_id'), updateMessageReaction);
    socket.on('delete_message_reaction-' + localStorage.getItem('provider_id'), deleteMessageReaction);
    socket.on('internal-message-update-' + props.client.conversation_id, updateMessage);
    return () => {
        socket.off('internal-message-' + props.client.conversation_id, addNewMessage)
        socket.off('message_failed-' + props.client.id + '-' + WHATSAPP, failedMessage)
        socket.off('delete_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
        socket.off('seen_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
        socket.off('update_status_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
        socket.off('update_message_reaction-' + localStorage.getItem('provider_id'), updateMessageReaction);
        socket.off('delete_message_reaction-' + localStorage.getItem('provider_id'), deleteMessageReaction);
        socket.off('internal-message-update-' + props.client.conversation_id, updateMessage);
      }
  },[messages, props.client, currentClient, page, totalPages, messageNotes, toast, scrollAtBottom]);

  useEffect(() => {
    const updateMessageDetachHandler = (message) => {
      updateMessageDetach(message);
    };
    socket.on('update_message_detach-' + localStorage.getItem('provider_id'), updateMessageDetachHandler);
    return () => {
      socket.off('update_message_detach-' + localStorage.getItem('provider_id'), updateMessageDetachHandler);
    };
  }, [messages, props.client, currentClient, page, totalPages, messageNotes, toast]);

  useEffect(() => {
    const messageTypesAllowed = ['document', 'image', 'audio', 'template', 'video', 'reaction']

    if (messageTypesAllowed.includes(messageType.name)){
      sendMessage();
    }
  }, [messageType.name, whatsappTemplateHeaderConfig])

  useEffect(() => {
    const messageTypesAllowed = ['forward']

    if (messageTypesAllowed.includes(messageType.name)){
      sendForwardMessage();
    }
  }, [messageType.name])


  useEffect(() => {
    if (activeNotes && currentClient){
      if (selectedClient === null){
        updateSelectedClient(currentClient.id);
      }
    } else {
      if (selectedClient !== null && !globalClients){
        updateSelectedClient(null);
      }
    }
  }, [activeNotes, currentClient, selectedClient, globalClients])

  useEffect(() => {
    if (message_context.selectedMessageContext !== null && !loadingMessages){
      if (messages.filter(x => x.id === message_context.selectedMessageContext)[0] === undefined) {
        if (hasMoreMessages){
          getMoreMessages(page);
        }
      } else {
        message_context.updateSelectedMessageContext(null);
      }
    }
  }, [loadingMessages, messages, hasMoreMessages, message_context.selectedMessageContext])

  useEffect(() => {
    if (selectedMessageNote !== null){
      setActiveNotes(false)
    }
  }, [selectedMessageNote])

  useEffect(() => {
    if (message_context.selectedMessageContext !== null){
      message_context.updateLoadingMessages(true);
    }
  }, [message_context.selectedMessageContext])

  useEffect(() => {
    if (message_context.selectedMessageContext === null && message_context.loadingMessages){
      message_context.updateFoundMessageContext(true);
      message_context.updateLoadingMessages(false);
    }
  }, [messages, message_context.loadingMessages, message_context.selectedMessageContext])

  useEffect(() => {
    if (!message_context.loadingMessages &&  message_context.foundMessageContext ){
      message_context.updateFoundMessageContext(false)
    }
  }, [message_context.loadingMessages, message_context.foundMessageContext, messages])

  useEffect(() => {
    if (errorNotes !== null && activeNotes){
      toast.current.show({severity: 'error', summary: 'Nota/Recordatorio', detail: errorNotes,
        sticky: true});
      updateErrorNotes(null);
    }
  }, [errorNotes, activeNotes]);

  useEffect(() => {
    if (message_context.messageReaction !== null){
      setMessageType(() => {
        return {
          name: 'reaction',
          value: {
            'message_id': message_context.messageReaction.message_id,
            'emoji': message_context.messageReaction.emoji,
          }
        }
      });
    }
  }, [message_context.messageReaction])

  useEffect(() => {
    if (message_context.forward && message_context.selectedForwardContact !== null){
      if (message_context.selectedForwardMessages.length > 0){
        setMessageType(() => {
          return {
            name: 'forward',
            value: {
              'messages': message_context.selectedForwardMessages,
              'to': message_context.selectedForwardContact
            }
          }
        });
      }
    }
  }, [message_context.forward, message_context.selectedForwardMessages, message_context.selectedForwardContact])


  useEffect(() => {
    if (messageType.name === 'scheduled_message'){
      setMessageType((prevState) => {
        return {
          name: prevState.name,
          value: {
            reminder_date: prevState.value.reminder_date,
            template_uuid: templateWhatsappDialog.template_uuid
          }
        }
      })
    }
  }, [templateWhatsappDialog]);


  const scrollBottom = (message) => {
    setTimeout(() => messageListComponent.current?.scrollIntoView(false), 50)
    setNewMessage(message);
    setScrollAtBottomCount(0);
  }

  useEffect(() => {
    if (scrollAtBottom) setScrollAtBottomCount(0);
  }, [scrollAtBottom])

  const addNewMessage = (message, checkMessage=true) => {
    if(message.internal_conversation_id === props.client.conversation_id){
      let newMessages = [...messages];
      if (!message.reaction){
        if (checkMessage){
          if(!checkIfMessageExist(message, newMessages)){
            newMessages.unshift(message);
          } else {
            let new_messages_index = newMessages.findIndex(x => x?.potential === true);
            if (new_messages_index > -1){
              newMessages[new_messages_index] = message;
            }
          }
        } else {
          newMessages.unshift(message);
        }
        setMessages(newMessages);
        markAsSeenInternal(true, props.client.conversation_id);
        setFoundMessage(false);
      } else {
        newMessageReaction(message)
      }
      if (message.type !== 'received') {
        scrollBottom(message);
      } else {
        if (scrollAtBottom) scrollBottom(message);
        else setScrollAtBottomCount( prevCount => prevCount + 1);
      }
    }

  }

  const checkIfMessageExist = (message, messages) => {
    const currentMessages = [...messages];
    let existMessage = false;
    currentMessages.forEach((msg) => {
      if((msg.message === message.message) & msg.potential === true && msg.user_id === message.user_id){
        existMessage=true;
      }
    })
    return existMessage;
  }

  const updateStatusMessage = ({message, status}) => {
    let newMessages = [...messages];
    let message_index = newMessages.findIndex(x => x.id === message.id);
    if (message_index === -1) message_index = newMessages.findIndex(x => x.emoji === message.emoji);
    if (message_index > -1) {
      newMessages[message_index][status] = true
      setNewMessage(message);
      setMessages(newMessages.filter(x => x.id !== undefined))
    }
  }

  const failedMessage = ({message, error}) => {
    let msgs = [...messages];
    let message_failed_index = msgs.findIndex(x => x.id === message.id);
    if (message_failed_index > -1){
      msgs[message_failed_index].failed = true
      setMessages(msgs);
    }
    toast.current.show({severity: 'error', summary: 'Mensaje no enviado', detail: error, sticky: true});
  }

  const deleteNoteReminder = (message) => {
    if (toast.current !== null){
      let msgs = [...messages];
      let new_messages = msgs.filter(x => x.id !== message.id);
      setMessages(new_messages);

      let allowed_message_types = ['note_reminder', 'scheduled_message'];
      if (allowed_message_types.includes(message.message_type.name)){
        let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
        let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id);
        updateMessageNotes(prevState => {
          return {
            ...prevState,
            [messageNoteKey]: updated_messages
          }
        });
      }
      setActiveNotes(false)

      let detail = '';
      if (message.message_type.name === 'note'){
        detail = 'Se elimino la nota';
      } else if (message.message_type.name === 'note_reminder'){
        detail = 'Se elimino el recordatorio';
      } else {
        detail = 'Se elimino el mensaje programado';
      }
      toast.current.show({severity: 'success', summary: message.message_type.description, detail: detail, sticky: true});
    }
  }

  const seenNoteReminder = (message) => {
    if (toast.current !== null) {
      let allowed_message_types = ['note_reminder', 'scheduled_message'];
      if (!allowed_message_types.includes(message.message_type.name)){
        return null;
      }
      let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
      let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id)

      updateMessageNotes(prevState => {
        return {
          ...prevState,
          [messageNoteKey]: updated_messages
        }
      });

      updateExpiredNoteReminders(updated_messages)

      setActiveNotes(false)

      let msgs = [...messages];
      let msg_seen_index = msgs.findIndex(x => x.id === message.id);

      if (msg_seen_index > -1){
        msgs[msg_seen_index].seen = true;
        msgs[msg_seen_index].note_reminder.seen = true;
        setMessages(msgs);
      }

      let detail = '';
      if (message.message_type.name === 'note'){
        detail = 'Se marcó como visto la nota';
      } else if (message.message_type.name === 'note_reminder'){
        detail = 'Se marcó como visto el recordatorio';
      } else {
        detail = 'Se marcó como visto el mensaje programado';
      }

      toast.current.show({
        severity: 'success', summary: message.message_type.description, detail: detail,
        sticky: true
      });
    }
  }

  const updateMessageReaction = (message) => {
    if (message.parent === null){
      return null;
    }
    let parent_id = message.parent.id;

    let reaction = {
      'id': message.id,
      'client_id': message.client_id,
      'type': message.type,
      'emoji': message.emoji,
    }

    let msgs = [...messages];
    let msg_parent_index = msgs.findIndex(x => x.id === parent_id);

    if (msg_parent_index > -1){
      let msg_parent_reactions = [...msgs[msg_parent_index].reactions];
      let existingReactionIndex = msg_parent_reactions.findIndex(x => x.id === reaction.id);

      if (existingReactionIndex !== -1) {
        msg_parent_reactions[existingReactionIndex] = reaction;
      } else {
        msg_parent_reactions.push(reaction);
      }

      msgs[msg_parent_index] = { ...msgs[msg_parent_index], reactions: msg_parent_reactions };
      setMessages(msgs)
    }
  }

  const updateMessageDetach = (message) => {
    setOnDetach(true);
    setMessages(prevState => {
      const updatedMessages = prevState.map(oldMessage => {
        if (oldMessage.id === message.id) {
          return {
            ...oldMessage,
            message_type: message.message_type
          };
        }
        return oldMessage;
      });
      return updatedMessages;
    });
  }

  const updateMessage = (message) => {
    setMessages(prevState => {
      const updatedMessages = prevState.map(oldMessage => {
        if (oldMessage.id === message.id) {
          return {
            ...oldMessage,
            ...message
          };
        }
        return oldMessage;
      });
      return updatedMessages;
    });
  }

  const deleteMessageReaction = (message) => {
    if (message.parent === null){
      return null;
    }
    let parent_id = message.parent.id;

    let msgs = [...messages];
    let msg_parent_index = msgs.findIndex(x => x.id === parent_id);

    if (msg_parent_index > -1){
      let msg_parent_reactions = msgs[msg_parent_index].reactions;

      msgs[msg_parent_index].reactions = msg_parent_reactions.filter(x => x.id !== message.id);
      setMessages(msgs)
    }
  }

  const newMessageReaction = (message) => {
    if (message.parent === null){
      return null;
    }
    let parent_id = message.parent.id;

    let reaction = {
      'id': message.id,
      'client_id': message.client_id,
      'type': message.type,
      'emoji': message.emoji,
    }

    let msgs = [...messages];
    let msg_parent_index = msgs.findIndex(x => x.id === parent_id);

    if (msg_parent_index > -1){
      let msg_parent_reactions = [...msgs[msg_parent_index].reactions];
      let existingReactionIndex = msg_parent_reactions.findIndex(x => x.id === reaction.id);

      if (existingReactionIndex === -1) {
        msg_parent_reactions.push(reaction);
        msgs[msg_parent_index] = {...msgs[msg_parent_index], reactions: msg_parent_reactions};
        setMessages(msgs)
      }
    }
  }

  const updateExpiredNoteReminders = (notes) => {
    const date_now = moment(new Date()).format('DD/MM/YYYY');
    let reminders_expired = notes.filter(x =>
        moment(x.note_reminder.reminder_date).format('DD/MM/YYYY') === date_now)
    updateExpirationNotes(reminders_expired.length);
  }

  const clearMessages = (messages) => {
    let messagesToClean = [...messages]
    const cleanedMessages = messagesToClean.filter((msg) => {
      if(msg.id){
        return msg
      }
    })
    return cleanedMessages;
  }

  const getMessages = (message_page) => {
    apiService.getResources({
        url: '/internal_conversation_messages',
        filters: [
            {"name": "conversation_id", "op": "equals", "val": props.client.conversation_id}
        ],
        order_by: [{"field": "id", "direction": "desc"}],
        results_per_page: messagesPerPage,
        page: message_page
    }).then(response => {
        let response_data = response.data.objects;
        setTotalPages(response.data.total_pages);
        message_page < response.data.total_pages ? setHasMoreMessages(true) : setHasMoreMessages(false);
        setMessages([...messages, ...response_data]);
        setLoadingMessages(false);
    });
  };

  const getTemplate = (templateName) => {
    let foundTemplate = templates.templates.filter((template) => {
      return (template.title === templateName)
    })
    if(foundTemplate.length > 0){
      return foundTemplate[0].body
    }else {
      setTemplates((prevState) => {
        return {
          ...prevState,
          loading: true,
        }
      });
      apiService.getResources({
        url: '/templates',
        filters: [{
          name: 'title',
          op: 'eq',
          val: templateName
        }]
      }).then(response => {

          if(response.data.objects.length > 0){
            foundTemplate = response.data.objects[0];
            setTemplates((prevState) => {
              return {
                templates: [
                  ...prevState.templates,
                  foundTemplate
                ]
              }
            });
            setText(replaceUserVars(foundTemplate.body));
          }
          setTemplates((prevState) => {
            return {
              ...prevState,
              loading: false,
            }
          });
      }).catch((error) => {
        setTemplates((prevState) => {
          return {
            ...prevState,
            loading: false,
          }
        });
        return error;
      });
    }
  }

  const sendMessageSubmit = (event) => {
    event.preventDefault();
    if (onFiles) {
      files.map((element)=> {
        sentFile(element);
      })
      setOnFiles(false);
    }
    sendMessage();
  }

  const getOrCreateConversation = async (recipientInternalClientId) => {
    try {
        const response = await apiService.postResource({
            url: '/internal_conversations',
            data: { recipient_internal_client_id: recipientInternalClientId }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to get or create conversation:', error);
        throw error;
    }
  };

  const sendMessage = async () => {
    let params = {};
    const token = localStorage.getItem('token');
    const decoded_jwt = jwtDecode(token);
    let conversation_id = parseInt(props.client.conversation_id);
    let newConversation
    if (!conversation_id && props.client.internal_client_id) {
        try {
            newConversation = await getOrCreateConversation(parseInt(props.client.internal_client_id));
            conversation_id = newConversation.id
        } catch (error) {
            throw error;
        }
    }

    params['conversation_id'] = conversation_id;
    if(props.client.internal_client_id){
      params['recipient_internal_client_id'] = parseInt(props.client.internal_client_id);
    }
    params['user_id'] = decoded_jwt.user_claims.user_id
    params['message_type'] = messageType.name;
    params['message_type_data'] = messageType.value;
    if (message_context.messageContext !== null){
      params['message_context'] = message_context.messageContext.id;
    }

    let fields = {
      url: '/internal_messages/single',
      data: params,
    }

    const potentialMessage = {
      id:null,
      user_id: decoded_jwt.user_claims.user_id,
      author: null,
      author_name: localStorage.getItem('username') || '',
      parent: message_context.messageContext ? message_context.messageContext.id : null,
      message: text,
      created: new Date().toISOString(),
      username: localStorage.getItem('username') || '',
      failed: false,
      mimetype: messageType?.value?.mimetype ?? null,
      reaction: false,
      recipient_internal_client_id: params.recipient_internal_client_id,
      recipient_name: props.client.name || '',
      seen: false,
      updated: null,
      potential: true,
      internal_conversation_id: conversation_id,
    };

    const formattedPotentialMessage = formatInternalMessage(potentialMessage);

    if (message_context.messageContext !== null){
      formattedPotentialMessage['parent'] = message_context.messageContext
    }
    if (messageType?.value){
      if (messageType.name !== 'note' && messageType.name !== 'note_reminder' && messageType.name !== 'reaction' &&
          messageType.name !== 'forward' && messageType.name !== 'scheduled_message'){
            addNewMessage(formattedPotentialMessage, false)
      }
    }

    setText('')

    setMessageType((prevState) => {
      return {
        value: null,
        name: 'chat'
      }
    });

    message_context.updateMessageContext(null);
    setTemplateWhatsappDialog({
      value: '',
      template_uuid: '',
      title: ''
    });

    if (message_context.forward){
      message_context.updateSelectedForwardMessages([]);
      message_context.updateForward(false);
      message_context.updateSelectedForwardContact(null);
    }

    if(messageType?.value){
      apiService.postResource(fields).then(response => {
        if (newConversation){
          const updatedClientConversation = {
            ...props.client,
            conversation_id: newConversation.id
          };
          props.onSelectClientHandler(updatedClientConversation);
        }
      }).catch(error => {
        setMessages(clearMessages([...messages]))
        let response = error.response
        if (response?.status !== undefined) {
          if (!response.status) {
            let summary = 'Error de conexión';
            let detail = 'Por favor verificar tu conexión a internet';
            if (response?.data?.message?.error !== undefined){
              summary = 'Mensaje no enviado';
              detail = response.data.message.error;
            }
            toast.current.show({severity: 'error', summary: summary, detail: detail, sticky: true});
          } else if (response.status === 400){
            let summary = 'Error';
            let detail = response?.data?.message?.error === undefined ? '' : response.data.message.error;
            if (typeof detail === 'object'){
              detail = response.data.message.error?.error_data?.details;
            }

            detail = response?.data?.message?.message_type_data?.reminder_date === undefined ? detail :
                response.data.message.message_type_data.reminder_date;

            toast.current.show({severity: 'error', summary: summary, detail: detail, sticky: true});
          } else {
            if (response?.data?.message?.error !== undefined){
              toast.current.show({severity: 'error', summary: 'Mensaje no enviado', detail: response?.data?.message?.error});
            }else{
              toast.current.show({severity: 'error', summary: 'Error de conexión', detail: 'Notificar al administrador del inconveniente'});
            }
          }
        } else {
          if (error?.response?.status === 500){
            toast.current.show({severity: 'error', summary: 'Error de conexión'});
          }
          if (error?.response?.status === 404){
            toast.current.show({severity: 'error', summary: 'Mensaje no enviado', detail: error.response.data.message.error});
          }
        }
      });
    }
    setWhatsappTemplateHeaderConfig(null);
    setShowPicker(false);
    setMimetype('chat');
  };

  const sendForwardMessage = () => {
    let params = {};
    const token = localStorage.getItem('token');
    const decoded_jwt = jwtDecode(token);

    params['recipient'] = contactSelected.value;
    params['country_prefix'] = contactSelected.country_prefix;
    params['client_id'] = parseInt(props.client.id);
    params['channel_id'] = WHATSAPP;
    params['user_id'] = decoded_jwt.user_claims.user_id
    params['message_type'] = messageType.name;
    params['message_type_data'] = messageType.value;
    if (message_context.messageContext !== null){
      params['message_context'] = message_context.messageContext.id;
    }

    let fields = {
      url: '/messages/forward',
      data: params,
    }

    setText('')

    setMessageType((prevState) => {
      return {
        value: null,
        name: 'chat'
      }
    });
    message_context.updateMessageContext(null);
    message_context.updateSelectedForwardMessages([]);
    message_context.updateForward(false);
    message_context.updateSelectedForwardContact(null);

    apiService.postResource(fields).then(response => {
      params['type'] = 'sent';
      params['created'] = new Date();
      params['id'] = response.data.id;
    });

    setMimetype('chat');
  };

  const searchVarValueByName = (varName) => {
    const foundVar = vars.filter((variable) => {
      return varName === variable.name;
    })
    if(foundVar.length > 0 && foundVar[0]['value'] !== undefined){
      let value = foundVar[0]['value'];
      if(['{ClientTotalAmount}', '{DebtorTotalDebt}', '{DebtorTotalPaid}', '{DebtorExpiredDebt}'].includes(varName)){
        value = formatMoney(foundVar[0]['value'],2, ',', '.','$');
      }
      return value;
    }
    return false
  }

  function replaceUserVars(text) {
    const regex = new RegExp(vars.map((variable) => variable.name).join('|'), 'g');
    return text.replace(regex, (m, $1) => searchVarValueByName(m) || m);
  }

  function replaceTemplates(text) {
    const regex = new RegExp('{{.*}}', 'g');
    return text.replace(regex, (m, $1) => getTemplate(m.replace(/{|}/g, '')) || m);
  }

  const onChangeBodyTextHandler = (value) => {
    let textChanged = replaceUserVars(replaceTemplates(value));
    setText(textChanged);

    setMessageType((prevState) => {
      let prevMessageType = prevState.name;
      if (prevMessageType !== 'note' && prevMessageType !== 'note_reminder'){
        prevMessageType = 'chat';
      }

      let newMessageType = {
        name: prevMessageType,
        value: {
          'body': textChanged,
        }
      }
      if (prevMessageType === 'note_reminder') {
        newMessageType = {
          name: prevMessageType,
          value: {
            ...prevState.value,
            'body': textChanged,
          }
        }
      }
      return newMessageType
    });

  }

  const handleTemplateSelection = (value) => {
    let content = value.content;
    let whatsapp_template = value.whatsapp_template;
    let whatsapp_template_header_config = value.template_header_config;
    let textChanged = replaceUserVars(replaceTemplates(content));
    setText(textChanged);

    if (whatsapp_template){
      if (whatsapp_template_header_config){
        setWhatsappTemplateHeaderConfig(whatsapp_template_header_config)
        setMimetype(convertMimetypeType(whatsapp_template_header_config.mimetype))
      }
      setMessageType(() => {
        return {
          name: 'template',
          value: {
            'template_uuid': value.template_uuid
          }
        }
      });
    } else {
      if (whatsapp_template_header_config === null || whatsapp_template_header_config === undefined){
        setMessageType(() => {
          return {
            name: 'chat',
            value: {
              'body': textChanged
            }
          }
        });
      } else {
        let message_type_template = convertMimetypeType(value.template_header_config.mimetype)
        whatsapp_template_header_config['caption'] = textChanged
        setText(textChanged)
        setWhatsappTemplateHeaderConfig(whatsapp_template_header_config)
        setMessageType(() => {
          return {
            name: message_type_template,
            value: whatsapp_template_header_config
          }
        });
        setMimetype(message_type_template)
      }
    }

  }

  const addMessageMedia = (message_type, media) => {
    if (message_type === 'audio' || message_type === 'document' || message_type === 'image' || message_type === 'video'){
        setMimetype(message_type);
        setText(media.url);
        setMessageType(() => {
        return {
          name: message_type,
          value: {
            'url': media.url,
            'id': media.id,
            'mimetype': media.mimetype,
            'filename': media.filename,
            'size': media.size
          }
        }
      });
    }
    else if (message_type === 'note'){
      setMessageType((prevState) => {
        return {
          name: message_type,
          value: {
            'body': prevState.value?.body === undefined ? '' : prevState.value.body
          }
        }
      })
    }
    else if (message_type === 'note_reminder'){
      setMessageType((prevState) => {
        return {
          name: message_type,
          value: {
            'body': '',
            'reminder_date': null
          }
        }
      })
    }
    else if (message_type === 'scheduled_message'){
      setMessageType((prevState) => {
        return {
          name: message_type,
          value: {
            'template_uuid': '',
            'reminder_date': null
          }
        }
      })
    }
  }

  const messageMediaError = (error, summary) => {
    toast.current.show({severity: 'error', summary: summary, detail: error});
  }

  const contactListDropDown = (
    <BasicDropDown key='contactList' button={<FontAwesomeIcon icon={"caret-down"} color={'#19418BE5'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome btn-caret-down"}/>}>
      {contacts.map((contact, index) => {
        return (<div key={index} onClick={() => setContactSelected(contact)}>{contact.value}</div>)
      })}
    </BasicDropDown>
  );

  const noteListButton = (
    <button data-tip data-for="noteList" className="p-link" onClick={(e) => setActiveNotes(!activeNotes)}>
      <FontAwesomeIcon icon={!activeNotes ? "fa-regular fa-note-sticky" : 'times'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
    </button>
);

  const noteList = (
      <Fragment key={'noteList'}>
        {noteListButton}
      </Fragment>
  );



  let itemsForEditNotesMenu = [
    {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-note-sticky"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Notas </span>
      </i>,
      command: () => { setActiveNotes(!activeNotes); },
    }
  ]

  const editBotNotesMenu = (
    <Menu model={itemsForEditNotesMenu} popup={true} ref={refEditBotNotesMenu}  />
  )

  const hideShowButton = (
    <div key={'hideShowButton'}>
      <a className="p-link" onClick={(event) => actionHideShowButton(event)}>
        <FontAwesomeIcon icon={"ellipsis-vertical"} color={'#0436A0'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
      </a>
    </div>
  );

  const hideShowClientView = () => {
    setShowClientView(showClientView ? false : true);
  }

  const actionHideShowButton = (event) => {
    event.preventDefault();
    refEditBotNotesMenu.current.toggle(event);
  }

  const actionShowPicket = (event) => {
    event.preventDefault();
    if (showPicker){
      setShowPicker(false);
    }else{
      setShowPicker(true);
      if (showTemplate){
        setShowTemplate(false);
        setText('');
      }
    }
  }

  const backShowButton = isMobile() && (
    <div key={'backButton'}>
      <button className="p-link" onClick={() => props.onSelectClientHandler(null)}>
        <FontAwesomeIcon icon={"arrow-left"} color={'#000000'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome btn-back"}/>
      </button>
    </div>
  );

  const emojisButton = show => {
    return (
      <Fragment>
        {!onFiles && (
          <button className="p-link" data-tip data-for="emojisButton" onClick={actionShowPicket}>
            <FontAwesomeIcon icon={showPicker ? "keyboard" : "fa-regular fa-face-smile"} className={'btnEmoji botonTransp'}/>
          </button>)}
        {!isMobile() &&
          <ReactTooltip id="emojisButton" place="left" effect="solid">
            {showPicker ? 'Teclado' : 'Emojis'}
          </ReactTooltip>
        }
      </Fragment>
    )
  };

  const moreOptionButton = (
    <Fragment>
      <div style={{ display: 'flex', bottom: isMobile() ? startAudio ? '' : showPicker ? '' : '50px' : ''}}>
        {!startAudio && (
          <Fragment>
              {!showPicker && (
                !onFiles && (
                <MessageMedia
                  setShowTemplates={setShowTemplates}
                  onMessageType={setMessageType}
                  onMessageMediaCreate={addMessageMedia}
                  onMessageMediaError={messageMediaError}
                  messageType={messageType.name}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  />
              ))}
        </Fragment>)}
      </div>
    </Fragment>
  );

  let composeLeftItems = [
    <Fragment key={'composeRightsItems'}>
      {emojisButton(false)}
      {moreOptionButton}
    </Fragment>
  ]

  const sendButton = (
    <Fragment>
      {!startAudio && (
          !(text === '' ? onFiles ? false : true : !showTemplate ? false : true) && (
            <button className="p-link" onClick={(event)=>{sendMessageSubmit(event)}} disabled={text === '' ? onFiles ? false : true : !showTemplate ? false : true}>
              <FontAwesomeIcon icon='fa-regular fa-paper-plane' className={text === '' ? onFiles ? 'btn botonSend' : 'btn botonF2' : !showTemplate ? 'btn botonTransp' : 'btn botonF2'}/>
            </button>
          )
      )}
    </Fragment>
  );

  const audioButton = (
    <Fragment>
      {!showPicker && (
            !onFiles && (
              text === '' && (
                <AudioRecord
                startAudio={setStartAudio}
                onMessageType={setMessageType}
                onMessageMediaCreate={addMessageMedia}
                onMessageMediaError={messageMediaError}
                />
              )
            )
          )
        }
    </Fragment>
  );

  let composeRightItems = [
    <Fragment key={'composeRightsItems'}>
      {sendButton}
      {audioButton}
      </Fragment>
  ];

  const openForwardContacts = () => {
    message_context.updateForwardContactModal(true);
  }

  let composeRightForwardItems = [
    <div>
      <button className="p-link" onClick={(event) => {openForwardContacts(event)}} disabled={message_context.selectedForwardMessages.length === 0}>
        <FontAwesomeIcon icon='fa-regular fa-share' className={message_context.selectedForwardMessages.length === 0 ? 'btn-forward  btn-forward-disabled' : 'btn-forward'}/>
      </button>
    </div>
  ];

  const onNoteCancelHandler = () => {
    setMimetype('chat')
    setMessageType((prevState) => {
      let newBody = prevState.value
      if (prevState.name === 'note_reminder'){
        newBody = prevState.value
        delete newBody['reminder_date']
      }
      if (prevState.name === 'scheduled_message'){
        newBody = prevState.value
        delete newBody['reminder_date']
        delete newBody['template_uuid']
      }

      return {
        value: newBody,
        name: 'chat'
      }
    });
    if (templateWhatsappDialog.template_uuid !== ''){
      setTemplateWhatsappDialog({
        value: '',
        template_uuid: '',
        title: ''
      });
    }

  }

  let buttonSent = [
    <Fragment>
      {!(text === '') && (
        <button className="p-link" onClick={(event)=>{sendMessageSubmit(event)}} disabled={text === ''}>
          <FontAwesomeIcon icon='fa-regular fa-paper-plane' className={text === '' ? 'btn botonF2' : 'btn botonSend'}/>
        </button>
      )}
    </Fragment>
  ];

  let noteCancel = [
    <Fragment>
      <i className="overlay-button" onClick={() => onNoteCancelHandler()}>
            <FontAwesomeIcon icon={"fa-regular fa-xmark"} className={"btn botonCancel"} />
      </i>
    </Fragment>
  ];

  const onEmojiClick = (event, emojiObject) => {
    event.preventDefault();
    let inputElement = composeRef.current;

    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;
    const textBefore = inputElement.value.substring(0, start);
    const textAfter = inputElement.value.substring(end);
    const newText = textBefore + emojiObject.emoji + textAfter;
    const newCursorPosition = start + emojiObject.emoji.length;

    setText(newText);
    setMessageType(prevInput => ({
      name: prevInput.name,
      value: {
        'body': newText
      }
    }));

    cursorPositionComposeHandler(newCursorPosition);

    setTimeout(() => {
      if (composeRef.current) {
        composeRef.current.selectionStart = composeRef.current.selectionEnd = newCursorPosition;
      }
    });
  };

  const nextPageScroll = () => {
    setTimeout(() => lastMessageRef.current?.scrollIntoView(false), 500);
  }

  const getMoreMessages = async (param_page) => {
    setScrollAtBottom(true);
    nextPageScroll();
    if (!loadingMessages){
      setLoadingMessages(true)
      if (hasMoreMessages){
        let new_page = param_page + 1;
        setPage(new_page)
        getMessages(new_page);
        return new_page
      }
    }
  }

  const  laberReactTooltip = (id, placeholder) => {
    return (
      <div>
        {!isMobile() &&
          <ReactTooltip id={id} place="bottom" effect="solid">
            {placeholder}
          </ReactTooltip>
        }
      </div>
    )
  }
  useEffect(() => {
    messages.map((message) => {
      if ((message?.recipient === contactSelected?.value) || (message?.sender === contactSelected?.value)){
        setLoadingConversation(true);
      }else{
        setLoadingConversation(false);
      }
      return message;
    });
  },[contactSelected]);

  const handleBottonScroll = () => {
    const element = scrollParentRef.current;
    const scrollAtBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 1;
    setScrollAtBottom(scrollAtBottom);
  };

  const labelButton = (
    <div>
      {currentClient ? laberReactTooltip('clientShow','Vista cliente') : ''}
      {currentClient ? laberReactTooltip('noteList','Notas') : ''}
    </div>
  )
  const conversationsView =  !loadingMessages && currentClient?.conversation_id === props.client.conversation_id   ?
      (<ConversationView scrollAtBottom={scrollAtBottom} onDetach={onDetach} key={contactSelected?.value} client={props.client} currentClient={currentClient} newMessage={newMessage} setNewMessage={setNewMessage} messages={messages} page={page}
                         lastMessageRef={lastMessageRef} messagesPerPage={messagesPerPage} contactSelected={contactSelected} />) : null;

  const conversationView = contacts && !loadingMessages && currentClient?.conversation_id === props.client.conversation_id ? (
    <Fragment key={'conversationView'}>
      {(!onDrag && !onFiles) && (
        <div className="scrollable" ref={scrollParentRef} onScroll={handleBottonScroll}>
          <div className="message-list-container" ref={messageListComponent}>
            <InfiniteScroll
              pageStart={page}
              loadMore={(paginate) => getMoreMessages(page)}
              isReverse={true}
              hasMore={hasMoreMessages}
              threshold={1}
              initialLoad={false}
              loader={loadingConversation ? <div className="center" key={0}> <ProgressBar mode="indeterminate"/> </div> : <div className="center"> <p>No se encontró ningún mensaje.</p> </div>}
              useWindow={false}
              getScrollParent={() => scrollParentRef.current}
            >
              {conversationsView}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </Fragment>) : loadingConversation ? <div className="scrollable message-list-container">  <MessageSkeleton count={5}/> </div> : null;

  let toolbarClassName = 'toolbar messenger-toolbar';
  if (activeDropdown){
    toolbarClassName = 'toolbar messenger-toolbar active';
  }

  const onNoteReminderCalendarHandler = (value) => {
    setMessageType((prevState) => {
      return {
        name: prevState.name === 'note_reminder' ? 'note_reminder' : 'chat',
        value: {
          'body': prevState.value?.body === undefined ? '' : prevState.value.body,
          'reminder_date': value
        }
      }
    });
  }

  const onNoteReminderCalendar = () => {
    return (
        <MessageNoteCalendar
            onChange={onNoteReminderCalendarHandler}
            messageType={messageType}
        />
    )
  }

  let render_modal_notes = activeNotes ? <MessageNoteReminder enabled={activeNotes} setEnabled={setActiveNotes}
                                                        isGlobal={false}/> : null;

  let render_note_messages = selectedMessageNote !== null && !globalClients ? <MessagesNoteModal
      selectedMessage={selectedMessageNote} selectedClient={selectedClient} contactSelected={contactSelected}
      activeNotes={activeNotes} setActiveNotesEnabled={setActiveNotes} clientFullName={currentClient}
  /> : null;


  let render_modal_forward = message_context.forwardContactModal ? <MessageForwardModal
      enabled={message_context.forward}
      setEnabled={message_context.updateForward} /> : null;

  let noteReminderButtons = [
    <Fragment key={'noteReminderButtons'}>
      {onNoteReminderCalendar()}
      <i className="overlay-button" onClick={() => onNoteCancelHandler()}>
        <FontAwesomeIcon icon={"fa-regular fa-xmark"} className={"btn botonCancel"}/>
      </i>
    </Fragment>
  ];

  const rightItemsCompose = () => {
    let items = composeRightItems;
    if (messageType.name === 'note') {
      items = buttonSent
      items.push(noteCancel);
    }
    else if (messageType.name === 'note_reminder') {
      items = buttonSent
      items.push(noteReminderButtons);
    }
    else if (message_context.forward){
      items = composeRightForwardItems
    }
    return items
  }

  const onMessageForwardCancelHandler = () => {
    message_context.updateSelectedForwardMessages([]);
    message_context.updateForward(false);
    setMimetype('chat')
    setMessageType((prevInput) => {
      return {
        name: 'chat',
        value: null
      }
    });
  }
  let forwardCancel = [
    <Fragment>
      <i className="overlay-button" onClick={() => onMessageForwardCancelHandler()}>
        <FontAwesomeIcon icon={"fa-regular fa-xmark"} className={"btn botonCancel"} />
      </i>
    </Fragment>
  ];

  const leftItemsCompose = () => {
    let items = null;
    if (messageType.name === 'note') {
      items = emojisButton(true);
    }
    else if (messageType.name === 'note_reminder') {
      items = emojisButton(true);
    }
    else if (messageType.name === 'scheduled_message'){
      items = [];
    }
    else if (message_context.forward){
      items = forwardCancel
    } else {
      items = composeLeftItems;
    }
    return items
  }

  const filePreview = (file) => {
    filesTmp.push(file)
    setFiles(filesTmp);
    setOnFiles(true);
  }

  const sentFile = (file) => {
    const formData = new FormData()
    formData.append('file_upload', file);
    apiService.postResourceBlob({
        url: '/messages/media',
        data: formData,
        blob: file.type
    }).then(response => {
      if (response.status === 200){
        addMessageMedia(convertMimetypeType(response.data.media.mimetype), response.data.media);
      }
    }).catch((error) => {
      if (error.response.data?.message?.size !== undefined) toast.current.show({severity: 'error', summary: 'Error en Multimedia ' + file.name, detail: error.response.data?.message?.size});
      if (error.response.data?.message?.mimetype !== undefined) toast.current.show({severity: 'error', summary: 'Error en Multimedia ' + file.name, detail: error.response.data?.message?.mimetype});
    })
  };

  const onPaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let index in items) {
      const item = items[index];
      if (item.kind === 'file') {
        filePreview(item.getAsFile());
      }
    }
  }

  const dragOverHandler = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (ev.dataTransfer.effectAllowed === 'copy' && !showModal) setOnDrag(true); 
  }

  const dragLeave = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setOnDrag(false);
  }

  const dropHandler = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          let file = ev.dataTransfer.items[i].getAsFile();
          if (file.size !== 0) filePreview(file);
        }
      }
      removeDragData(ev)
    }
    setOnDrag(false)
  }

  const removeDragData = (ev) => {
    if (ev.dataTransfer.items) {
      ev.dataTransfer.items.clear();
    } else {
      ev.dataTransfer.clearData();
    }
  }


  let messageContextItem = (
    <MessageContextItem/>
  );

  let viewModal = (
    <div style={{position: 'absolute'}}>
      {render_modal_notes}
      {render_note_messages}
      {render_modal_forward}
    </div>
  )

  let buttonScrollButton = (
    (!scrollAtBottom && !loadingMessages && !showPicker && !showTemplate && !onFiles && !onDrag) && (
      <Avatar onClick={scrollBottom} icon="pi pi-angle-down" className='scroll-botton-button p-overlay-badge' shape="circle" >
        {(scrollAtBottomCount !== 0) && (
          <Badge value={scrollAtBottomCount} />
        )}
      </Avatar>
    )
  )

  let componseInput = (
    (!onDrag && (
      <div className={'compose-element'}>
        <Compose
          messagesDivRef={messageListComponent}
          onChange={onChangeBodyTextHandler}
          leftItems={leftItemsCompose()}
          rightItems={rightItemsCompose()}
          contextItems={messageContextItem}
          onSubmitForm={sendMessageSubmit}
          value={text}
          mimetype={mimetype}
          loading={templates.loading}
          messageType={messageType.name}
          startAudio={!startAudio}
          onFiles={onFiles}
          showTemplate={showTemplate}
          composeRef={composeRef}
        />
      </div>
      ))
  );

  let backgroundImage = (
    <div className='content-imagen'></div>
  )

  let toolbarTop = (
    ((!onDrag) && (
      <Fragment>
        <Toolbar hideShowLeftItems={hideShowLeftItems}
                 title={ (loadingMessages && <ProgressBar mode="indeterminate"/>) || props.client.name}
                 backButton={backShowButton}
                 leftItems={null}
                 rightItems={[]}
                className={toolbarClassName}
        />
        {labelButton}
      </Fragment>
    ))
  )

  return (
      <div onPaste={(event) => onPaste(event)} onDragOver={(event) => dragOverHandler(event)} onDrop={(event) => dropHandler(event)} key={currentClient?.conversation_id} className='content'>
        <Toast ref={toast}/>
        {backgroundImage}
        {buttonScrollButton}
        {toolbarTop}
        {onDrag && (<div onDrop={(event) => dropHandler(event)} onDragLeave={(event) => dragLeave(event)} className='dropZone'/>)}
        {conversationView}
        {onFiles && (<MessageMediaPreview files={files} setOnFiles={setOnFiles} setFiles={setFiles}></MessageMediaPreview>)}
        {showPicker && (<Picker pickerStyle={{ height: 'auto', width: "auto", zIndex: "0" }} onEmojiClick={onEmojiClick}/>)}
        {showTemplate && (<TemplatesView text={text} onTemplateSelection={handleTemplateSelection} setShowTemplate={setShowTemplate} setText={setText}/>)}
        {componseInput}
        {viewModal}
        {loadingNote || message_context.loadingMessages ? <CustomSpinner status={'Obteniendo mensajes...'} style={{paddingLeft: '22%'}}/> : null}
      </div>
    );
}
