import {InternalClientConversation, FormattedConversation} from '../../../types/InternalMessenger/ConversationListTypes'
import { listToObject } from '../../../utils/utils';

export const formatInternalConversation = (conversation: InternalClientConversation): FormattedConversation => {
  let {
    conversation_id,
    name,
    last_message,
    unread_count,
    internal_client_id,
    group_id
  } = conversation;
  return {
    conversation_id: conversation_id,
    photo: 'assets/layout/images/profile.png',
    name: name,
    last_message: last_message,
    unread_count: unread_count,
    group_id: group_id,
    internal_client_id: internal_client_id,
  };
};

export const updateShowedClients = (clientToUpdate, showedClientsRef, searchValue) => {
  let updatedShowedClients = [...showedClientsRef]
  let showedClientsIndexedById = listToObject(updatedShowedClients, 'client_id');
  if (showedClientsIndexedById[`${clientToUpdate.id}`]) {
    updatedShowedClients = updatedShowedClients.map(c => c.client.id === clientToUpdate.id ? { ...c, client: clientToUpdate } : c);
    return updatedShowedClients
  } else if(!searchValue){
    let newMessage = formatInternalConversation(clientToUpdate)
    updatedShowedClients = [...updatedShowedClients, newMessage]
    return updatedShowedClients
  }else{
    return updatedShowedClients
  }
}

export let internalChatTabOptions: Array<string> =  ['fa-regular fa-inbox', 'fa-regular fa-envelope', 'fa-regular fa-address-book', 'fa-regular fa-people-group'];


export const getUniqueConversationId = (conversation: FormattedConversation) => {
  if (!conversation) return null;
  if (conversation.conversation_id) {
    return `conversation-${conversation.conversation_id}`;
  } else if (conversation.internal_client_id) {
    return `internal-client-${conversation.internal_client_id}`;
  } else {
    return `unknown-${conversation.name}`;
  }
};


export const isConversationActive = (conversation: FormattedConversation, conversationActive: FormattedConversation) => {
  const uniqueId = getUniqueConversationId(conversation);
  const activeConversationId = conversationActive ? getUniqueConversationId(conversationActive) : null;
  return uniqueId === activeConversationId;
};