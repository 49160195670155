import { updateObject } from '../../shared/utility';
import {SHOW_MESSAGE, CLEAN_MESSAGE} from '../actions/actionTypes';

const initialState = {
    messages: []
};

const showMessage = (state, action) => {
    let messages=[];
    messages.push(action.message);
    return updateObject(state, {messages: messages});
};

const cleanMessage = (state, action) => {
    return updateObject(state, {messages: null});
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MESSAGE: return showMessage(state, action);
        case CLEAN_MESSAGE: return cleanMessage(state, action);
        default:
            return state;
    }
};

export default reducer;