import React, { useEffect, useState, Fragment } from "react";
import {Message} from "primereact/message";
import {InputText} from "primereact/inputtext";


const InputSmsGateway = props => {
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [getGateway, setGateway] = useState('');

    useEffect(() => {
        if (props.gateway !== ''){
            setGateway(props.gateway);
        }
    }, [props.gateway]);

    useEffect(() => {
        if (props.error !== undefined){
            setErrorMessage(props.error);
        }
    }, [props.error]);

    useEffect(() => {
        if (props.gateway !== ''){
            setLoading(false);
        }
    }, [props.gateway]);

    const canParseJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const inputChangeHandler = (event, field) => {
        let field_key = Object.keys(field)[0];

        let value = props.value;

        if  (canParseJson(value)) {
            value = JSON.parse(props.value);
        }

        if (typeof(value) === 'string' || typeof(value) === 'undefined'){
            value = {
                alias: "",
                api_key: ""
            };
            value[field_key] = event.target.value;
        }

        if (value[field_key] !== undefined){
            value[field_key] = event.target.value;
        }
        props.onChange({value: value});
    };

    const cleanValueHandler = (value) => {
        props.onChange({value: value});
    };

    const getSmsGatewayForm = gateway => {
        let form = null;
        let value = props.value;

        let elementClass = "";
        if (errorMessage === undefined){
            elementClass = "col-12 md-12"
        }
        let errorMessages = {};

        if  (canParseJson(props.value)) {
            value = JSON.parse(props.value);
        }

        if (errorMessage !== undefined){
            errorMessage.map(error => {
                let field = Object.keys(error)[0];
                errorMessages = {
                    ...errorMessages,
                    [field]: error[field]
                }
                return errorMessages;
            });
        }

        switch (gateway) {
            case 'Smsc':
                if (gateway !== getGateway){
                    setGateway('Smsc');
                    value = {
                        alias: '',
                        api_key: ''
                    };
                    cleanValueHandler(value);
                }

                if (typeof(value) === 'string' || typeof(value) === 'undefined'){
                    value = {
                        alias: '',
                        api_key: ''
                    };
                    cleanValueHandler(value);
                }

                form = (
                    <Fragment>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.alias}
                                            onChange={(e) => inputChangeHandler(e, {alias: value.alias})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Alias</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.api_key}
                                            onChange={(e) => inputChangeHandler(e, {api_key: value.api_key})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">API KEY</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            case 'Twilio':
                if (gateway !== getGateway){
                    setGateway('Twilio');
                    value = {
                        auth_token: '',
                        from_field: '',
                        sid: ''
                    };
                    cleanValueHandler(value);
                }
                if (typeof(value) === 'string' || typeof(value) === 'undefined'){
                    value = {
                        auth_token: '',
                        from_field: '',
                        sid: ''
                    };
                    cleanValueHandler(value);
                }

                form = (
                    <Fragment>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.auth_token}
                                            onChange={(e) => inputChangeHandler(e, {auth_token: value.auth_token})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Auth token</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.from_field}
                                            onChange={(e) => inputChangeHandler(e, {from_field: value.from_field})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">From</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.sid}
                                            onChange={(e) => inputChangeHandler(e, {sid: value.sid})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Sid</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                    </Fragment>
                );

                break;
            case 'SmsMasivos':
                if (gateway !== getGateway){
                    setGateway('SmsMasivos');
                    value = {
                        password: '',
                        user: ''
                    };
                    cleanValueHandler(value);
                }
                if (typeof(value) === 'string' || typeof(value) === 'undefined'){
                    value = {
                        password: '',
                        user: ''
                    };
                    cleanValueHandler(value);
                }

                form = (
                    <Fragment>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.password}
                                            onChange={(e) => inputChangeHandler(e, {password: value.password})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Password</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.user}
                                            onChange={(e) => inputChangeHandler(e, {user: value.user})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">User</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                    </Fragment>
                );


                break;
            case 'Movilgate':
                if (gateway !== getGateway){
                    setGateway('Movilgate');
                    value = {
                        user: '',
                        password: '',
                        uri: '',
                        service_name: '',
                        short_number: ''
                    };
                    cleanValueHandler(value);
                }

                if (typeof(value) === 'string' || typeof(value) === 'undefined'){
                    value = {
                        user: '',
                        password: '',
                        uri: '',
                        service_name: '',
                        short_number: ''
                    };
                    cleanValueHandler(value);
                }

                form = (
                    <Fragment>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.user}
                                            onChange={(e) => inputChangeHandler(e, {user: value.user})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">User</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.password}
                                            onChange={(e) => inputChangeHandler(e, {password: value.password})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Password</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.uri}
                                            onChange={(e) => inputChangeHandler(e, {uri: value.uri})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">URI</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.service_name}
                                            onChange={(e) => inputChangeHandler(e, {service_name: value.service_name})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Service Name</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.short_number}
                                            onChange={(e) => inputChangeHandler(e, {short_number: value.short_number})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Short Number</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            case 'Teleprom':
                if (gateway !== getGateway){
                    setGateway('Teleprom');
                    value = {
                        username: '',
                        password: '',
                        service_name: ''
                    };
                    cleanValueHandler(value);
                }

                if (typeof(value) === 'string' || typeof(value) === 'undefined'){
                    value = {
                        username: '',
                        password: '',
                        service_name: ''
                    };
                    cleanValueHandler(value);
                }

                form = (
                    <Fragment>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.username}
                                            onChange={(e) => inputChangeHandler(e, {username: value.user})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Username</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                             style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.password}
                                            onChange={(e) => inputChangeHandler(e, {password: value.password})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Password</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                                style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={elementClass || "col-12 md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.service_name}
                                            onChange={(e) => inputChangeHandler(e, {service_name: value.service_name})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Service Name</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined &&
                                (<div className="col-12 md-3">
                                    <Message severity="error" text={errorMessage}
                                                style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                                </div>)
                                }
                            </div>
                        </div>
                    </Fragment>
                );
                break;                
            default:
                break;

        }
        return form;
    };

    if (!loading) {
        let input_field = getSmsGatewayForm(props.gateway);
        return input_field;
    } else {
        return null;
    }
};

export default InputSmsGateway;