import React from 'react';
import Resources from '../Resources/Resources';

const Groups = () => {
    const resource = {
        title: 'Equipos',
        url: '/groups',
        colsHeader: [
            {
                field: 'name',
                header: 'Nombre'
            },
            {
                field: 'enabled',
                header: 'Estado',
                checkbox_chips: {
                    label: {
                        'true': 'ACTIVO',
                        'false': 'INACTIVO'
                    }
                }
            },
            {
                field: 'user_accounts',
                header: 'Participantes'
            },
            {
                field: 'action',
                header: 'Acciones'
            }
        ],
        action: {
            add: '/new',
            show: '/groups/',
            edit: '/edit',
            delete: true,
        },
        urlTable: [
            '/groups',
            '/groups/new',
            '^\\/groups\\/\\d+\\/edit$'
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       action={resource.action} urlTable={resource.urlTable} />);
}

export default Groups;
