import React, {useState, useEffect, useRef} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import ApiLoan from "../../service/ApiLoanService";
import { Sidebar } from 'primereact/sidebar';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Toast} from "primereact/toast";


const ProviderWebhookForm = (props) => {
    const [action, setAction] = useState('create');
    const [method, setMethod] = useState(null);
    const [urlPage, setUrlPage] = useState('');
    const [body, setBody] = useState({});
    const [active, setActive] = useState(false);
    const [providerWebhookAction, setProviderWebhookAction] = useState(null);
    const [providerWebhookActionOptions, setProviderWebhookActionOptions] = useState(null);
    const [attributes, setAttributes] = useState([]);
    const [visibleSidebar, setVisibleSidebar] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useEffect(() => {
        let apiService = new ApiLoan();

        apiService.getResources({
            url: '/provider_webhook_actions'
        }).then(response => {
            let options = [];
            let data_objects = response.data.objects;

            data_objects.map(x => {
                return options.push({
                    'value': x.id,
                    'label': x.description
                });
            });
            setProviderWebhookActionOptions(options);

        }).catch(error => {
            props.history.push('/')
        });
        return () => {
            setProviderWebhookActionOptions(null);
        };
    }, []);

    useEffect(() => {
        let apiService = new ApiLoan();

        apiService.getResources({
            url: '/provider_webhook/variables'
        }).then(response => {
            setAttributes(response.data.objects);
        }).catch(error => {
            props.history.push('/')
        });

        return () => {
            setAttributes([]);
        };
    }, []);

    useEffect(() => {
        let edit = props.match.params.id !== undefined;
        if (edit){
            let apiService = new ApiLoan();

            apiService.getResource({
                url: '/provider_webhooks/',
                resource_id: props.match.params.id
            }).then(response => {
                const data = response.data;
                setMethod(data.method);
                setUrlPage(data.url);
                setProviderWebhookAction(data.provider_webhook_action_id);
                setActive(data.active);
                setBody(JSON.parse(data.body))
            }).catch(error => {
                props.history.push('/')
            });
            setAction('edit');
        }
    }, []);

    useEffect(() => {
        if (method !== null && urlPage !== '' && body !== null && providerWebhookAction !== null){
            setSubmitDisabled(false);
        } else {
            if (submitDisabled === false){
                setSubmitDisabled(true)
            }
        }
    }, [method, urlPage, body, providerWebhookAction]);

    const methodOptions = [
        { value: 'POST', label: 'POST' },
        { value: 'PATCH', label: 'PATCH' }
    ];

    const editJson = (e) => {
        if (e.new_value.startsWith('{')){
            const attribute_exist = attributes.filter(x => x.name === e.new_value)[0];
            if (attribute_exist === undefined){
                return false;
            }
        }
        setBody(e.updated_src);
    }

    const deleteObjectJson = (e) => {
        setBody(e.updated_src);
    }

    const showAttributes = () => {
        return attributes.map((attr, index) => {
            return (
                <div className={"inline-flex"} key={index}>
                        <h5>{attr.name}</h5>
                    <div className={"ml-2"}> {attr.type}</div>
                    <CopyToClipboard text={attr.name}>
                        <FontAwesomeIcon icon={'fa-regular fa-copy'} color={'#FD971F'}
                                         size="xs" className={"btn-actions btn-fontawesome"} />
                    </CopyToClipboard>
                </div>
            )
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const apiService = new ApiLoan();

        const formData = {
            'method': method,
            'url': urlPage,
            'provider_webhook_action_id': providerWebhookAction,
            'active': active,
            'body': body
        }

        let api_request = null;
        let url = 'provider_webhooks';
        if (action === 'create'){
            api_request = apiService.postResource;
        } else {
            api_request = apiService.patchResource;
            url = url + '/' + props.match.params.id
        }


        api_request({
            'url': url,
            'data': formData
        }).then(response => {
            refToast.current.show({severity: 'success', summary: 'Webhooks', detail: 'Se creó correctamente.'});
            props.history.push('/provider_webhooks')
        }).catch(error => {
            let error_message = error.response.data.message;
            if (typeof(error.response.data.message) === 'object'){
                error_message =  'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Webhooks',
                detail: error_message});
        });
    }

    const refToast = useRef(null);
    const labelSubmit = props.match.params.id !== undefined ? 'Guardar' : 'Crear';
    let form = (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h1><strong>Webhooks</strong></h1>
                    <Toast ref={refToast}/>
                    <div className="formgrid grid">
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="dropdown" value={method} options={methodOptions} onChange={(e) => setMethod(e.value)}/>
                                <label htmlFor="dropdown">Método</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={urlPage} onChange={(e) => setUrlPage(e.target.value)} />
                                <label htmlFor="inputtext">Url</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown value={providerWebhookAction} options={providerWebhookActionOptions} onChange={(e) => setProviderWebhookAction(e.value)}/>
                                <label>Acción</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label mt-2">
                                <Checkbox checked={active} onChange={e => setActive(e.checked)} />
                                <label className={"ml-3 "} >Habilitado</label>
                            </span>
                        </div>
                        <Button icon="pi pi-th-large" onClick={() => setVisibleSidebar(true)} className="p-button-rounded btn-actions-default" />
                    </div>
                </div>
                <div>
                    <ReactJson src={body} theme="monokai" name={null} enableClipboard={false}
                               onEdit={(e) => editJson(e)} style={{minHeight: '20em' }}
                               onDelete={(e) => deleteObjectJson(e)}
                               onAdd={(e) => true}
                               validationMessage={"Verifique las variables disponibles"}/>
                </div>
                <div className="field p-fluid">
                    <Button label={labelSubmit} disabled={submitDisabled} className={"mt-4"}
                                onClick={(e) => handleSubmit(e)} />
                </div>
            </div>
            <Sidebar visible={visibleSidebar} position="right" baseZIndex={1000000} onHide={() => setVisibleSidebar(false)}>
                <h1 style={{ fontWeight: 'normal' }}>Variables Disponibles</h1>
                {showAttributes()}
            </Sidebar>
        </div>
    )
    return form;
}

export default ProviderWebhookForm;