import React from 'react';

import '../assets/css/StripedList.css'

const StripedList = ({items, style}) => {
    const list = items.map((item, index) => {
        return (
                    <li className="item-list" key={index}>
                        {item}
                    </li>
                );
    })

    return (<ul className="ul-list" style={style}>{list}</ul>);
}

export default StripedList