import React, {useState, useEffect} from 'react';

import { CSVLink } from "react-csv";

import ApiLoan from "../../service/ApiLoanService";
import {useHistory} from "react-router-dom";


export default function CsvExample({CSV_TYPE}){
    const [providerCsvConfig, setProviderCsvConfig] = useState({
        loading: true,
        csvConfig: {}
    });
    const csvRowExamples = {
        CLIENTS_BULK_CSV_SETTINGS: [
            ["lastname", "firstname", "document_number", "cuil", "extra_info_1", "extra_info_2", "extra_info_3", "phone", "country_prefix", "email", "custom_tags", "user_identity", "group"],
            ["RIQUELME", "JOSE FELIX", "11222333", "23112223339", "Info 1", "Info 2", "Info 3", "2616223344", "54", "demo@pip.com.ar", "tag1, tag2", "usuario1", "RRHH"],
            ["ALTAMIRANO", "ROBERTO JUAN", "22333444", "20223334449", "Info 1", "Info 2", "Info 3", "2616445566", "54", "demo@pip.com.ar", "tag3, tag2", "usuario2", "Ventas"],
            ["TORRES", "ALBERTO OSVALDO", "33444555", "20334445557", "Info 1", "Info 2", "Info 3", "2616334455", "54", "demo@pip.com.ar", "tag1", "usuario3", "RRHH"]
        ]
    }

    const history = useHistory();

    useEffect(() => {
        const apiLoan = new ApiLoan();
        apiLoan
        .getResource({
            url: "/csv/config"
        })
        .then(response => {
            if (response.data[CSV_TYPE] === null){
                history.push('/');
            }

            setProviderCsvConfig({
                loading: false,
                csvConfig: response.data,
            });
        });
    },[])

    function generateExample(csvConfig){
        const csvRawExample = csvRowExamples[CSV_TYPE]
        let csvRawExampleHeader = csvRawExample[0]
        const newHeader = csvRawExampleHeader.map((columnHeader) => {
            return csvConfig[CSV_TYPE][columnHeader]
        })
        csvRawExample[0] = newHeader
        return csvRawExample
    }
    let component = '';

    if(!providerCsvConfig.loading){
        const exampleData = generateExample(providerCsvConfig.csvConfig);
        component = (<CSVLink data={exampleData} separator={","} className={'p-button p-component p-download'} filename={'ejemplo.csv'}>
                        <span className="pi pi-download"> Ejemplo</span>
                    </CSVLink>);
    }

    return component;
}
