import React from 'react';
var moment = require('moment');

const DateField = ({children, format}) => {
	const default_format ='DD/MM/YYYY';
	return (
		<React.Fragment>
			{moment(children).locale('es').format(format || default_format)}
		</React.Fragment>
	);

}

export default DateField;