import React, {useState, useEffect, useContext} from 'react';
import './Compose.css';
import TextareaAutosize from 'react-textarea-autosize';
import {MessageContext} from "../../../context/messageContext";


export default function Compose(props) {

    const [composeWidth, setComposeWidth] = useState(0);
    const {messageContext, forward, selectedForwardMessages, updateForward} = useContext(MessageContext);
    const isMobile = () => {
      return window.innerWidth <= 920;
    };

    useEffect(() => {
      if(props.messagesDivRef.current){
        setComposeWidth(props.messagesDivRef.current.offsetWidth)
      }
    }, [props.messagesDivRef]);

    useEffect(() => {
        if (messageContext !== null){
            props.composeRef.current.focus();
        }
    }, [messageContext])

    const _handleKeyDown = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        if (props.value && !props.showTemplate){
          props.onSubmitForm(e);
        }
      }
    }

    const handleOnInput = (event) => {
      props.onChange(event.target.value);
    }

    const loadingTemplate = (
      <div className="p-inputtext p-inputtextarea p-component compose-input p-inputtextarea-resizable chat-loading">
        Cargando plantilla por favor espere
      </div>
    );

    const getPlaceholder = () => {
        let label = 'Escribe un mensaje';
        if (props.messageType === 'note'){
            label = 'Escribe una nota';
        } else if (props.messageType === 'note_reminder'){
            label = 'Escribe un recordatorio';
        }
        if (!props.startAudio){
          label = 'Grabando...';
        }
        if (props.onFiles){
          label = 'Enviar Archivos...';
        }
        if (isMobile()){
          label = '...';
        }
        return label
    }

    const textAreaClass = props.value ? 'compose-input with-text' : 'compose-input';

    const chatBox = !forward ? (
        <div className="p-inputgroup chatbox inline-flex">
            { props.contextItems }

            <TextareaAutosize className={textAreaClass} minRows={1} maxRows={8} value={props.value}
                              onChange={handleOnInput} onKeyDown={_handleKeyDown} placeholder={getPlaceholder()}
                              disabled={!props.startAudio || props.onFiles} ref={props.composeRef} style={{minWidth: '90%', maxWidth: '90%'}} />
        </div>
    ) : (
        <div className={'forward-items-selection compose-input'}>
            {selectedForwardMessages.length} Seleccionados
        </div>
    );

    return (
      <div className={props.messageType === 'note' ? 'compose note' : props.messageType === 'note_reminder' ? 'compose reminder' : 'compose'}>
        { props.startAudio ? props.leftItems : ''}
        { props.loading ? loadingTemplate : chatBox }
        { props.rightItems }
      </div>
    );
}