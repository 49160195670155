import React, {useState, useEffect, useRef, useContext} from 'react';
import moment from "moment";
import Message from "../Message";
import {MessageNoteContext} from "../../../context/messageNoteContext";
import DateRange from '../DateRange/DateRange';
import {MessageContext} from "../../../context/messageContext";
import { formatInternalMessage } from '../MessageList/MessageListHelpers.ts';
import jwtDecode from "jwt-decode";

const ConversationView = (props) => {

    const [loading, setLoading] = useState(true);
    const [messagesList, setMessagesList] = useState([]);
    const findMessageRef = useRef(null);
    const findMessageContextRef = useRef(null);
    const {selectedMessageNote, foundMessage, setFoundMessage} = useContext(MessageNoteContext);
    const audioRefs = useRef([]);
    const {selectedMessageContext, foundMessageContext, loadingMessages,
        selectedForwardMessages, forward,
        updateFoundMessageContext, updateSelectedForwardMessages} = useContext(MessageContext)

    useEffect(() => {
        if (props?.lastMessageRef?.current){
            props.lastMessageRef.current.value = '';
        }
    }, [])

    useEffect(() => {
        renderMessages(props.messages);
        setLoading(false)
    }, [props.messages, props.client, selectedMessageNote, selectedMessageContext, selectedForwardMessages])

    useEffect(() => {
        if (!props.onDetach && props?.lastMessageRef?.current && !foundMessage && selectedMessageNote === null && !foundMessageContext && findMessageContextRef.current === null && !forward){
            if (props.page === 1 && props.scrollAtBottom) setTimeout(() => props.lastMessageRef.current?.scrollIntoView(true), 50);
        }
    }, [props.lastMessageRef, messagesList, props.client, findMessageContextRef, forward])

    useEffect(() => {
        if (findMessageRef.current && foundMessage){
            findMessageRef.current?.scrollIntoView({behavior: 'smooth'});
            setFoundMessage(false);
        }
    }, [selectedMessageNote, props.messages, props.client, foundMessage, findMessageRef]);

    useEffect(() => {
        if (findMessageContextRef.current && !foundMessageContext){
            if (findMessageContextRef.current?.parentNode.previousSibling !== null){
                findMessageContextRef.current?.parentNode.previousSibling.scrollIntoView({ behavior: 'smooth' })
            } else {
                findMessageContextRef.current?.parentNode.scrollIntoView({ behavior: 'smooth' })
            }
            updateFoundMessageContext(false)
        }
    }, [selectedMessageContext, props.messages, props.client, foundMessageContext, findMessageContextRef, loadingMessages]);

    const onMessageForwardChange = (e) => {
        let _selectedForwardMessages = [...selectedForwardMessages];
        if (e.checked) {
            _selectedForwardMessages.push(e.value);
        }

        else {
            for (let i = 0; i < _selectedForwardMessages.length; i++) {
                const selectedForwardMessage = _selectedForwardMessages[i];

                if (selectedForwardMessage === e.value) {
                    _selectedForwardMessages.splice(i, 1);
                    break;
                }
            }
        }
        updateSelectedForwardMessages(_selectedForwardMessages)
    }

    const renderMessages = (msgs) => {
        let tempMessages = []
        let tempDate = []
        let msgsReverse = [...msgs].reverse()

        msgsReverse.map((message)=>{
            if(!tempDate.some((element)=> moment(element.created_timezone).format('DD/MM/YY') === moment(message.created_timezone).format('DD/MM/YY'))){
                tempDate.push(message);
            }
        })

        msgs.map((message, index) => {
            if ((message.recipient === props.contactSelected?.value) || (message.sender === props.contactSelected?.value)){
                const token = localStorage.getItem('token');
                const { user_claims} = jwtDecode(token);
                let isMine = message.user_id === user_claims.user_id;

                const templateMessage = formatInternalMessage(message)
                let previous = msgs[index - 1];

                let next = msgs[index + 1];
                let currentMoment = moment(message.created_timezone);
                let prevBySameAuthor = false;
                let nextBySameAuthor = false;
                let startsSequence = true;
                let endsSequence = true;
                let showTimestamp = true;

                let messageRef = null;

                if (previous) {
                    let previousMoment = moment(previous.created_timezone);
                    let previousDuration = moment.duration(currentMoment.diff(previousMoment));
                    prevBySameAuthor = previous.client_id === message.client_id;

                    if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                        startsSequence = false;
                    }

                    if (previousDuration.as('hours') < 1) {
                        showTimestamp = false;
                    }
                }

                if (next) {
                    let nextMoment = moment(next.created_timezone);
                    let nextDuration = moment.duration(nextMoment.diff(currentMoment));
                    nextBySameAuthor = next.client_id === message.client_id;

                    if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                        endsSequence = false;
                    }
                }

                if (props.page === 1 && props.findMessageModal && selectedMessageNote === message.id){
                    messageRef = null
                } else if (props.page === 1 && index === 0){
                    messageRef = props.lastMessageRef
                } else {
                    if (props.page > 1){
                        if (index === (props.messagesPerPage * props.page) - props.messagesPerPage - 1){
                            messageRef = props.lastMessageRef
                        }
                    }
                }

                let findMessage = message.id === selectedMessageNote ? findMessageRef : (message.id === selectedMessageContext ? findMessageContextRef : null)
                tempMessages.unshift(
                    <div key={message.id} ref={messageRef}>
                        <div ref={findMessage}>
                            <span>{tempDate.some((element)=> element.id === message.id) ? <DateRange date={message.created_timezone}></DateRange> : null}</span>
                            <Message
                                key={message.id}
                                index={index}
                                isMine={isMine}
                                startsSequence={startsSequence}
                                endsSequence={endsSequence}
                                showTimestamp={showTimestamp}
                                data={templateMessage}
                                onMessageForwardChange={onMessageForwardChange}
                                animated={message.id === selectedMessageContext}
                                audioRefs={audioRefs}
                            />
                        </div>
                    </div>
                );
                return tempMessages
            }
        });
        setMessagesList(tempMessages);
    }

    if (loading){
        return null;
    }
    return messagesList;

}

export default ConversationView;