import React from 'react';

export const TableContext = React.createContext({
    tableFields: {
        url: null,
        filters: null,
        page: 0,
        first: 0,
        order_by: null,
        sort: {
            sortOrder: null,
            sortField: null
        },
        reset: false
    },

    updateTableFields: (data) => {},
    checkCurrentTable: (urisAvailable, fromOrigin) => {},
    cleanFieldsTable: () => {}
});