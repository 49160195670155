import React, {useEffect, useState} from 'react';
import ApiLoan from "../../../service/ApiLoanService";
import { Dropdown } from 'primereact/dropdown';
import { useHistory } from "react-router-dom";

const ReassignMessages = (props) => {

    const [usersFrom, setUsersFrom] = useState(null);
    const [usersTo, setUsersTo] = useState(null);
    const [user, setUser] = useState({
        assign_from: null,
        assign_to: null
    });
    const history = useHistory();

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResources({
            url: '/conversations/users',
        }).then(response => {
            let objects = response.data.objects;

            let data = objects.map(obj => {
                return {
                    'id': obj.id,
                    'username': obj.username
                }
            })

            setUsersFrom(data)
            let users_to = [...data];

            users_to.push({
                'id': 'unassigned',
                'username': 'Sin asignar'
            });

            setUsersTo(users_to);

        }).catch(error => {
            history.push( '/audiences' );
        })
    }, [history]);

    useEffect(() => {
        props.onChange(user)
    }, [user])

    const onChangeHandler = (value, field) => {
        setUser((prevState) => {
            return {
                ...prevState,
                [field]: value
            }
        });
    }

    return (
        <div>
            <div className="field col">
                <div className="col-12 md-12">
                    <span className="p-float-label">
                        <Dropdown value={user.assign_from} options={usersFrom}
                                  onChange={(e) => onChangeHandler(e.value, 'assign_from')} optionLabel="username"
                                  optionValue="id" filter={!isMobile()} showClear filterBy="username" />
                        <label htmlFor="dropdown">Los chats de</label>
                    </span>
                </div>
            </div>
            <div className="field col">
                <div className="col-12 md-12">
                    <span className="p-float-label">
                        <Dropdown value={user.assign_to} options={usersTo}
                                  onChange={(e) => onChangeHandler(e.value, 'assign_to')} optionLabel="username"
                                  optionValue="id" filter={!isMobile()} showClear filterBy="username" />
                        <label htmlFor="dropdown">Al operador</label>
                    </span>
                </div>
            </div>
        </div>
    );

}
export default ReassignMessages;