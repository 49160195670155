import React, {useState, Fragment, useEffect} from 'react';
import ApiLoanService from "../../../service/ApiLoanService";
import {Button} from "primereact/button";
import StripedList from "../../../UI/StripedList";
import Tag from "../../../UI/Tag";
import InputField from '../../Forms/InputField';
import ResultNotFount from '../../../UI/ResultNotFount';


const NotificationWhatsappBody = (props) => {
    const [search, setSearch] = useState('');

    const [templateState, setTemplateState] = useState({
        templates: [],
        page: 1,
        num_results: 0,
        loading: true
    });

    const [templateSearchState, setTemplateSearchState] = useState({
        templates: [],
        page: 1,
        num_results: 0,
        loading: true
    });

    const onChangeSearch = (e) => {
        let templateSearch = []
        setSearch(e.target.value)
        if (e.target.value === '') {
            setTemplateSearchState({
                templates: [],
                page: 1,
                num_results: 0,
                loading: true
            })
        }
        if (templateState?.templates?.length !== 0 && e.target.value.length >= 1) {
            templateSearch = templateState?.templates.filter(element => element.title.toLowerCase().includes(e.target.value.toLowerCase()));
            setTemplateSearchState((prevState) => {
                return {
                    ...prevState,
                    templates: [
                        ...templateSearch
                    ],
                    page: templateState.page,
                    num_results: templateState.num_results,
                    loading: templateState.loading
                }
            });
        }
    }

    useEffect(() => {
        const apiService = new ApiLoanService()
        apiService.getResources({
            url: '/templates',
            page: templateState.page,
            filters: props.filter
        }).then(response => {
            let loading = response.data.page < response.data.total_pages
            setTemplateState((prevState) => {
                return {
                    ...prevState,
                    templates: [
                        ...prevState.templates,
                        ...response.data.objects
                    ],
                    page: response.data.page,
                    num_results: response.data.num_results,
                    loading: loading
                }
            });
        });
    }, [templateState.page]);

    useEffect(() => {
        if (templateState.templates.length !== templateState.num_results){
            setTemplateState((prevState) => {
                return {
                    ...prevState,
                    page: prevState.page + 1
                }
            });
        }
    }, [templateState.num_results, templateState.templates]);

    const onTemplateSelection = (body, title, template_uuid) => {
        props.onChange({value: body, title: title, template_uuid: template_uuid});

        props.updateTemplateValue(prevState => {
            return {
                ...prevState,
                value: body,
                title: title,
                template_uuid: template_uuid
            }
        });
        props.onClose();
    }

    const template = search !== '' ? templateSearchState : templateState;

    const templateList = template.templates.map((template) => {
        let selectButton = null;

        const templateStatuses = {
            'APPROVED': 'Aprobado',
            'DELETED': 'Borrado',
            'DISABLED': 'Desactivado',
            'IN_APPEAL': 'En apelación',
            'PENDING': 'Pendiente',
            'PENDING_DELETION': 'Pendiente de eliminación',
            'REJECTED': 'Rechazado'
        }

        const canSelectTemplate = (template.status === 'APPROVED' || !template.whatsapp_template);
        selectButton = (
            <Button
                type="button"
                label='Aplicar'
                icon="pi pi-check"
                className="p-button-rounded btn-actions-default"
                onClick={(event) => {
                    event.preventDefault();
                    onTemplateSelection(template.body, template.title, template.template_uuid);
                }}
                disabled={!canSelectTemplate}
            />
        );

        const showTemplateTag = template.whatsapp_template ? (
            <Tag>{templateStatuses[template.status]}</Tag>
        ) : null;

        return (<Fragment>
            <div className={"template-value"}>
                <h5><strong>{template.title} {showTemplateTag}</strong></h5>
                <span>{template.body}</span>
            </div>
            <div className={'right-item-list'}>
                {selectButton}
            </div>
        </Fragment>);
    })

    const getTemplates = () => {
        let form = null;
        if (!templateState.loading){
            form = (
                <div>
                    <InputField
                        type={'text'}
                        value={search === null ? '' : search}
                        traduction={'Buscar'}
                        onChange={onChangeSearch}/>
                    {search !== '' ? templateSearchState?.templates?.length === 0 ? <ResultNotFount/> : null : null}
                    <StripedList items={templateList} style={{margin: "0px 25px 0px 0px"}}/>
                </div>
            );
        }
        return form;
    }

    return getTemplates()

}

export default NotificationWhatsappBody;