import React, {Fragment, useMemo} from 'react';
import {getFormattedDate} from "../../../utils/utils";

import './ChatListItem.css';

export default function ChatListItem(props) {
    const {text, client,  created, id} = props.data;
    const fulltime = getFormattedDate(created);
    const searchValue = props.searchValue;


    const Fulltime = () => useMemo(() => {
        return (
            <div className='full-time p-as-center'>
                {fulltime}
            </div>
        )
    }, [id])

    /**
     * Highlights the occurrences of a search value within a given text, ignoring accents.
     * If the search value is found, it returns an array of text parts with the matched text wrapped in a span with a "highlight" class.
     * If the search value is not found, it returns the original text.
     *
     * @param {string} text - The text to search within.
     * @param {string} searchValue - The value to search for and highlight in the text.
     * @returns {string|Array} - The original text if no match is found, or an array of text parts with highlighted matches.
     */
    const highlight = (text, searchValue) => {
        if (!searchValue) return text;

        const removeAccents = (str) => {
          return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        };

        const searchValueNoAccents = removeAccents(searchValue.toLowerCase());
        const textNoAccents = removeAccents(text.toLowerCase());

        const matchIndex = textNoAccents.indexOf(searchValueNoAccents);
        if (matchIndex === -1) return text;

        const contextSize = 25;
        const start = Math.max(0, matchIndex - contextSize);
        const end = Math.min(text.length, matchIndex + searchValue.length + contextSize);

        const trimmedText = text.substring(start, end);
        let displayText = trimmedText;
        if (start > 0) {
          displayText = '...' + trimmedText;
        }

        const parts = [];
        let currentIndex = 0;

        while (currentIndex < displayText.length) {
          const remainingText = displayText.slice(currentIndex);
          const remainingTextNoAccents = removeAccents(remainingText.toLowerCase());
          const searchIndex = remainingTextNoAccents.indexOf(searchValueNoAccents);

          if (searchIndex === -1) {
            parts.push(remainingText);
            break;
          }

          if (searchIndex > 0) {
            parts.push(remainingText.slice(0, searchIndex));
          }

          const matchedText = remainingText.slice(searchIndex, searchIndex + searchValue.length);
          parts.push(<span key={currentIndex + searchIndex} className="highlight">
            {matchedText}
          </span>);

          currentIndex += searchIndex + searchValue.length;
        }

        return parts;
      };


    const renderAction = () => {
        return (
            <div className="grid">
                <div className="conversation-title col-8 md-9">
                    <h1 className="conversation-list-item-name">
                        {client.name}
                    </h1>
                </div>
                <div className="col-4 md-3 p-nogutter">
                    <div className="flex justify-content-end p-nogutter">
                        {Fulltime()}
                    </div>
                </div>
            </div>
        );
    };

    const active_element = props.conversationActive ? 'active' : '';


    return (
        <Fragment>
            <div className={`conversation-list-item ${active_element}`} onClick={props.onClick}>
                <div className="conversation-info">
                    {renderAction()}
                    <div className="grid">
                        <div className="col-8 md-9">
                            <div className="conversation-snippet" >
                                <div className="p-conversation-preview">
                                    <p>{highlight(text, searchValue)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}