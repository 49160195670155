import React from 'react';

const ResultNotFount = () => {
    return (
        <div className="result-not-fount">
          <p>No se encontró ningún resultado.</p>
        </div>
    );
}

export default ResultNotFount;