import React from 'react';
import Resources from "../Resources/Resources";

const EmailSetting = () => {

    const resource = {
        title: 'Configuración Email',
        url: '/email_settings',
        colsHeader: [
            { field: 'provider.name', header: 'Cliente'},
            { field: 'default_mail_sender', header: 'Correo'},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/email_settings/',
            edit: '/edit',
            delete: true
        },
        urlTable: [
            '/email_settings',
            '/email_setting/new',
            '^\\/email_settings\\/\\d+\\/edit$',
        ]
    };

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader} action={resource.action}
                       urlTable={resource.urlTable} />);

};


export default EmailSetting;
