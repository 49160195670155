import React, {Fragment, useRef, useContext, useEffect, useState} from 'react';
import { Toast } from 'primereact/toast';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MessageNoteContext} from "../../../context/messageNoteContext";
import './MessageNote.css'
import {RadioButton} from "primereact/radiobutton";
import ReactTooltip from "react-tooltip";
import ApiLoan from "../../../service/ApiLoanService";
import InfiniteScroll from 'react-infinite-scroller';
import CustomSpinner from '../../../UI/CustomSpinner';
import ResultNotFount from '../../../UI/ResultNotFount';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { Calendar } from 'primereact/calendar';
import {CompanyContext} from "../../../context/companyContext";

const MessageNote = (props) => {
    const {
        messageNotes, selectedClient, globalClients, updateSelectedMessageNote,
        deleteReminder, seenNoteReminder, getPageNote, getResetNote,
        getPagesSheduled, countNote, selectedDate, setSeletedDate, loadingScheduled, loadingNote, hasMoreNote, hasMoreScheduled, selectedTemplate, setSelectedTemplate
    } = useContext(MessageNoteContext);

    const {providerUrlVersion} = useContext(CompanyContext);
    const [messagesTemp, setMessagesTemp] = useState([]);
    const [messagesTempDate, setMessagesTempDate] = useState([]);
    const [notes, setNotes] = useState([]);
    const [messageNoteType, setMessageNoteType] = useState('notes');
    const [loadingNotes, setLoadingNotes] = useState(false);
    const scrollParentRef = useRef(null);
    const [optionTemplate, setOptionTemplate] = useState([]);

    const isMobile = () => window.innerWidth <= 750;

    const templateDropdown = () => {
        return (
            <div className="flex justify-content-center">
                <Dropdown value={selectedTemplate} onChange={(e) => {setSelectedTemplate(e.value)}} options={optionTemplate} placeholder="Plantilla" optionLabel="title"
                    filter showClear className="w-full md:w-14rem" />
            </div>
        )
    }

    const dateDropdown = () => {
        return (
            <div className="flex justify-content-center">
                    <Calendar showButtonBar value={selectedDate} onChange={(e) => setSeletedDate(e.value)} showClear placeholder="Periodo" selectionMode="range" readOnlyInput hideOnRangeSelection />
            </div>
        )
    }

    const countNoteBadge = () => {
        return (
            <div className='pt-2 flex justify-content-center'>
                <p><b>Cantidad: </b></p>
                <Badge value={countNote[messageNoteType] || 0}></Badge>
            </div>
        )
    }

    useEffect(() => {
        getResetNote(['scheduled_message', 'note_reminder']);
    }, [selectedTemplate, selectedDate]);

    useEffect(() => {
            const apiService = new ApiLoan();
            apiService.getResources({
                url: '/note/template',
            }).then(response => {
                setOptionTemplate(response.data);
            });
    }, []);

    useEffect(() => {
        if (selectedClient !== null && !loadingNotes) {
            const apiService = new ApiLoan();
            apiService.getResourcesGeneric({
                url: '/note/reminders',
                filters: {
                    message_types: ['note'],
                    client_id: selectedClient
                },
                results_per_page: 40,
                page: 1
            }, providerUrlVersion).then(response => {
                setNotes(response.data.objects.filter(x => x.message_type.name === 'note'));
                setLoadingNotes(true);
            });
            getResetNote(['scheduled_message', 'note_reminder']);
        }
    }, [selectedClient, loadingNotes]);

    useEffect(() => {
        if (selectedClient !== null) {
            setMessagesTemp(messageNoteType !== 'notes' ? messageNotes[messageNoteType] : notes);
        } else if (globalClients) {
            if (messageNoteType === 'notes') {
                setMessageNoteType('note_reminders');
                return;
            }
            setMessagesTemp(messageNotes[messageNoteType]);
        }
    }, [messageNotes, notes, selectedClient, globalClients, messageNoteType]);

    const showItemHandler = (data) => {
        const client_contact = data.client.contacts.find(x => x.contact_type_id === 1);
        if (client_contact) {
            updateSelectedMessageNote({
                message_id: data.id,
                message_type: data.message_type.name,
                contact: client_contact
            });
        }
    };

    const formatDate = (dateString, format) => {
        const date = new Date(dateString);
        switch (format) {
            case 'HH:mm':
                return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            case 'DD/MM/YY':
                return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: '2-digit' });
            case 'MM/DD/YYYY':
                return date.toLocaleDateString('en-US');
            case 'dddd, D [de] MMMM [de] YYYY':
                return date.toLocaleDateString('es-ES', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
            case 'YYYY-MM-DD':
                return date.toISOString().split('T')[0];
            default:
                return date.toLocaleString();
        }
    };

    const subItemNotes = (data) => {
        const time = formatDate(data.created, 'HH:mm');
        const date = formatDate(data.created, 'DD/MM/YY');
        const fulltime = `${time}hs - ${date}`;
        const user = data?.user?.username || '';

        let disabled_delete_button = data.message_type.name === 'scheduled_message' && data.has_children;
        let removeButtonClass = 'btn-actions btn-fontawesome';

        if (disabled_delete_button) {
            removeButtonClass += ' note-button-cancel';
        }

        const noteButtonRemove = (
            <Fragment>
                <button data-tip disabled={disabled_delete_button} data-for="deleteNote"
                        className="p-link note-button-cancel"
                        onClick={() => deleteReminder({message_id: data.id, message_type: data.message_type.name})}>
                    <FontAwesomeIcon icon={'times'} color={'red'} size={"xs"} className={removeButtonClass}/>
                </button>
                {!isMobile() &&
                    <ReactTooltip id="deleteNote" place="top" effect="solid">
                        Eliminar
                    </ReactTooltip>
                }
            </Fragment>
        );

        const date_now = formatDate(new Date(), 'MM/DD/YYYY');
        let reminder_date = data.note_reminder ? new Date(data.note_reminder.reminder_date) : null;
        let note_reminder_expired = reminder_date ? reminder_date < new Date(date_now) ? 'not-expired' : 'expired' : '';

        const noteReminderCheck = note_reminder_expired === 'expired' && data.message_type.name !== 'scheduled_message' ? (
            <Fragment>
                <button data-tip data-for="seenNote" className="p-link" onClick={() => seenNoteReminder(data.id)}>
                    <FontAwesomeIcon icon={"check"} color={'#607D8B'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                </button>
                {!isMobile() &&
                    <ReactTooltip id="seenNote" place="top" effect="solid">
                        Marcar como leído
                    </ReactTooltip>
                }
            </Fragment>
        ) : null;

        let note_icon = 'note-sticky';
        let tooltip = null;
        let tooltip_label = null;
        let tooltip_id = null;
        let color = 'grey';
        let note_date = null;

        if (messageNoteType === 'note_reminders' || messageNoteType === 'scheduled_messages') {
            note_icon = messageNoteType === 'note_reminders' ? 'bell' : 'robot';
            let note_reminder_date = reminder_date;

            if (note_reminder_date) {
                let message_type = messageNoteType === 'note_reminders' ? 'Recordatorio' : 'Mensaje Programado';
                let active_label = note_reminder_date >= new Date(date_now) && !data.note_reminder.seen ? 'activo' : 'inactivo';
                color = note_reminder_date.toDateString() === new Date(date_now).toDateString() ? 'orange' : note_reminder_date > new Date(date_now) && !data.note_reminder.seen ? null : 'grey';
                note_date = (<p><b>{formatDate(data.note_reminder.reminder_date, 'HH:mm')}</b></p>);
                tooltip_label = (
                    <p> {message_type} {active_label} : {formatDate(data.note_reminder.reminder_date, 'DD-MM-YYYY HH:mm')}</p>
                );

                tooltip_id = 'message-note-id-' + data.id;

                tooltip = (
                    !isMobile() ?
                    <ReactTooltip id={tooltip_id} place="top" effect="solid" key={color}>
                        {tooltip_label}
                    </ReactTooltip> : null
                );
            }
        }

        return (
            <Fragment>
                <div className={`p-fluid mt-1 mb-3 flex`}>
                    <div className='note-button'>
                        <FontAwesomeIcon icon={'fa-regular fa-' + note_icon} color={color} size={"xs"} data-tip data-for={tooltip_id} className={!color ? "btn-actions btn-fontawesome btn-actions-default" : "btn-actions btn-fontawesome"}/>
                        {note_date}
                    </div>
                    <div className="note-desc col-8 p-sm-12 md-8 p-lg-8">
                        <div className='py-2'><b>{data.client.firstname + ' ' + data.client.lastname}</b></div>
                        <div><small className='note-body'><FontAwesomeIcon icon={'fa-regular fa-clipboard'}/> {data.body}</small></div>
                        <div className='pt-4'>
                            <small><FontAwesomeIcon icon={'fa-regular fa-user'}/> {user ? user : 'Mensaje Programado'}</small>
                            <small className='ml-2'><FontAwesomeIcon icon={'fa-regular fa-calendar'}/> {fulltime}</small>
                        </div>
                    </div>
                    <div className="field">
                        <Fragment>
                            <div className='py-2'><b>Acciones</b></div>
                            <button data-tip data-for="showNote" className="p-link" onClick={() => showItemHandler(data)}>
                                <FontAwesomeIcon icon={"fa-regular fa-eye"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                            </button>
                            {!isMobile() &&
                                <ReactTooltip id="showNote" place="top" effect="solid">
                                    Visualizar
                                </ReactTooltip>
                            }
                        </Fragment>
                        {noteButtonRemove}
                        {noteReminderCheck}
                    </div>
                </div>
                {tooltip}
            </Fragment>
        );
    }

    const removeDuplicateDates = (array) => {
        const seenDates = new Set();
        return array.filter(item => {
            const isDuplicate = seenDates.has(item.date);
            seenDates.add(item.date);
            return !isDuplicate;
        });
    };

    const itemNotesDate = (data) => {
        if (!data.note_reminder?.reminder_date) {
            return null;
        }
        let date = formatDate(data.note_reminder.reminder_date, 'MM/DD/YYYY');
        let formattedDate = formatDate(data.note_reminder.reminder_date, 'dddd, D [de] MMMM [de] YYYY');
        let newMessagesTempDate = removeDuplicateDates([...messagesTempDate]);
        let existsDate = newMessagesTempDate.some(element => element.date === date);

        if (existsDate) {
            let existsData = newMessagesTempDate.some(element => element.id === data.id);
            if (existsData) return <p className='note-title'><b>{formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)}</b></p>;
            else return null;
        } else {
            setMessagesTempDate(prevMessagesTempDate => {
                return [
                    ...prevMessagesTempDate,
                    { date: date, id: data.id }
                ];
            });
        }
    };

    const itemNotes = (data) => {
        return(
            <Fragment>
                {itemNotesDate(data)}
                {subItemNotes(data)}
            </Fragment>
        );
    }

    const messageNotesList = messagesTemp.map((element) => {
        return itemNotes(element);
    });

    const toast = useRef(null);

    let header_label = messageNoteType === 'notes' ? 'Notas' : messageNoteType === 'note_reminders' ? 'Recordatorios' : 'Mensajes Programados';

    let radio_button_disabled = globalClients ? 'note-radio-button-disabled' : '';

    const toggleRadioButton = (value) => {
        if (scrollParentRef.current) {
            scrollParentRef.current.scrollTop = 0
        };
        setSelectedTemplate(null);
        setSeletedDate(null);
        setMessageNoteType(value);
    }

    const getMorePage = () => {
        switch (messageNoteType) {
            case 'note_reminders':
                !loadingNote && getPageNote();
                break;
            case 'scheduled_messages':
                !loadingScheduled && getPagesSheduled();
                break;
            case 'notes':
                break;
        }
    }

    const getResultNotFount = () => {
        switch (messageNoteType) {
            case 'note_reminders':
                if (!loadingNote && messageNotesList.length === 0) return true;
            case 'scheduled_messages':
                if (!loadingScheduled && messageNotesList.length === 0) return true;
            case 'notes':
                if (loadingNotes && messageNotesList.length === 0) return true;
        }
    };

    const spinnerNote = (
        loadingNote && <CustomSpinner status={'Obteniendo ' + header_label + '...'}/>
    );

    const spinnerScheduled = (
        loadingScheduled && <CustomSpinner status={'Obteniendo ' + header_label + '...'}/>
    );

    const scheduledMessagesUp = () => {
        return (
            <div class="formgrid flex pb-3">
                {messageNoteType === 'scheduled_messages' && (
                    <div className='px-2'>
                    {templateDropdown()}
                </div>
                )}
                <div className='px-2'>
                    {dateDropdown()}
                </div>
                <div className='px-2'>
                    {countNoteBadge()}
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            <div className={"flex"}>
                {!globalClients && (
                    <div className={`ml-2 mr-2 ${radio_button_disabled}`}>
                        <label className={"mr-2"} htmlFor="notes">Notas</label>
                        <RadioButton inputId="note" name="message_note" value={'notes'} onChange={(e) => toggleRadioButton(e.value)} checked={messageNoteType === 'notes'} disabled={globalClients} />
                    </div>
                )}
                <div className="ml-2 mr-2">
                    <label className={"mr-2"} htmlFor="reminders">Recordatorios</label>
                    <RadioButton inputId="reminder" name="message_note" value={'note_reminders'} onChange={(e) => toggleRadioButton(e.value)} checked={messageNoteType === 'note_reminders'} />
                </div>
                <div className="ml-2 mr-2">
                    <label className={"mr-2"} htmlFor="reminders">Mensajes Programados</label>
                    <RadioButton inputId="scheduled_message" name="message_note" value={'scheduled_messages'} onChange={(e) => toggleRadioButton(e.value)} checked={messageNoteType === 'scheduled_messages'} />
                </div>
            </div>
            <div style={{height:'20px'}}></div>
            <div className='scrollable datascroller-notes' style={{height: "50vh"}} ref={scrollParentRef}>
                {(messageNoteType !== 'notes') && scheduledMessagesUp()}
                {getResultNotFount() && <ResultNotFount/>}
                <InfiniteScroll
                    pageStart={1}
                    isReverse={false}
                    initialLoad={false}
                    useWindow={false}
                    loadMore={getMorePage}
                    loader={(loadingNote || loadingScheduled) && <div className="center" key={0}> <ProgressBar mode="indeterminate" /> </div>}
                    hasMore={messageNoteType === 'notes' ? true : messageNoteType === 'note_reminders' ? hasMoreNote : hasMoreScheduled}
                    getScrollParent={() => scrollParentRef.current}
                >
                {messageNotesList}
                </InfiniteScroll>
            </div>
            {spinnerNote}
            {spinnerScheduled}
        </Fragment>
    );
}

export default MessageNote;