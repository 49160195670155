import ApiLoan from '../service/ApiLoanService';

export const getProviderSettings = async () => {
    const apiService = new ApiLoan();
    return apiService.getResources({
        url: '/profile'
    }).then(response => {
        localStorage.setItem('username', response.data.username);
        return response.data;
    });
}
