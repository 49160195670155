import React, { Fragment, useEffect, useState } from 'react';
import './Toolbar.css';
import Avatar from 'react-avatar';
import { removeNumbersAndSpecialCharacters, avatarColors } from '../../../utils/utils';

export default function Toolbar(props) {
    const {
        title,
        handleClickTitle,
        backButton,
        leftItems,
        rightItems,
        centerItems,
        className,
        zIndex,
        hideShowLeftItems,
    } = props;

    let toolbarClassname = className !== undefined ? className : 'toolbar';

    const onTitleClick = () => {
        if (handleClickTitle) {
            handleClickTitle();
        }
    };

    if (centerItems !== undefined) {
        return (
            <div style={{ zIndex: '2' }} className={toolbarClassname}>
                <div className="center-items">
                    {centerItems}
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ zIndex: '2' }} className={toolbarClassname}>
                <div className={title ? "left-items-title" : "left-items"}>
                    {backButton && backButton}
                    {title && (
                        <Fragment>
                            <Avatar
                                colors={avatarColors}
                                style={{ position: 'relative'}}
                                className="conversation-photo"
                                maxInitials={1}
                                name={removeNumbersAndSpecialCharacters(title)}
                                round={true}
                                size="50" />
                            <h1
                                onClick={onTitleClick}
                                className="toolbar-title"
                                style={{ cursor: handleClickTitle ? 'pointer' : 'default' }}>
                                {title}
                            </h1>
                        </Fragment>
                    )}
                    {!hideShowLeftItems ? leftItems : null}
                </div>
                <div className={title ? "right-items-title" : "right-items"}>
                    {
                        rightItems.map((x, index) => {
                            return <div key={index} >{x}</div>
                        })
                    }
                </div>
            </div>
        );
    }
}
