import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import {Message} from 'primereact/message';
import {InputNumber} from 'primereact/inputnumber';
import cronParser from 'cron-parser';
import moment from 'moment-timezone';
import {CompanyContext} from "../../context/companyContext";

const Frecuency = props => {

    const [loading, setLoading] = useState(true);
    const [frecuencyType, setFrecuencyType] = useState(null);
    const [colsClass, setColsClass] = useState("md-3");
    const [errorMessage, setErrorMessage] = useState(undefined);
    const {providerTimezone} = useContext(CompanyContext)


    useEffect(() => {
        setLoading(false);
        setFrecuencyType(props.value.type);
    }, []);

    useEffect(() => {
        if (props.value.cron_expression === undefined){
            let values = props.value;

            let cron_expression = values.minute + ' ' + values.hour + ' ' + values.day_month + ' ' + values.month + ' ' + values.day_week;

            let cronExpression = cronExpressionHandler(cron_expression, 'America/Argentina/Buenos_Aires',
                providerTimezone).split(' ')

            values['minute'] = cronExpression[0];
            values['hour'] = cronExpression[1];
            values['day_month'] = cronExpression[2];
            values['month'] = cronExpression[3];
            values['day_week'] = cronExpression[4];

            let new_cron_expression = cronExpression.join(' ');

            values['cron_expression'] = cronExpressionHandler(new_cron_expression, providerTimezone,
                'America/Argentina/Buenos_Aires');

            props.onChange({value: values});

        }

    }, []);

    useEffect(() => {
        if (props.error !== undefined){
            setErrorMessage(props.error.type);
        }
    }, [props.error]);

    const getInputFrecuency = () => {
        return (
            <Fragment>

                {getFrecuencyForm()}

                    {
                        errorMessage !== undefined ? (
                            <div className={"col-12 md-12"}>
                                <Message severity="error" text={errorMessage} style={{ float: 'center', marginBotton: '30px', paddingLeft: '.5em' }}/>
                            </div>)
                            : null
                    }
            </Fragment>
        );
    };

    const getFrecuencyForm = () => {
        return (
            <Fragment>
                <div className="col-12">
                    <div className="p-fluid formgrid grid">

                    <div className="field col-12 md-3">
                        {getFrecuencyTypes()}
                    </div>
                    <div className="field col-12 md-9">
                        {switchTypeFrecuency()}
                    </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md-12">

                    </div>
                </div>
            </Fragment>
        );
    };

    const getFrecuencyTypes = () => {
        let options = [];
        let frecuencies = {
            'day': 'Día',
            'week': 'Semana',
            'month': 'Mes',
            'year': 'Año'
        };

        for (let key in frecuencies) {
            options.push({
                'value':  key,
                'label': frecuencies[key]
            });
        }

        return (
            <span className="p-float-label">
                <Dropdown value={frecuencyType} options={options}
                      onChange={(e) => changeTypeFrecuency(e.value) }/>
                <label htmlFor="frecuencyType">Seleccione Frecuencia</label>
            </span>
        );
    };

    const changeTypeFrecuency = (value) => {
        setFrecuencyType(value);
        let reset_value = {
            minute: 0,
            hour: 0,
            day_month: '*',
            month: '*',
            day_week: '*',
            type: value,
            cron_expression: null
        };
        props.onChange({value: reset_value});
    };

    const switchTypeFrecuency = () => {
        let form = null;

        switch (frecuencyType) {
            case('day'):
                setColsClass("field col-12 md-4");
                form = dayHandler();
                break;
            case('week'):
                setColsClass("field col-12 md-4");
                form = weekHandler();
                break;
            case('month'):
                setColsClass("field col-12 md-4");
                form = monthHandler();
                break;
            case('year'):
                setColsClass("field col-12 md-3");
                form = yearHandler();
                break;
            default:
                break;
        }

        return form;
    };


    const dayHandler = () => {
        return (
            <div className="p-fluid formgrid grid">
                {hourHandler()}
                {minuteHandler()}
            </div>
        );
    };

    const minuteHandler = () => {
        let value = parseInt(props.value.minute);

        return (
            <div className={colsClass}>
               <span className="p-float-label">
                   <InputNumber value={value} onValueChange={(e) => inputChangeHandler(e, {minute: e.target.value})}
                                mode="decimal" min={0} max={59} />
                   <label htmlFor="float-input">Minutos</label>
               </span>
            </div>
        )
    };

    const hourHandler = () => {
        let value = parseInt(props.value.hour);

        return (
            <div className={colsClass}>
                <span className="p-float-label">
                    <InputNumber value={value} onValueChange={(e) => inputChangeHandler(e, {hour: e.target.value})}
                                 mode="decimal" min={0} max={23} />
                    <label htmlFor="float-input">Hora</label>
                </span>
            </div>
        );
    };

    const getWeekDays = () => {
        let options = [];
        let days_of_week = {
            '1': 'Lunes',
            '2': 'Martes',
            '3': 'Miercoles',
            '4': 'Jueves',
            '5': 'Viernes',
            '6': 'Sabado',
            '7': 'Domingo'
        };

        for (let key in days_of_week) {
            options.push({
                'value':  key,
                'label': days_of_week[key]
            });
        }

        let value = props.value.day_week;

        return (
            <div className={colsClass}>
                <span className="p-float-label">
                   <Dropdown value={value} options={options}
                             onChange={(e) => inputChangeDropdownHandler(e, {day_week: e.value})}/>
                   <label htmlFor="dayWeek">Seleccione día</label>
                </span>
            </div>
        );
    };

    const weekHandler = () => {
        return (
            <div className="p-fluid formgrid grid">
                {getWeekDays()}
                {hourHandler()}
                {minuteHandler()}
            </div>
        );
    };

    const getMonthDays = () => {
        let options = [];

        for (let i = 1; i <= 31; i++){
            let value = i.toString();
            options.push({
                'value': value,
                'label': value
            });
        }

        let value = props.value.day_month;

        return (
            <div className={colsClass}>
                <span className="p-float-label">
                    <Dropdown value={value} options={options}
                          onChange={(e) => inputChangeDropdownHandler(e, {day_month: e.value})}/>
                    <label htmlFor="dayMonth">Seleccione día</label>
                </span>
            </div>
        );
    };

    const monthHandler = () => {
        return (
            <div className="p-fluid formgrid grid">
                {getMonthDays()}
                {hourHandler()}
                {minuteHandler()}
            </div>
        );
    };

    const getMonths = () => {
        let options = [];

        let months = {
            '1': 'Enero',
            '2': 'Febrero',
            '3': 'Marzo',
            '4': 'Abril',
            '5': 'Mayo',
            '6': 'Junio',
            '7': 'Julio',
            '8': 'Agosto',
            '9': 'Septiembre',
            '10': 'Octubre',
            '11': 'Noviembre',
            '12': 'Diciembre'
        };

        for (let key in months) {
            options.push({
                'value':  key,
                'label': months[key]
            });
        }

        let value = props.value.month;

        return (
            <div className={colsClass}>
                <span className="p-float-label">
                    <Dropdown value={value} options={options}
                              onChange={(e) => inputChangeDropdownHandler(e, {month: e.value})} />
                    <label htmlFor="month">Seleccione mes</label>
                </span>
            </div>
        );
    };

    const yearHandler = () => {
        return (
            <div className="p-fluid formgrid grid">
                {getMonthDays()}
                {getMonths()}
                {hourHandler()}
                {minuteHandler()}
            </div>
        );
    };

    const cronExpressionHandler = (expression, timezone_origin, timezone_destination) => {
        const options = {
          currentDate: new Date(),
          tz: timezone_origin
        };

        let cronExpression = expression;

        const interval = cronParser.parseExpression(cronExpression, options);
        const nextDate = interval.next();

        const targetDate = moment(nextDate.toDate()).tz(timezone_destination);
        const newCronParts = cronExpression.split(' ').map((part, i) => {
          if (part === '*') {
            return '*';
          } else {
            switch (i) {
              case 0: return targetDate.minutes(); // minute
              case 1: return targetDate.hours(); // hour
              case 2: return targetDate.date(); // day of the month
              case 3: return targetDate.month() + 1; // month
              case 4: return targetDate.day(); // day of the week
              default: return part; // default to original part
            }
          }
        });

        return newCronParts.join(' ');
    }

    const inputChangeDropdownHandler = (event, field) => {
        let values = {...props.value};
        values[Object.keys(field)[0]] = event.value;

        let cron_expression = values.minute + ' ' + values.hour + ' ' + values.day_month + ' ' + values.month + ' ' + values.day_week;

        values['cron_expression'] = cronExpressionHandler(cron_expression, providerTimezone ,'America/Argentina/Buenos_Aires')
        props.onChange({value: values, field});
    };

    const inputChangeHandler = (event, field) => {
        let values = {...props.value};
        values[Object.keys(field)[0]] = parseInt(event.target.value);

        let cron_expression = values.minute + ' ' + values.hour + ' ' + values.day_month + ' ' + values.month + ' ' + values.day_week;

        values['cron_expression'] = cronExpressionHandler(cron_expression, providerTimezone ,'America/Argentina/Buenos_Aires')

        props.onChange({value: values, field});
    };

    return useMemo(() => getInputFrecuency(frecuencyType), [frecuencyType, !loading, props.value, colsClass, errorMessage]);

};

export default Frecuency;