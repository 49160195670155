import React from 'react'
import Resource from '../../Resources/Resource'

const AiAssistantView = (props) => {

    const resource = {
        title: 'Asistente IA',
        id: props.match.params.id,
        url: '/ai_assistants/',
        fields: [
            { name:'name', traduction:'Nombre'},
            { name:'description', traduction:'Descripción'},
            { name:'instructions', traduction:'Instrucciones'},
            { name:'file_name', traduction:'Archivo'}
        ]
    }

    return (
        <Resource title={resource.title} url={resource.url} resource_id={resource.id} fields={resource.fields}/>
    );

}

export default AiAssistantView;