import React from 'react';

import Resources from '../Resources/Resources';


const SmsSettings = () => {
    const resource = {
        title: 'Lista de cuentas de SMS',
        url: '/sms_settings',
        colsHeader: [
            { field: 'provider.name', header: 'Cliente'},
            { field: 'gateway', header: 'Proveedor'},
            { field: 'active', header: 'Habilitado', checkbox: true},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/sms_setting/',
            edit: '/edit',
            delete: true
        },
        urlTable: [
            '/sms_settings',
            '/sms_settings/new',
            '^\\/sms_settings\\/\\d+\\/edit$',
        ]
    };

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader} action={resource.action}
                       urlTable={resource.urlTable} />);
};

export default SmsSettings;