import React from 'react';

export const MessageContext = React.createContext({
    messageFields: {
        customTags: [],
        clientCustomTags: [],
        selectedCustomTags: [],
        closeModal: false
    },
    messageContext: null,
    messageReaction: null,
    selectedMessageContext: null,
    loadingMessages: false,
    foundMessageContext: false,
    forward: false,
    selectedForwardMessages: [],
    selectedForwardContact: null,
    forwardContactModal: false,
    isSearchingContext: false,
    groups: [],
    users: [],
    selectedPhoneContact: null,
    loadingSearchSelectedPhoneContact: false,
    updateClientCustomTags: (data) => {},
    updateSelectedCustomTags: (data) => {},
    updateCloseModal: (data) => {},
    updateMessageContext: (data) => {},
    updateMessageReaction: (data) => {},
    updateSelectedMessageContext: (data) => {},
    updateLoadingMessages: (data) => {},
    updateFoundMessageContext: (data) => {},
    updateForward: (data) => {},
    updateSelectedForwardMessages: (data) => {},
    updateSelectedForwardContact: (data) => {},
    updateForwardContactModal: (data) => {},
    updateIsSearchingContext: (data) => {},
    updateSelectedPhoneContact: (data) => {},
    updateLoadingSearchSelectedPhoneContact: (data) => {},
});

