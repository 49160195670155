import React, {Fragment, useEffect, useState, useRef} from 'react'
import {Button} from "primereact/button";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ApiLoan from "../../../service/ApiLoanService";

import OpusMediaRecorderView from "./OpusMediaRecorderView";
import OutsideAlerter from "../../../utils/useOutsideAlerter";
import { formatAudioTime } from "../../../utils/utils";
import './AudioRecord.css';
import ReactTooltip from "react-tooltip";

const AudioRecord = (props) => {

    const [data, setData] = useState([]);
    const [reset, setReset] = useState(false);
    const [sendMedia, setSendMedia] = useState(false);
    const [recordingTimeout, setRecordingTimeout] = useState(null);
    const timerDisplayRef = useRef(null);
    const elapsedTimeRef = useRef(0);
    const counterIntervalRef = useRef(null);

    useEffect(() => {
        if (sendMedia){
            const apiService = new ApiLoan();

            const formData = new FormData()
            const extension = data[0].type;

            const audioFile = new File(data, `${Date.now()}.${extension}`, {
                type: extension,
            });

            formData.append('file_upload', audioFile);
            apiService.postResourceBlob({
                url: '/messages/media',
                data: formData,
                blob: extension
            }).then(response => {
                if (response.status === 200){
                    props.onMessageMediaCreate('audio', response.data.media);
                }
            }).catch(error => {
                props.onMessageMediaError('Error al subir', 'Audio')
            }).finally(() => {
                setSendMedia(false);
                setData([]);
            })
        }
    }, [sendMedia])

    const startRecording = (start, stop) => {
        props.startAudio(true);
        setData([]);
        start();
        const timeout = setTimeout(() => {
            countdownComplete(stop);
        }, 600000);
        setRecordingTimeout(timeout);
        manageTimer('start');
    }

    const resetRecord = (stop) => {
        props.startAudio(false);
        setReset(true);
        stop()
        setData([]);
        clearTimeout(recordingTimeout)
        manageTimer('stop');
    }

    const countdownComplete = (stop) => {
        stop();
    }

    const onCloseOutside = (stop) => {
        resetRecord(stop)
    }

    const confirmAudio = (stop) => {
        stop();
        clearTimeout(recordingTimeout)
    }

    const timerActions = {
        start: () => {
            if (timerDisplayRef.current) {
                timerDisplayRef.current.textContent = '0:00';
            }
            elapsedTimeRef.current = 0;
            counterIntervalRef.current = setInterval(() => {
                elapsedTimeRef.current += 1;
                if (timerDisplayRef.current) {
                    timerDisplayRef.current.textContent = formatAudioTime(elapsedTimeRef.current);
                }
            }, 1000);
        },
        stop: () => {
            clearInterval(counterIntervalRef.current);
            elapsedTimeRef.current = 0;
            if (timerDisplayRef.current) {
                timerDisplayRef.current.textContent = '0:00';
            }
        }
    };

    const manageTimer = (action) => {
        const timerAction = timerActions[action];
        if (timerAction) {
            timerAction();
        }
    };

    const audioButtons = (status, start, stop) => {
        let buttons = null;
        if (status === 'recording'){
            buttons = (
                <OutsideAlerter onClose={() => onCloseOutside(stop)}>
                    <div className={"audio-record"}>
                        <div className={"audio-timer"}>
                            <span ref={timerDisplayRef}>0:00</span>
                        </div>
                        <button className="p-link" onClick={() => resetRecord(stop)}>
                            <FontAwesomeIcon icon={"fa-regular fa-circle-xmark"} className={"btn botonCancel"}/>
                        </button>
                        <button className="p-link" onClick={()=>confirmAudio(stop)}>
                            <FontAwesomeIcon icon={"fa-regular fa-circle-check"} className={"btn botonSend"}/>
                        </button>
                    </div>
                </OutsideAlerter>
            )
        } else {
            buttons = (
                <Fragment>
                    <button data-tip data-for="audio" className="p-link" onClick={() => startRecording(start)} disabled={sendMedia}>
                        <FontAwesomeIcon icon={"fa-regular fa-microphone"} className={"btn botonTransp"}/>
                    </button>
                    <ReactTooltip id="audio" place="left" effect="solid">
                        Audio
                    </ReactTooltip>
                </Fragment>
            )
        }

        return buttons
    }

    return (
        <Fragment>
            <OpusMediaRecorderView
                onDataAvailable={(e) => {
                    const new_data = [...data, e.data];
                    setData(new_data)

                    if (!reset){
                        props.startAudio(false);
                        setSendMedia(true);
                    } else {
                        setReset(false)
                    }

                }}
                error={(errorMessage) => {
                        props.onMessageMediaError(errorMessage, 'Audio')
                    }
                }
                mimeType={'audio/ogg'}
                render={({ state, start, stop, pause, resume }) => (
                    <Fragment>
                        {audioButtons(state, start, stop)}
                    </Fragment>
                )}
            />
        </Fragment>
    );
}

export default AudioRecord;