import React from 'react';
import Attribute from "../../Resources/Attribute";

const SmsConfigView = ({value}) => {

    if  (canParseJson(value)) {
        value = JSON.parse(value);
    }

    let fields = [];
    for (let field in value){
        fields.push(
            <Attribute name={field} traduction={field} key={field}>
                {value[field]}
            </Attribute>
        );
    }

    return (
        <div className="col-12" >
            <div className="grid">
                {fields}
            </div>
        </div>
    );
};

const canParseJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export default SmsConfigView;