import React, {useEffect, useState, useRef, Fragment} from 'react'
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import ApiLoan from "../service/ApiLoanService";
import { Toast } from 'primereact/toast';

const ButtonEditInline = ({value, elementConfig, sumaryToast, formData, classNameValue }) => {

    const [editField, setEditField] = useState(false);
    const [valueState, setValueState] = useState(null);

    useEffect(() => {
        setValueState(value);
    },[value]);

    const action_edit = !editField ? valueState : (<InputText className={"p-inputtext-sm"} value={valueState} onChange={(e) => setValueState(e.target.value)} {...elementConfig}/>);

    const onEditButton = () => {
        setEditField(true);
    }

    const toast = useRef(null);

    const showSuccess = (detail) => {
        let summary_message = sumaryToast !== undefined ? sumaryToast : '';
        toast.current.show({severity: 'success', summary: summary_message, detail: detail});
    }

    const showError = (detail) => {
        let summary_message = sumaryToast !== undefined ? sumaryToast : '';
        toast.current.show({severity: 'error', summary: summary_message, detail: detail});
    }

    const onCancelButton = () => {
        setValueState(value);
        setEditField(false);
    }

    const onSendButton = () => {
        const apiService = new ApiLoan();
        const form = formData;
        form.data['value'] = valueState;
        apiService.patchResource({
            url: form.url,
            data: form.data
        }).then(response => {
            showSuccess(response.data.message);
            setEditField(false);
        }).catch(error => {
            showError(error.response.data.message);
        });
    }

    const button_edit = (
        <span>
            <Button type="button" icon="pi pi-pencil" className={'p-button-rounded p-button-warning'} onClick={onEditButton}
                style={{marginRight:'.25em', width: '2em', height: '2em', marginLeft:'1em'}}/>
        </span>
    );

    const cancel_edit = (
        <span>
            <Button type="button" icon="pi pi-times" className={'p-button-rounded p-button-danger'} onClick={onCancelButton}
                style={{marginRight:'.25em', width: '2em', height: '2em', marginLeft:'1em'}}/>
        </span>
    );

    const send_edit = (
        <span>
            <Button type="button" icon="pi pi-check" className={'p-button-rounded p-button-success'} onClick={onSendButton}
                style={{marginRight:'.25em', width: '2em', height: '2em',marginLeft:'1em'}}/>
        </span>
    );

    const form_action = () => {
        let form;
        if (!editField){
            form = button_edit;
        } else {
            form = (
                <span>
                    {send_edit}
                    {cancel_edit}
                </span>
            );
        }
        return form;
    }

    const form = (
        <Fragment>
            <Toast ref={toast} />
            <div className={classNameValue || "col-12 md-10"}>
                {action_edit}
                {form_action()}
            </div>
        </Fragment>
    )

    return form;
}

export default ButtonEditInline;